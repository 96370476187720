import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { getRequest } from "../../../src/utils/API/api.ts";
import styles from "./Landing.module.css";
import axios from "axios";
import TextInputP from "../../common/inputElements/TextInputP";
import { useMatch, useNavigate } from "react-router-dom";
import { FormControl } from "../eventLayout/settings2/regForm/FormControl";
import { useDispatch, useSelector } from "react-redux";
import CountryCityRegForm from "./CountryCityRegForm.jsx";
import countryCodes from "../../utils/CountryCodes.json";
import { showToast } from "../../redux/actions/toastActions.js";

const LinkedinRegForm = ({
  linkData,
  setIsRegistered,
  setRegisteredUserEmail,
}) => {
  let l1 =
    "AQUPp1f7AeX3h8qFSRzRi65y4ogC8ZLOlNLzIw6IiTzwAcHc3wo1EPPMSrALaneSWgF8gJmn09pbYG7EXmcY3aRSfpLgUhynEODha2yEMGbBJxIKcyfPrxpJyBGyELZxnAjgogtR_TX4OSRSAGrAZtjyklEKlCxBuA82uU5rZZ2id2fBuOkTD3AwXb0lYJW36xsZa5-NLe0AUbADe0tVKdLhbqK0GYLRQol_wNLDjbVjs46_QtucnxPOCwkppl4fhlkSavGY1Lrk1xYNQW5fBLQ_Ww1eud6iFkUNsyoj3eB2gMObLfBCXEr2_jUHWU0QwJOw7L52Lz_41JDcK1dbJ0TFLhD8SQ";
  let l2 = "-WvuDcxHN3";
  const [regForm, setRegForm] = useState({});
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);
  const navigate = useNavigate();
  const eventsId = useMatch("/event/:eventId");
  const formMethods = useForm();
  const userLogin = useSelector((state) => state.userLogin);
  const { error, loading, userInfo } = userLogin;
  const [countryResult, setCountryResult] = useState([]);
  const [cityResult, setCityResult] = useState([]);
  const [stateResult, setStateResult] = useState([]);
  const [triggerCode, setTriggerCode] = useState(false);
  const [countryCode, setCountryCode] = useState("+91");
  const dispatch = useDispatch();
  const [allCountryCode, setAllCountryCode] = useState(countryCodes);
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = formMethods;

  useEffect(() => {
    const getAdditionalForm = async () => {
      const response = await getRequest(
        `/event/${eventsId.params.eventId}/additionalForm`
      );
      setRegForm(response.data[0]?.form);
      // console.log(response.data[0]?.form, "new form");
    };
    if (eventsId.params.eventId) {
      getAdditionalForm();
    }
  }, [eventsId]);

  useEffect(() => {
    if (userInfo?.firstName) {
      setTimeout(() => {
        navigate("/events");
      }, 1000);
      setIsRegistered(true);
    }
  }, [userInfo?.email]);

  function handleLinkedinPost(linkedinAccessToken, linkedinURNId, eventId) {
    // linkedinURNid can be passed here from different page where login is going to happen
    // let linkedinAccessToken = localStorage.getItem("linkedinAccessToken");
    // let linkedinURNid = localStorage.getItem("linkedinURNid");
    if (!eventId) {
      return alert("something went wrong! Please try again");
    }
    if (linkedinAccessToken && linkedinURNId) {
      axios
        .post(
          `${process.env.REACT_APP_SERVER_URL_LEADS}/user/linkedin-auto-post`,
          { linkedinURNid: linkedinURNId, eventId: eventId },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${linkedinAccessToken}`,
            },
          }
        )
        .then((response) => {
          console.log(response, "success");
          updateLinkedinPostCount(eventId);
        })
        .catch((err) => console.log(err, "error"));
    } else {
      alert("something went wrong! Please try again"); //access-token or urnid not found
    }
  }

  async function updateLinkedinRegCount(eventId) {
    const response = await axios.patch(
      `${process.env.REACT_APP_SERVER_URL_LEADS}/event/linkedin-analytics`,
      {
        eventId: eventId,
        registration: true,
      }
    );

    if (response.status !== 200) {
      alert("something went wrong");
    }
  }

  async function updateLinkedinPostCount(eventId) {
    const response = await axios.patch(
      `${process.env.REACT_APP_SERVER_URL_LEADS}/event/linkedin-analytics`,
      {
        eventId: eventId,
        post: true,
      }
    );

    if (response.status !== 200) {
      alert("something went wrong");
    }
  }

  function onFormSubmit(data, error) {
    const matchingCountry = countryCodes.find(
      (country) => country.dial_code === countryCode
    );
    const regex = /^[\d]{10}$/;
    if (!matchingCountry) {
      dispatch(
        showToast({
          message: "Invalid Country Code.",
          category: "danger",
        })
      );
      return;
    }

    if (!regex.test(data.mobile)) {
      dispatch(
        showToast({
          message: "Invalid Mobile Number",
          category: "danger",
        })
      );
      return;
    }
    let additionalData = {};
    if (Object.keys(data).length > 6) {
      for (const prop in data) {
        if (
          prop !== "firstName" &&
          prop !== "lastName" &&
          prop !== "mobile" &&
          prop !== "email" &&
          prop !== "organization" &&
          prop !== "jobTitle" &&
          prop !== "country" &&
          prop !== "state" &&
          prop !== "city"
        ) {
          additionalData[prop] = data[prop];
        }
      }
    }
    setIsSubmitting(true);
    axios
      .post(`${process.env.REACT_APP_SERVER_URL_LEADS}/attendee/eventregister`, {
        firstName: data.firstName,
        lastName: data.lastName,
        mobile: data.mobile,
        countryCode: countryCode,
        email: data.email,
        eventId: eventsId.params.eventId,
        jobTitle: data.jobTitle,
        organization: data.organization,
        country: data.country,
        state: data.state,
        city: data.city,
        additionalData: additionalData,
      })
      .then(function (response) {
        setRegisteredUserEmail(data.email);
        reset();
        setIsSubmitting(false);
        setIsRegistered(true);
        setIsSubmitting(false);

        handleLinkedinPost(
          // linkedin AutoPost
          linkData.linkedinAccessToken,
          linkData.linkedinURNId,
          eventsId.params.eventId
        );

        updateLinkedinRegCount(eventsId.params.eventId);
      })
      .catch(function (error) {
        reset();
        setIsSubmitting(false);
        if (
          error.response.data.message ===
          "You have already registered for this event"
        ) {
          dispatch(
            showToast({
              message: "You have already registered for this event",
              category: "danger",
            })
          );
        }
        setIsSubmitting(false);
      });
  }

  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  useEffect(() => {
    if (countryCode) {
      const filteredCountries = countryCodes.filter(
        (country) =>
          country.dial_code
            .toLowerCase()
            .startsWith(countryCode.toLowerCase()) ||
          country.name.toLowerCase().startsWith(countryCode.toLowerCase()) ||
          country.code.toLowerCase().startsWith(countryCode.toLowerCase())
      );

      setAllCountryCode(filteredCountries);
    }
  }, [countryCode]);

  return (
    <div className="w-[100%] mymd:w-full">
      <form
        onSubmit={handleSubmit(onFormSubmit)}
        className={styles.reg_form_box}
      >
        <div className="mymd:w-[100%] grid grid-cols-1 sm:grid-cols-2 pt-[15px] w-[100%] gap-[15px]">
          <TextInputP
            register={register}
            type="text"
            id={"firstName"}
            label="First Name"
            required
            defaultValue={linkData.firstName ? linkData.firstName : ""}
            width={windowWidth > 900 ? "" : ""}
          />
          <TextInputP
            register={register}
            type="text"
            id={"lastName"}
            label="Last Name"
            required
            defaultValue={linkData.lastName ? linkData.lastName : ""}
            width={windowWidth > 900 ? "" : ""}
          />
        </div>

        <TextInputP
          register={register}
          type="email"
          id={"email"}
          label="Email Address"
          required
          errors={errors}
          defaultValue={linkData.email ? linkData.email : ""}
          pattern={
            /^([a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)$/i
          }
          width={"100%"}
        />
        <div
          className="relative mb-[28px] w-[100%] 
             peer bg-white border border-gray-300 text-gray-900 text-[12px] rounded-lg h-[35px] placeholder-transparent focus:ring-transparent focus:border-primary focus:border-[1.2px] block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white font-medium dark:focus:ring-blue-500 dark:focus:border-blue-500 flex py-0 items-center "
        >
          <div className="relative">
            <div className="flex items-center">
              <input
                type="text"
                id="code"
                required
                value={countryCode}
                onFocus={() => setTriggerCode(true)}
                onBlur={() => setTriggerCode(false)}
                onChange={(event) => setCountryCode(event.target.value)}
                // {...register("code")}
                className={`w-[52px]
                    peer bg-white border-0 border-gray-300 text-gray-900 text-[12px] rounded-lg h-[30px] placeholder-transparent focus:ring-transparent block pl-1.5 `}
                autoComplete="off"
              />
              <img
                src="/svgs/arrow.svg"
                alt=""
                onClick={() => setTriggerCode(!triggerCode)}
                className="transform rotate-180 h-[16px] cursor-pointer mr-2"
              />
            </div>

            {triggerCode && (
              <div className="border max-h-[110px] absolute w-[83px] z-20 bg-white overflow-y-scroll rounded-lg border-primary">
                {allCountryCode?.map((codes) => (
                  <span
                    // key={result}
                    className="w-[100%] flex h-[40px] items-center pl-2 text-[12px] font-[500] cursor-pointer hover:bg-gray-200 "
                    onMouseDown={(event) => {
                      setCountryCode(codes.dial_code);
                      event.stopPropagation();
                    }}
                  >
                    {codes.code} {codes.dial_code}
                  </span>
                ))}
              </div>
            )}
          </div>
          <div>
            <input
              type="number"
              id="mobile"
              required
              autoComplete="off"
              className={`w-[100%]
                 bg-white border-0 text-gray-900 text-[12px] h-[33px] placeholder-transparent focus:ring-transparent p-0 appearance-none bg-transparent focus:outline-none focus:ring-0 focus:border-blue-500
           `}
              {...register("mobile")}
              style={{ backgroundColor: "transparent" }}
            />
            <label
              htmlFor="firstName"
              className="absolute left-2 -top-5 mb-1 text-sm peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-900 peer-placeholder-shown:text-opacity-40 peer-placeholder-shown:top-[10px] peer-placeholder-shown:left-[13px] transition-all peer-focus:-top-5 peer-focus:text-gray-900 peer-focus:text-opacity-40 peer-focus:text-[12.5px] font-medium text-gray-900 text-opacity-40 dark:text-gray-300"
            >
              <>
                Phone Number
                <span className="text-red-500">*</span>
              </>
            </label>
          </div>
        </div>
        {/* <TextInputP
          register={register}
          type="tel"
          id={"mobile"}
          required
          label="Phone Number"
          width={"100%"}
          defaultValue={linkData.mobile ? linkData.mobile : ""}
        /> */}
        <TextInputP
          register={register}
          type="text"
          id={"organization"}
          required
          label="Company Name"
          width={"100%"}
          defaultValue={linkData.organization ? linkData.organization : ""}
        />
        <TextInputP
          register={register}
          type="text"
          id={"jobTitle"}
          required
          label="Designation"
          width={"100%"}
          defaultValue={linkData.jobTitle ? linkData.jobTitle : ""}
        />

        <FormProvider {...formMethods}>
          {regForm &&
            regForm.length > 0 &&
            regForm.map((form, key) => (
              <div key={key}>
                <CountryCityRegForm
                  form={form}
                  setCountryResult={setCountryResult}
                  countryResult={countryResult}
                  cityResult={cityResult}
                  setCityResult={setCityResult}
                  setStateResult={setStateResult}
                  stateResult={stateResult}
                />
              </div>
            ))}
        </FormProvider>

        <FormProvider {...formMethods}>
          {regForm &&
            regForm.length > 0 &&
            regForm.map((ele, key) => (
              <div key={key}>
                {/* <label htmlFor={ele.fieldName}>{ele.label}</label> */}

                <FormControl {...ele} showIcons={false} width={"100%"} />

                {/* <ErrorMessage errors={errors} name={el.fieldName} /> */}
              </div>
            ))}
        </FormProvider>
        <div className="mymd:w-[100%] mymd:flex flex-col">
          <button
            type="submit"
            disabled={isSubmitting}
            className={styles.submit_btn}
            // onClick={() => setSubmitDisable(true)}
          >
            {isSubmitting ? "Loading..." : "Register"}
          </button>
          <div
            className="text-center text-[14px] mt-[15px] cursor-pointer"
            onClick={() =>
              window.open(`${window.location.origin}/login`, "_blank")
            }
          >
            <span>
              Already Registered?{" "}
              <span className="text-primary font-[500] ">Login</span>
            </span>
          </div>
        </div>
      </form>
      {/* <div
      className="primary_submit"
        onClick={() => {
          // handleLinkedinPost(
            // linkedin AutoPost
            // linkData.linkedinAccessToken,
            // linkData.linkedinURNId,
          // );
          // handleLinkedinPost(
          //   l1,
          //   l2,
          //   eventsId.params.eventId
          // );
        }}
      >
        Linkedin post
      </div> */}
    </div>
  );
};

export default LinkedinRegForm;
