import React, { useEffect, useState } from "react";
import useDebounce from "../../helper/hooks/useDebounce";
import { useFormContext } from "react-hook-form";

const CountryCityRegForm = ({
  form,
  setCountryResult,
  countryResult,
  setCityResult,
  cityResult,
  setStateResult,
  stateResult,
}) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const debouncedSearchValue = useDebounce(searchTerm, 700);
  const [showResults, setShowResults] = useState(false);
  const [showStateResults, setShowStateResults] = useState(false);
  const [showCityResults, setShowCityResults] = useState(false);
  const { setValue } = useFormContext();
  useEffect(() => {
    var headers = new Headers();
    headers.append(
      "X-CSCAPI-KEY",
      " bXJra0hXRXk2UTdZWmdYTzZDQU53TEVyT1JIWUJ3YmxRZnA3dEpqUA=="
    );
    var requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };
    let countryCode = stateResult.country_code || countryResult.iso2;
    let stateApi = "";
    let cityApi = "";
    if (countryResult?.length !== 0) {
      stateApi = `https://api.countrystatecity.in/v1/countries/${countryResult.iso2}/states`;
    } else {
      stateApi = "https://api.countrystatecity.in/v1/states";
    }

    if (stateResult?.length !== 0 && countryResult?.length !== 0) {
      cityApi = `https://api.countrystatecity.in/v1/countries/${countryResult.iso2}/states/${stateResult.iso2}/cities`;
    } else {
      cityApi = `https://api.countrystatecity.in/v1/countries/${countryCode}/cities`;
    }
    fetch(
      `${
        showStateResults
          ? stateApi
          : showCityResults
          ? cityApi
          : "https://api.countrystatecity.in/v1/countries"
      }`,
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        if (debouncedSearchValue === "") {
          const firstFourCountries = result.slice(0, 100);
          setSearchResults(firstFourCountries);
        } else {
          const filteredCountries = result.filter((country) =>
            country.name.toLowerCase().startsWith(searchTerm.toLowerCase())
          );

          setSearchResults(filteredCountries);
        }
      })
      .catch((error) => console.log("error", error));
  }, [debouncedSearchValue, showStateResults, showCityResults]);

  return (
    <div>
      {form.inputType === "country" ? (
        <>
          {form.label === "true" ? (
            <div className={`relative mb-[28px]`}>
              <input
                type="text"
                id="country"
                required
                value={searchTerm}
                onFocus={() => setShowResults(true)}
                onBlur={() => setShowResults(false)}
                onChange={(event) => setSearchTerm(event.target.value)}
                className={`w-full
                    peer bg-white border border-gray-300 text-gray-900 text-[12px] rounded-lg h-[35px] placeholder-transparent focus:ring-transparent focus:border-primary focus:border-[1.2px] block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white font-medium dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                autoComplete="off"
              />

              <label
                htmlFor="country"
                className="absolute left-2 -top-5 mb-1 text-sm peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-900 peer-placeholder-shown:text-opacity-40 peer-placeholder-shown:top-[10px] peer-placeholder-shown:left-[13px] transition-all peer-focus:-top-5 peer-focus:text-gray-900 peer-focus:text-opacity-40 peer-focus:text-[12.5px] font-medium text-gray-900 text-opacity-40 dark:text-gray-300"
              >
                <>
                  Country&nbsp;<span className="text-red-500">*</span>
                </>
              </label>
              {showResults && (
                <div className="border max-h-[110px] absolute w-[100%] z-20 bg-white overflow-y-scroll rounded-lg border-primary">
                  {searchResults.map((result) => (
                    <span
                      onMouseDown={(event) => {
                        setSearchTerm(result.name);
                        setCountryResult(result);
                        var headers = new Headers();
                        headers.append(
                          "X-CSCAPI-KEY",
                          " bXJra0hXRXk2UTdZWmdYTzZDQU53TEVyT1JIWUJ3YmxRZnA3dEpqUA=="
                        );

                        var requestOptions = {
                          method: "GET",
                          headers: headers,
                          redirect: "follow",
                        };

                        let countryCode = result.iso2;

                        fetch(
                          `https://api.countrystatecity.in/v1/countries/${countryCode}`,
                          requestOptions
                        )
                          .then((response) => response.json())
                          .then((result) => {
                            setValue(form.fieldName, {
                              id: result.id,
                              iso2: result.iso2,
                              iso3: result.iso3,
                              latitude: result.latitude,
                              longitude: result.longitude,
                              name: result.name,
                            });
                          })
                          .catch((error) => console.log("error", error));

                        event.stopPropagation();
                      }}
                      key={result.id}
                      className="w-[100%] flex h-[40px] items-center pl-2 text-[16px] font-[500] cursor-pointer hover:bg-gray-200"
                    >
                      {result.name}
                    </span>
                  ))}
                </div>
              )}
            </div>
          ) : (
            <></>
          )}
        </>
      ) : form.inputType === "state" ? (
        <>
          {form.label === "true" ? (
            <div className={`relative mb-[28px]`}>
              <input
                type="text"
                id="state"
                value={searchTerm}
                required
                onFocus={() => setShowStateResults(true)}
                onBlur={() => setShowStateResults(false)}
                onChange={(event) => setSearchTerm(event.target.value)}
                className={`w-full
                    peer bg-white border border-gray-300 text-gray-900 text-[12px] rounded-lg h-[35px] placeholder-transparent focus:ring-transparent focus:border-primary focus:border-[1.2px] block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white font-medium dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                autoComplete="off"
              />

              <label
                htmlFor="state"
                className="absolute left-2 -top-5 mb-1 text-sm peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-900 peer-placeholder-shown:text-opacity-40 peer-placeholder-shown:top-[10px] peer-placeholder-shown:left-[13px] transition-all peer-focus:-top-5 peer-focus:text-gray-900 peer-focus:text-opacity-40 peer-focus:text-[12.5px] font-medium text-gray-900 text-opacity-40 dark:text-gray-300"
              >
                <>
                  State&nbsp;<span className="text-red-500">*</span>
                </>
              </label>
              {showStateResults && (
                <div className="border max-h-[110px] absolute w-[100%] z-20 bg-white overflow-y-scroll rounded-lg border-primary">
                  {searchResults.map((result) => (
                    <span
                      key={result.id}
                      required
                      className="w-[100%] flex h-[40px] items-center pl-2 text-[16px] font-[500] cursor-pointer hover:bg-gray-200"
                      onMouseDown={(event) => {
                        console.log(result);
                        setSearchTerm(result.name);
                        setStateResult(result);
                        setValue(form.fieldName, result);
                        event.stopPropagation();
                      }}
                    >
                      {result.name}
                    </span>
                  ))}
                </div>
              )}
            </div>
          ) : (
            <></>
          )}
        </>
      ) : form.inputType === "city" ? (
        <>
          {form.label === "true" ? (
            <div className={`relative mb-[28px]`}>
              <input
                type="text"
                id="city"
                required
                value={searchTerm}
                onFocus={() => setShowCityResults(true)}
                onBlur={() => setShowCityResults(false)}
                onChange={(event) => setSearchTerm(event.target.value)}
                disabled={
                  stateResult.length === 0 && countryResult.length === 0
                    ? true
                    : false
                }
                className={`w-full
               peer bg-${
                 stateResult.length === 0 && countryResult.length === 0
                   ? "gray-100"
                   : "white"
               } border border-gray-300 text-gray-900 text-[12px] rounded-lg h-[35px] placeholder-transparent focus:ring-transparent focus:border-primary focus:border-[1.2px] block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white font-medium dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                autoComplete="off"
              />

              <label
                htmlFor="city"
                className="absolute left-2 -top-5 mb-1 text-sm peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-900 peer-placeholder-shown:text-opacity-40 peer-placeholder-shown:top-[10px] peer-placeholder-shown:left-[13px] transition-all peer-focus:-top-5 peer-focus:text-gray-900 peer-focus:text-opacity-40 peer-focus:text-[12.5px] font-medium text-gray-900 text-opacity-40 dark:text-gray-300"
              >
                <>
                  City&nbsp;<span className="text-red-500">*</span>
                </>
              </label>
              {showCityResults && (
                <div className="border max-h-[110px] absolute w-[100%] z-20 bg-white overflow-y-scroll rounded-lg border-primary">
                  {searchResults.map((result) => (
                    <span
                      key={result.id}
                      onMouseDown={(event) => {
                        console.log(result);
                        setSearchTerm(result.name);
                        setCityResult(result);
                        setValue(form.fieldName, result);
                        event.stopPropagation();
                      }}
                      className="w-[100%] flex h-[40px] items-center pl-2 text-[16px] font-[500] cursor-pointer hover:bg-gray-200"
                    >
                      {result.name}
                    </span>
                  ))}
                </div>
              )}
            </div>
          ) : (
            <></>
          )}
        </>
      ) : (
        <></>
      )}
    </div>
  );
};

export default CountryCityRegForm;
