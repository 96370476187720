import React, { useEffect, useState } from "react";
import moment from "moment";
import axios from "axios";
import { useDispatch } from "react-redux";
import { showToast } from "../../redux/actions/toastActions";

const ScannedLeadsNotes = ({
  setTriggerNotes,
  eventsId,
  singleLeadsData,
  setAttendeesData,
  setSingleLeadsData,
}) => {
  const [text, setText] = useState("");
  const [image, setImage] = useState("");
  const dispatch = useDispatch();
  let optionmymdonth = { month: "short" };
  const addNotes = async () => {
    if (text === "" && image === "") {
      dispatch(
        showToast({
          message: "Cannot send empty fields",
          category: "danger",
        })
      );
      return;
    }
    let accessToken = localStorage.getItem("accessToken");

    const obj = {
      noteDate: new Date().toISOString(),
      noteText: text,
      noteImage: image,
    };

    setText("");
    setImage("");
    try {
      if (!accessToken) {
        dispatch(
          showToast({
            message: "Unauthorised User",
            category: "danger",
          })
        );
      }
      const response = await fetch(
        `${process.env.REACT_APP_SERVER_URL_LEADS}/attendee/addScannedLeadNotes?eventId=${eventsId}&leadId=${singleLeadsData?._id}`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer ${accessToken}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            notes: obj,
          }),
        }
      );

      const data = await response.json();
      const leads = data?.data?.filter((lead) => {
        return lead?._id === singleLeadsData?._id;
      });
      setSingleLeadsData(leads[0]);
      setAttendeesData(data?.data);
      if (response.status !== 200) {
        throw new Error();
      }
    } catch (error) {
      console.log(error);
      dispatch(
        showToast({
          message: "Error",
          category: "danger",
        })
      );
    }
  };

  useEffect(() => {
    if (image) {
      function preventBackgroundScroll(event) {
        event.preventDefault();
        event.stopPropagation();
      }

      document.body.style.overflow = "hidden";
      document.addEventListener("scroll", preventBackgroundScroll, {
        passive: false,
      });

      return () => {
        document.body.style.overflow = "visible";
        document.removeEventListener("scroll", preventBackgroundScroll);
      };
    }
  }, [image]);

  return (
    <div>
      <div
        className="h-full top-0 bg-[rgba(0,0,0,0.4)] z-30 fixed w-[100%] md:left-0"
        onClick={() => {
          setTriggerNotes(false);
        }}
      ></div>

      <div
        className={`h-[94%] w-full z-40 fixed bottom-0 bg-white rounded-t-[10px]  transform transition duration-1000 ease-in-out pb-[50px] md:h-[90%] md:w-[500px] md:left-0 md:right-0 mx-auto md:top-1/2 md:-translate-y-1/2 md:rounded-[10px] md:pb-0 `}
      >
        <div className="w-full h-[60px] fixed top-0 bg-white flex items-center px-[16px] border-b border-[#EDEDED] md:fixed z-10">
          <img
            src="/svgs/Arrowleft.svg"
            className="w-[24px] h-[24px] object-cover cursor-pointer"
            onClick={() => setTriggerNotes(false)}
            alt="arrow"
          />
          <span className="ml-2 text-[22px] font-[500]">Notes</span>
        </div>
        <div className="mt-[60px] pt-[16px] md:pt-1 md:mt-[63px] pb-[60px] pl-3.5 overflow-scroll h-[90%]">
          {singleLeadsData?.Notes?.map((note) => {
            return (
              <div
                key={note._id}
                className="bg-gray-200 mb-4 rounded-[10px] p-[16px] border-[2px] border-white"
              >
                <div className="flex relative">
                  <div>
                    {note.noteImage ? (
                      <img
                        src={note.noteImage}
                        alt=""
                        className="w-[90%] h-[198px] rounded-[5px] object-cover mb-3"
                      />
                    ) : (
                      <></>
                    )}
                    {note.noteText !== "" ? (
                      <div className="sm:text-[14px] text-[12px] font-[400] md:font-semibold text-black w-[90%]">
                        {note.noteText}
                      </div>
                    ) : (
                      <></>
                    )}

                    <span className="flex text-[12px] font-[400] text-gray-500 mt-4">
                      {new Date(note?.noteDate).getDate()}{" "}
                      {new Intl.DateTimeFormat("en-US", optionmymdonth).format(
                        new Date(note?.noteDate)
                      )}
                      , {note.noteDate.toString().substring(0, 4).slice(-4)} •{" "}
                      {moment(note.noteDate).format("LT")}
                    </span>
                  </div>
                  {/* 
                  <img
                    src="/svgs/Menu.svg"
                    alt=""
                    className="right-[0px] absolute cursor-pointer"
                  /> */}
                </div>
              </div>
            );
          })}
        </div>
        {image && (
          <div className="fixed bottom-[65px] bg-black h-[500px] w-full flex flex-col items-center z-50 justify-center rounded-t-[10px] ">
            <div
              className="absolute right-[20px] z-50 top-[27px] bg-white h-[30px] w-[30px] rounded-full flex justify-center items-center"
              onClick={() => setImage("")}
            >
              <img
                src="/svgs/Cross.svg"
                alt="mic"
                className="cursor-pointer h-[22px] "
              />
            </div>

            <img
              src={image}
              alt=""
              className="w-[100vw] h-full rounded-[5px] object-contain"
            />
          </div>
        )}
        <div className="fixed bottom-0 w-full h-[65px] z-50 bg-white flex items-center justify-between md:bg-white border">
          <textarea
            type="text"
            placeholder="Type Notes Here..."
            value={text}
            className="border-0 focus:ring-0 w-[75%] text-[16px] font-[400] h-[45px] md:w-[80%] md:ml-2"
            onChange={(e) => setText(e.target.value)}
          />
          {/* <img
          src="/svgs/Mic.svg"
          alt="mic"
          className="cursor-pointer h-[18px] "
        /> */}
          <label htmlFor="file" className="inline-block	cursor-pointer">
            <img
              src="/svgs/image_upload.svg"
              alt="mic"
              className="cursor-pointer h-[18px]"
            />
          </label>
          <input
            id="file"
            className="hidden"
            type="file"
            accept="image/*"
            placeholder="imageUpload"
            onChange={(e) => {
              const formData = new FormData();
              formData.append("file", e.target.files[0]);

              axios
                .post(
                  `${process.env.REACT_APP_SERVER_URL_LEADS}/user/upload_picture`,
                  formData,
                  {
                    headers: {
                      Authorization:
                        "Bearer " + localStorage.getItem("accessToken"),
                    },
                  }
                )
                .then(function (response) {
                  console.log(response);
                  setImage(response.data.url);
                })
                .catch(function (error) {
                  console.log(error);
                });
            }}
          />

          <img
            src="/svgs/send.svg"
            alt="send"
            className="cursor-pointer mr-3 h-[16px]"
            onClick={() => addNotes()}
          />
        </div>
      </div>
    </div>
  );
};

export default ScannedLeadsNotes;
