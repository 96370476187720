import React, { useEffect, useState } from "react";
import DefaultProfilePicture from "../../common/defaultProfilePicture/DefaultProfilePicture";
import { getAuthenticatedRequest } from "../../utils/API/api.ts";
import { useMatch } from "react-router-dom";
import ScannedLeadsNotes from "./ScannedLeadsNotes";

const ScannedLeads = ({ event }) => {
  const [attendeesData, setAttendeesData] = useState([]);
  const eventsId = useMatch("/attendee/:eventId/*");
  const [triggerNotes, setTriggerNotes] = useState(false);
  const [singleLeadsData, setSingleLeadsData] = useState([]);

  useEffect(() => {
    async function fetch() {
      const leadsData = await getAuthenticatedRequest(
        `/attendee/getLeadDetails?eventId=${eventsId.params.eventId}`
      );
      setAttendeesData(leadsData?.data?.user.scannedLeads);
    }
    if (eventsId?.params?.eventId) {
      fetch();
    }
  }, [event, eventsId?.params.eventId]);

  const handleDownload = async () => {
    const fields = [
      "firstName",
      "lastName",
      "email",
      "countryCode",
      "mobile",
      "organization",
      "jobTitle",
      "Notes",
    ];
    const headerRow = fields.join(",");
    const dataRows = attendeesData.map((row) => {
      const notes = row?.Notes?.map((note) => note?.noteText).join(" ");

      return fields
        .map((field) => {
          if (field === "Notes") {
            return notes || "N/A";
          } else {
            return row.attendee[field] || "N/A";
          }
        })
        .join(",");
    });
    const csvContent =
      "data:text/csv;charset=utf-8," + [headerRow, ...dataRows].join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `Leads.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  return (
    <>
      {triggerNotes && (
        <ScannedLeadsNotes
          setTriggerNotes={setTriggerNotes}
          triggerNotes={triggerNotes}
          eventsId={eventsId.params.eventId}
          singleLeadsData={singleLeadsData}
          setAttendeesData={setAttendeesData}
          setSingleLeadsData={setSingleLeadsData}
        />
      )}
      <div className="fixed top-[0px] w-full bg-white py-4">
        <div
          className="bg-primary text-white px-2 text-[14px] ml-4 font-semibold rounded-sm w-[145px] h-[35px] flex items-center text-center justify-center cursor-pointer hover:opacity-95 "
          onClick={handleDownload}
        >
          Export Leads
          <img
            src="/svgs/downloadIcon.svg"
            className="w-[18px] ml-2 font-[500]"
            alt=""
          />
        </div>
      </div>

      <div className="md:w-[100%] mt-[75px] mb-[20px]">
        {attendeesData?.length > 0 ? (
          <>
            <div className="md:flex flex-wrap">
              {attendeesData
                ?.slice()
                ?.reverse()
                ?.map((attendee, key) => (
                  <div className="mx-[16px] " key={key}>
                    <div
                      className="bg-white mb-4 rounded-[10px] p-[16px] md:border md:w-[350px] mt-[10px] border border-[#EDEDED]"
                      style={{
                        boxShadow: "0px -4px 14px 4px rgba(0, 0, 0, 0.1)",
                      }}
                    >
                      <div className="flex">
                        {attendee.attendee?.profilePicture ? (
                          <img
                            src={attendee.attendee?.profilePicture}
                            className="rounded-full w-[43px] h-[43px] object-cover mr-3 mt-1"
                            alt=""
                          />
                        ) : (
                          <div className="mr-3 mt-1">
                            <DefaultProfilePicture
                              firstName={attendee.attendee.firstName}
                              lastName={attendee.attendee.lastName}
                              style={{
                                width: "43px",
                                height: "43px",
                                borderRadius: "300px",
                                fontSize: "16px",
                              }}
                            />
                          </div>
                        )}

                        <div className="flex items-center">
                          <div className="">
                            <div>
                              <p className="text-[13px] text-gray-500">Name</p>
                              <div className="sm:text-[14px] text-[14x] font-[500] pt-[px] md:font-semibold">
                                {attendee.attendee.firstName}{" "}
                                {attendee.attendee.lastName}{" "}
                              </div>
                            </div>

                            <div className="border-t mt-1 pt-0.5">
                              <p className="text-[13px] text-gray-500">Email</p>
                              <div className="text-[14px] font-medium py-[0px] ">
                                {attendee.attendee?.email}
                              </div>
                            </div>
                            <div className="border-t mt-1 pt-0.5">
                              <p className="text-[13px] text-gray-500">
                                Designation
                              </p>
                              <div className="text-[14px] font-medium py-[0px] ">
                                warpbay
                                {attendee.attendee.jobTitle}
                              </div>
                            </div>
                            <div className="border-t mt-1 pt-0.5">
                              <p className="text-[13px] text-gray-500">
                                Organization
                              </p>
                              <div className="text-[14px] font-medium py-[0px] ">
                                {attendee?.attendee?.organization}
                              </div>
                            </div>
                            <div className="border-t mt-1 pt-0.5">
                              <p className="text-[13px] text-gray-500">
                                Mobile
                              </p>
                              <div className="text-[14px] font-medium py-[0px] ">
                                {attendee?.attendee?.mobile}
                              </div>
                            </div>
                            {/* <div className="text-[12px] font-medium py-[0px] text-gray-500">
                              {attendee.attendee.jobTitle},{" "}
                              {attendee.attendee.organization}
                            </div>
                            <div className="text-[12px] font-medium py-[0px] text-gray-500">
                              {attendee.attendee?.email}
                            </div>
                            <div className="text-[12px] font-medium py-[0px] text-gray-500">
                              {attendee.attendee?.mobile}
                            </div> */}
                          </div>
                        </div>
                      </div>
                      <span
                        className="flex items-center cursor-pointer text-white text-[13px] font-[500] h-[32px] w-full justify-center rounded-[4px] bg-primary md:w-[100%] mt-[15px]"
                        onClick={() => {
                          setTriggerNotes(true);
                          setSingleLeadsData(attendee);
                        }}
                      >
                        {attendee?.Notes?.length > 0
                          ? "View Notes"
                          : "Add Notes"}
                      </span>
                    </div>
                  </div>
                ))}
            </div>
          </>
        ) : (
          <div className="grid w-full place-items-center h-[250px] md:w-[65%]">
            <div>
              <img
                src="/svgs/nullState.svg"
                alt=""
                className="w-[200px] h-[200px]"
              />
              <p className="text-[15px] font-[500] text-[#717171]  text-center">
                Nothing here...
              </p>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default ScannedLeads;
