import React from "react";

const Onsite = ({ tabHover }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height="100%"
      fill={tabHover === "onsite" ? "#A55EEA" : "#C5C5C7"}
      viewBox="0 -960 960 960"
      width="100%"
    >
      <path d="M226-160q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm0-254q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm0-254q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm254 254q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47l-66 66Zm0-254q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm-25 508q-6 0-10.5-4.5T440-175v-44q0-2 4-10l239-239 65 65-239 239q-2 2-10 4h-44Zm279-508q-28 0-47-19t-19-47q0-28 19-47t47-19q28 0 47 19t19 47q0 28-19 47t-47 19Zm40 239-65-65 25-25q8-8 20-8.5t20 7.5l26 26q8 8 7.5 20t-8.5 20l-25 25Z" />
    </svg>
  );
};

export default Onsite;
