import React, { useState, useEffect } from "react";
import styles from "./Landing.module.css";
import LandingRegForm from "./LandingRegForm";
import LandingSchedule from "./LandingSchedule";
import LandingSpeakers from "./LandingSpeakers";
import LandingHost from "./LandingHost";
import LandingAbout from "./LandingAbout";
import LandingSocialBtn from "./LandingSocialBtn";
import moment from "moment";
import { useMatch, useNavigate, useSearchParams } from "react-router-dom";
import GoogleCalendar from "./GoogleCalendar";
import LinkedinReg from "./LinkedinReg";
import LandingSponsors from "./LandingSponsors";
import { Helmet } from "react-helmet";
import axios from "axios";

const LandingPage = ({ singleEvent }) => {
  const navigate = useNavigate();
  const [isRegistered, setIsRegistered] = useState(false);
  const [popup, setPopup] = useState(false);
  const [copied, setCopied] = useState("");
  const xmas95 = new Date(singleEvent.startDate);
  const optionmymdonth = { month: "short" };
  const eventsId = useMatch("/event/:eventId");
  const [searchParams] = useSearchParams();
  const [googleCal, setGoogleCal] = useState(false);
  const [triggerOtp, setTriggerOtp] = useState(false);
  const [registeredUserEmail, setRegisteredUserEmail] = useState("");

  useEffect(() => {
    if (popup) {
      document.body.style.overflow = "hidden";
    }
    return () => {
      document.body.style.overflow = "unset";
    };
  }, [popup]);

  const handleCopy = () => {
    navigator.clipboard.writeText(window.location.href);
    setCopied(window.location.href);
  };

  const closeCopy = () => {
    setPopup(false);
    setCopied("");
  };

  useEffect(() => {
    if (triggerOtp) {
      var newWindow = window.open();
      axios({
        method: "POST",
        url: `${process.env.REACT_APP_SERVER_URL_LEADS}/user/emailotp`,
        data: {
          email: registeredUserEmail.toLowerCase(),
        },
      })
        .then((response) => {
          localStorage.setItem("email", registeredUserEmail.toLowerCase());
          localStorage.setItem("token", response.data.token);
          localStorage.setItem("existingUser", response.data.existingUser);
          setTriggerOtp(false);

          newWindow.location = `${window.location.origin}/login/otp`;
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, [triggerOtp]);

  return (
    <>
      <Helmet>
        <title>Warpbay - {singleEvent?.title}</title>
        <link rel="icon" href={`${singleEvent?.coverImage}`} />
        <meta name="type" property="og:type" content="article" />
        <meta
          name="title"
          property="og:title"
          content={`${singleEvent?.title}`}
        />
        <meta
          name="description"
          property="og:description"
          content={`${singleEvent?.shortDescription}`}
        />
        <meta
          name="image"
          property="og:image"
          content={`${singleEvent?.coverImage}`}
        />
        <meta name="url" property="og:url" content="https://app.warpbay.com" />
      </Helmet>

      {/* {share Popup} */}
      {popup && (
        <div className="">
          <div
            className="h-full top-0 bg-[rgba(0,0,0,0.4)] z-30 fixed w-[100%] md:left-0"
            onClick={closeCopy}
          ></div>
          <div
            className={`mymd:w-[400px] h-[230px] w-full z-50 fixed bottom-0 bg-white rounded-lg p-5 md:left-0 md:right-0 mx-auto md:top-1/2 md:-translate-y-1/2 `}
          >
            <div className="h-[35px] font-semibold text-[20px] border-b-2 outline-offset-4 relative">
              Share This Event
              <span
                className="absolute right-0 rounded-full bg-gray-200 cursor-pointer w-[28px] h-[28px]"
                onClick={closeCopy}
              >
                <div className="flex justify-center items-center text-gray-800 w-[28px] h-[28px]">
                  x
                </div>
              </span>
              <div className="h-[85px] flex justify-evenly items-center mt-2">
                <LandingSocialBtn />
              </div>
              <div className="h-[80px]">
                <span className="font-normal text-[15px]">Share the link:</span>
                <div className="flex justify-evenly">
                  <input
                    type="text"
                    readOnly={true}
                    className="mymd:w-[280px] w-full rounded-lg border-gray-300 shadow font-light text-[14px]"
                    value={window.location.href}
                    onClick={handleCopy}
                  />
                  <button
                    className="border rounded-lg bg-primary text-white text-[14px] ml-2 w-[75px] border-none"
                    onClick={handleCopy}
                  >
                    {copied === "" ? "Copy" : "Copied"}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}

      <div className={styles.nav_parent}>
        <nav className={styles.navbar}>
          <div className={styles.logo}>
            <img src="/svgs/logo.svg" alt="logo" /> Warpbay
          </div>
          <ul className={styles.menu_ul}>
            <li className={styles.nav_home}>
              <a
                href=""
                onClick={() =>
                  window.open(`${window.location.origin}/login`, "_blank")
                }
              >
                Login
              </a>
            </li>
          </ul>
        </nav>
      </div>

      {searchParams.get("tab") !== "register" &&
      searchParams.get("tab") !== "registerlinkedin" &&
      searchParams.get("tab") !== null ? (
        <div
          className="fixed bottom-0 h-[74px] z-30 w-[100vw] mymd:w-[100%] flex mymd:justify-end items-center justify-center bg-white mymd:bg-transparent rounded-t-xl "
          style={{
            filter: "drop-shadow(0px -4px 14px rgba(0, 0, 0, 0.1))",
          }}
        >
          <button
            type="submit"
            className="bg-primary w-[170px] h-[40px] text-white font-bold text-mymd rounded mymd:mr-4"
            onClick={() => {
              navigate(`/event/${eventsId.params.eventId}?tab=${"register"}`);
            }}
          >
            Register
          </button>
        </div>
      ) : (
        <></>
      )}
      <div className="pt-0 mymd:pt-18 mymd:mb-0 mx-auto mymd:grid place-items-center w-[93vw] mymd:max-w-[1440px] mymd:w-full mb-[100px] ">
        {/* {Navbar}   */}

        {/* {title and coverImage} */}
        <div className="mymd:sticky mymd:top-0 z-20 mymd:bg-white mymd:mt-[0px] mb-[12px] mymd:h-[65px] mymd:grid place-items-center mt-[65px]">
          <div className="mymd:mr-0 mymd:w-[824px] mymd:flex mymd:mt-[0px] mt-0 mymd:flex items-center justify-between ">
            <h1 className="text-[22px] mymd:text-[24px] font-semibold">
              {" "}
              {singleEvent?.title}
            </h1>
            <div>
              <div className="hidden mymd:flex text-primary font-[600] items-center justify-between w-64">
                <span
                  className="flex items-center cursor-pointer"
                  onClick={() => {
                    setPopup(true);
                  }}
                >
                  {" "}
                  <img
                    src="/svgs/SharePurple.svg"
                    alt="share"
                    className="mr-2"
                  />{" "}
                  Share
                </span>
                <span
                  className="flex items-center cursor-pointer"
                  onClick={() => setGoogleCal(true)}
                >
                  <img
                    src="/svgs/calendar_monthcalendar.svg"
                    alt="calender"
                    className="mr-2"
                  />
                  Add to calender
                </span>
              </div>
            </div>
          </div>
        </div>

        <div className={styles.img_div}>
          <img
            src={singleEvent?.coverImage}
            alt=""
            className={"w-full h-full rounded-md object-cover"}
          />
        </div>
        <div className="bg-white">
          {/* {event blocks} */}
          <div className="mymd:flex justify-between mymd:h-[136px] mymd:w-[824px] w-[100%] mymd:mb-4">
            {/* {1st item} */}
            <div className="mymd:w-[250px] bg-[#F5F5F5] rounded-xl mymd:p-3.5 flex h-[56px] mymd:h-[136px] mymd:flex-col mymd:items-start items-center overflow-hidden">
              <span className="text-base text-[#727374] mymd:text-black mx-3 mymd:mx-0 font-medium">
                Event By
              </span>
              <div className="flex items-center mymd:mt-2 ml-7 mymd:ml-0">
                <div className="ml-2 mymd:ml-0">
                  {singleEvent.primaryOrganizer?.profilePicture ? (
                    <div className="h-8 w-8 rounded-full object-cover mr-0">
                      <img
                        src={singleEvent?.primaryOrganizer.profilePicture}
                        alt="user"
                        className="h-8 w-8 rounded-full object-cover mr-0"
                      />
                    </div>
                  ) : (
                    <div className="w-8 h-8 rounded-full bg-primary flex items-center justify-center mr-0 text-white text-lg font-medium uppercase">
                      {singleEvent?.primaryOrganizer.organization.charAt(0)}
                    </div>
                  )}
                </div>
                <span className="mymd:line-clamp-2 line-clamp-1 font-normal ml-1.5">
                  {singleEvent?.primaryOrganizer.organization}
                </span>
              </div>
            </div>

            {/* {2nd item} */}
            <div className="mymd:w-[300px] bg-[#F5F5F5] rounded-xl mymd:my-0 mymd:mx-2 my-2 mymd:p-3.5 flex h-[88px] mymd:h-[136px] mymd:flex-col mymd:items-start items-center overflow-hidden">
              <span className="font-medium text-base text-[#727374] mymd:text-black mx-3 mymd:mx-0 ">
                Date & Time
              </span>
              <div>
                <span className="flex items-center cursor-pointer my-3 font-normal">
                  {" "}
                  <img
                    src="/svgs/calender.svg"
                    alt="calender"
                    className="mr-2"
                  />{" "}
                  {new Date(singleEvent?.startDate)
                    .toString()
                    .substring(0, 10)
                    .slice(-10) ===
                  new Date(singleEvent?.endDate)
                    .toString()
                    .substring(0, 10)
                    .slice(-10) ? (
                    <>
                      {new Date(singleEvent?.startDate).getDate()}{" "}
                      {new Intl.DateTimeFormat("en-US", optionmymdonth).format(
                        xmas95
                      )}
                    </>
                  ) : (
                    <>
                      {new Date(singleEvent?.startDate).getDate()}{" "}
                      {new Intl.DateTimeFormat("en-US", optionmymdonth).format(
                        new Date(singleEvent.startDate)
                      )}{" "}
                      to {new Date(singleEvent?.endDate).getDate()}{" "}
                      {new Intl.DateTimeFormat("en-US", optionmymdonth).format(
                        new Date(singleEvent?.endDate)
                      )}
                    </>
                  )}
                </span>
                <span className="flex items-center cursor-pointer font-normal ">
                  {" "}
                  <img src="/svgs/Accesstime.svg" alt="time" className="mr-2" />
                  <div className="line-clamp-1">
                    {moment(singleEvent?.startDate).format("LT")} to{" "}
                    {moment(singleEvent?.endDate).format("LT")}
                  </div>
                </span>
              </div>
            </div>

            {/* {3rd item} */}
            <div className="mymd:w-[414px] bg-[#F5F5F5] rounded-xl mymd:p-3.5 flex h-[88px] mymd:h-[136px] mymd:flex-col mymd:items-start items-center pr-2 mymd:pr-0 overflow-hidden">
              <span className="font-medium text-base text-[#727374] mymd:text-black mx-3 mymd:mx-0">
                Location
              </span>
              <span
                className="flex cursor-pointer font-normal mymd:w-[302px] text-primary underline mymd: mt-[10px] ml-7 mymd:ml-0"
                onClick={() => {
                  window.open(singleEvent?.location?.landmark, "_blank");
                }}
              >
                <img
                  src="/svgs/Location.svg"
                  alt="location"
                  className="mr-1 h-[24px]"
                />
                <div className="line-clamp-2">
                  {singleEvent.location?.addressLine1},{" "}
                  {singleEvent.location?.city}, {singleEvent.location?.state}
                </div>
              </span>
            </div>
            <div className="mymd:hidden flex text-primary justify-around h-10 mt-4 font-bold text-xs">
              <span
                className="flex items-center justify-center cursor-pointer border w-[43%] rounded border-primary"
                onClick={() => {
                  setPopup(true);
                }}
              >
                {" "}
                <img
                  src="/svgs/SharePurple.svg"
                  alt="share"
                  className="mr-2"
                />{" "}
                Share
              </span>
              <span
                className="flex items-center cursor-pointer justify-center cursor-pointer border w-[43%] rounded border-primary"
                onClick={() => setGoogleCal(true)}
              >
                <img
                  src="/svgs/calendar_monthcalendar.svg"
                  alt="calender"
                  className="mr-2"
                />
                Add to calender
              </span>
            </div>
          </div>
        </div>

        {/* {tabs} */}
        <div className={styles.modules_grid}>
          <div className={styles.modules_box1}>
            <div className={styles.modules_bar} id="module">
              <div
                onClick={() => {
                  navigate(
                    `/event/${eventsId.params.eventId}?tab=${"register"}`
                  );
                }}
                style={
                  searchParams.get("tab") === "register" ||
                  searchParams.get("tab") === "registerlinkedin" ||
                  searchParams.get("tab") === null
                    ? { color: "black" }
                    : {}
                }
                className={
                  searchParams.get("tab") === "register" ||
                  searchParams.get("tab") === "registerlinkedin" ||
                  searchParams.get("tab") === null
                    ? "font-[600] underline underline-offset-8 decoration-black decoration-2"
                    : {}
                }
              >
                Register
              </div>
              <div
                onClick={() => {
                  navigate(`/event/${eventsId.params.eventId}?tab=${"about"}`);
                }}
                style={
                  searchParams.get("tab") === "about" ? { color: "black" } : {}
                }
                className={
                  searchParams.get("tab") === "about" &&
                  "font-[600] underline underline-offset-8 decoration-black decoration-2"
                }
              >
                About
              </div>
              <div
                onClick={() => {
                  navigate(
                    `/event/${eventsId.params.eventId}?tab=${"schedule"}`
                  );
                }}
                style={
                  searchParams.get("tab") === "schedule"
                    ? { color: "black" }
                    : {}
                }
                className={
                  searchParams.get("tab") === "schedule" &&
                  "font-[600] underline underline-offset-8 decoration-black decoration-2"
                }
              >
                Schedule
              </div>
              {singleEvent.speakers.length === 0 ? (
                <></>
              ) : (
                <div
                  onClick={() => {
                    navigate(
                      `/event/${eventsId.params.eventId}?tab=${"speakers"}`
                    );
                  }}
                  style={
                    searchParams.get("tab") === "speakers"
                      ? { color: "black" }
                      : {}
                  }
                  className={
                    searchParams.get("tab") === "speakers" &&
                    "font-[600] underline underline-offset-8 decoration-black decoration-2"
                  }
                >
                  Speakers
                </div>
              )}
              {singleEvent.exhibitorOrganizations.length === 0 ? (
                <></>
              ) : (
                <div
                  onClick={() => {
                    navigate(
                      `/event/${eventsId.params.eventId}?tab=${"sponsors"}`
                    );
                  }}
                  style={
                    searchParams.get("tab") === "sponsors"
                      ? { color: "black" }
                      : {}
                  }
                  className={
                    searchParams.get("tab") === "sponsors" &&
                    "font-[600] underline underline-offset-8 decoration-black decoration-2"
                  }
                >
                  Sponsors
                </div>
              )}

              <div
                onClick={() => {
                  navigate(
                    `/event/${eventsId.params.eventId}?tab=${"contact"}`
                  );
                }}
                style={
                  searchParams.get("tab") === "contact"
                    ? { color: "black" }
                    : {}
                }
                className={
                  searchParams.get("tab") === "contact" &&
                  "font-[600] underline underline-offset-8 decoration-black decoration-2"
                }
              >
                Contact
              </div>
            </div>
            <div className={styles.modules_box1_render}>
              {searchParams.get("tab") === "schedule" ? (
                <LandingSchedule singleEvent={singleEvent} />
              ) : searchParams.get("tab") === "registerlinkedin" ? (
                isRegistered ? (
                  <div className="mymd:w-[600px] w-[100%] h-[450px] pt-2">
                    <div className="flex">
                      <img
                        src="/svgs/Checkcircle.svg"
                        alt="check"
                        className="mr-1 rounded-full m-[-4px]"
                      />
                      <div className="text-[16px]">
                        You have successfully registered for{" "}
                        {singleEvent?.title}!
                        <br />
                        Please use the QR code to check-in to the event.
                      </div>
                    </div>
                    <span
                      className="flex items-center justify-center cursor-pointer cursor-pointer border w-[170px] h-[40px] rounded border-primary ml-12 mb-7 mt-3"
                      onClick={() => setGoogleCal(true)}
                    >
                      <img
                        src="/svgs/calendar_monthcalendar.svg"
                        alt="calender"
                        className="mr-2"
                      />
                      Add to calender
                    </span>
                    You will also receive an invite on your submitted email ID.
                    <br />
                    <div className="mt-3">
                      Did not receive? Please check your spam folder.
                      <br />
                    </div>
                    <div className="mt-3 text-[#E74C3C] font-[500]">
                      Login to access the event
                      <br />
                    </div>
                    <span
                      className="text-primary font-normal	underline cursor-pointer"
                      onClick={() => setTriggerOtp(true)}
                    >
                      Login here
                    </span>
                  </div>
                ) : (
                  <LinkedinReg
                    setIsRegistered={setIsRegistered}
                    setRegisteredUserEmail={setRegisteredUserEmail}
                  />
                )
              ) : searchParams.get("tab") === "register" ? (
                isRegistered ? (
                  <div className="mymd:w-[600px] w-[100%] h-[450px] pt-2">
                    <div className="flex">
                      <img
                        src="/svgs/Checkcircle.svg"
                        alt="check"
                        className="mr-1 rounded-full m-[-4px]"
                      />
                      <div className="text-[16px]">
                        You have successfully registered for{" "}
                        {singleEvent?.title}!
                        <br />
                        Please use the QR code to check-in to the event.
                      </div>
                    </div>
                    <span
                      className="flex items-center justify-center cursor-pointer cursor-pointer border w-[170px] h-[40px] rounded border-primary ml-12 mb-7 mt-3"
                      onClick={() => setGoogleCal(true)}
                    >
                      <img
                        src="/svgs/calendar_monthcalendar.svg"
                        alt="calender"
                        className="mr-2"
                      />
                      Add to calender
                    </span>
                    You will also receive an invite on your submitted email ID.
                    <br />
                    <div className="mt-3">
                      Did not receive? Please check your spam folder.
                      <br />
                    </div>
                    <div className="mt-3 text-[#E74C3C] font-[500]">
                      Login to access the event
                      <br />
                    </div>
                    <span
                      className="text-primary font-normal	underline cursor-pointer"
                      onClick={() => setTriggerOtp(true)}
                    >
                      Login here
                    </span>
                  </div>
                ) : (
                  <LandingRegForm
                    setIsRegistered={setIsRegistered}
                    setRegisteredUserEmail={setRegisteredUserEmail}
                  />
                )
              ) : searchParams.get("tab") === "speakers" ? (
                <LandingSpeakers singleEvent={singleEvent} />
              ) : searchParams.get("tab") === "sponsors" ? (
                <LandingSponsors singleEvent={singleEvent} />
              ) : searchParams.get("tab") === "contact" ? (
                <LandingHost singleEvent={singleEvent} />
              ) : searchParams.get("tab") === "about" ? (
                <LandingAbout singleEvent={singleEvent} />
              ) : isRegistered ? (
                <div className="mymd:w-[600px] w-[100%] h-[450px] pt-2">
                  <div className="flex">
                    <img
                      src="/svgs/Checkcircle.svg"
                      alt="check"
                      className="mr-1 rounded-full m-[-4px]"
                    />
                    <div className="text-[16px]">
                      You have successfully registered for {singleEvent?.title}!
                      <br />
                      Please use the QR code to check-in to the event.
                    </div>
                  </div>
                  <span
                    className="flex items-center justify-center cursor-pointer cursor-pointer border w-[170px] h-[40px] rounded border-primary ml-12 mb-7 mt-3"
                    onClick={() => setGoogleCal(true)}
                  >
                    <img
                      src="/svgs/calendar_monthcalendar.svg"
                      alt="calender"
                      className="mr-2"
                    />
                    Add to calender
                  </span>
                  You will also receive an invite on your submitted email ID.
                  <br />
                  <div className="mt-3">
                    Did not receive? Please check your spam folder.
                    <br />
                  </div>
                  <div className="mt-3 text-[#E74C3C] font-[500]">
                    Login to access the event
                    <br />
                  </div>
                  <span
                    className="text-primary font-normal	underline cursor-pointer"
                    onClick={() => setTriggerOtp(true)}
                  >
                    Login here
                  </span>
                </div>
              ) : (
                <LandingRegForm
                  setIsRegistered={setIsRegistered}
                  setRegisteredUserEmail={setRegisteredUserEmail}
                />
              )}
            </div>
          </div>
          <div className={styles.modules_box2}></div>
        </div>

        {/* {GooogleCalender Popup} */}
        {googleCal ? (
          <GoogleCalendar
            singleEvent={singleEvent}
            setGoogleCal={setGoogleCal}
          />
        ) : (
          <></>
        )}

        {/* {footer} */}
        <div className={styles.footer_box}>
          <p className="hover:text-primary">Help</p>
          <p
            onClick={() => navigate("/events")}
            className="hover:text-primary hover:font-[500]"
          >
            Events
          </p>
          <p></p>
          <p className="hover:text-primary">Mail Us</p>
          <p></p>
        </div>
      </div>
    </>
  );
};

export default LandingPage;
