import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_EVENT } from "../../../redux/constants/eventConstants";
import { getRequest } from "../../../utils/API/api.ts";
import AttendeeCSVUpload from "./BulkUploadAttendee";
import { useMatch } from "react-router-dom";
import Modal from "../../../common/modals/Modal";
import "react-tooltip/dist/react-tooltip.css";
import RegistrationTabs from "./RegistrationTabs";

function Registrations() {
  const eventsId = useMatch("/events/:eventId");
  const [isBulkUpload, setIsBulkUpload] = useState(false);
  const [search, setSearch] = useState("");
  const [total, setTotal] = useState({
    registerdTotal: 0,
    filteredTotal: 0,
  });
  const [attendedRegistrations, setAttendedRegistrations] = useState([]);
  const [registrations, setRegistrations] = useState([]);
  const [next, setNext] = useState({
    registerdNext: "",
    filteredNext: "",
  });
  const [previous, setPrevious] = useState({
    registerdPrevious: "",
    filteredPrevious: "",
  });
  const dispatch = useDispatch();
  const [tab, setTab] = useState("Registered");
  const event = useSelector((state) => state.eventData);
  const searchValue = useSelector((state) => state.searchRegistration);
  const [totalPages, setTotalPages] = useState({
    registerdTotalPages: 0,
    filteredTotalPages: 0,
  });
  const [currentPage, setCurrentPage] = useState({
    registeredCurrent: 1,
    filteredCurrent: 1,
  });

  // UseEffect for initial registrations fetching
  useEffect(() => {
    if (eventsId.params.eventId && !isBulkUpload && event?.title) {
      getAllEventAttendees(`/attendee/${eventsId.params.eventId}`);
    }
  }, [eventsId, isBulkUpload, event?.title]);

  //UseEffect for search related functionality
  useEffect(() => {
    async function fetchData() {
      setNext(null);
      const data = await getRequest(
        `/attendee/${eventsId.params.eventId}/search/attendee?name=${searchValue.value}&search=${tab}`
      );
      if (tab === "Registered") {
        setRegistrations([...data.data.registrations.attendees]);
      }
    }
    if (searchValue.value) {
      fetchData();
    } else if (searchValue.value === "" || searchValue.value === " ") {
      getAllEventAttendees(`/attendee/${eventsId.params.eventId}`);
    }
  }, [searchValue.value]);

  //fetch all registered event attendees
  const getAllEventAttendees = async (route) => {
    const response = await getRequest(route || next);
    if (
      registrations.length === 0 &&
      (searchValue.value === "" || searchValue.value === " ")
    ) {
      setRegistrations(response.data?.attendees);
    } else {
      setRegistrations(response.data?.attendees);
    }
    setNext({ ...next, registerdNext: response?.data?.next });
    setPrevious({ ...previous, registerdPrevious: response?.data?.previous });
    setTotal({
      ...total,
      registerdTotal: response?.data?.totalAttendees[0]?.total,
    });
    setTotalPages({
      ...totalPages,
      registerdTotalPages: response.data?.totalPages,
    });
    setCurrentPage({
      ...currentPage,
      registeredCurrent: response.data?.currentPage,
    });
    dispatch({
      type: UPDATE_EVENT,
      payload: {
        attendees: [response.data?.attendees],
      },
    });
  };

  //fetch all attendee and not attendee attendees
  const getAttendedAttendees = async (route) => {
    const response = await getRequest(route);
    setNext({ ...next, filteredNext: response?.data?.next });
    setPrevious({ ...previous, filteredPrevious: response?.data?.previous });
    setTotal({ ...total, filteredTotal: response?.data?.totalAttendees });
    setTotalPages({
      ...totalPages,
      filteredTotalPages: response.data?.totalPages,
    });
    setCurrentPage({
      ...currentPage,
      filteredCurrent: response.data?.currentPage,
    });

    setAttendedRegistrations(response?.data?.registrations);
  };

  const handleDownload = async () => {
    let dataToDownload;
    if (tab === "Registered") {
      const response = await getRequest(
        `/attendee/${eventsId.params.eventId}?limit=${total?.registerdTotal}`
      );
      if (response.status === 200) {
        dataToDownload = response.data.attendees;
      }
    } else {
      let url;
      if (tab === "Attended") {
        url = `attendee/${eventsId.params.eventId}/attended/?hasAttended=true&location=eventCheckin&limit=0`;
      } else if (tab === "NotAttended") {
        url = `attendee/${eventsId.params.eventId}/attended/?hasAttended=false&location=eventCheckin&limit=0`;
      }
      const response = await getRequest(url);
      if (response.status === 200) {
        dataToDownload = response?.data?.registrations;
      }
    }

    const fields = [
      "firstName",
      "lastName",
      "email",
      "countryCode",
      "mobile",
      "organization",
      "country",
      "jobTitle",
      "registrationDate",
      "lastCheckInTime",
      "label",
    ];
    const headerRow = fields.join(",");
    const dataRows = dataToDownload.map((row) => {
      let country = "";
      if (row.location && row.location.country && row.location.country.name) {
        country = row.location.country.name;
      }
      if (row.attendee && Array.isArray(row.attendee[0].eventSpecificData)) {
        const eventSpecificData = row.attendee[0].eventSpecificData;
        const matchingData = eventSpecificData.find(
          (data) => data.eventId === eventsId.params.eventId
        );
        if (matchingData) {
          const registrationDate = formateDateTime(matchingData.timeStamp);
          const lastCheckInTime = formateDateTime(
            getLatestCheckinTime(matchingData)
          );
          const label = matchingData.label;

          return fields.map((field) => {
            if (field === "registrationDate") {
              return registrationDate;
            } else if (field === "lastCheckInTime") {
              return lastCheckInTime;
            } else if (field === "country") {
              return country || "N/A";
            } else if (field === "label") {
              return label || "N/A";
            } else {
              return row[field] || "N/A";
            }
          });
        }
      }

      return fields.map((field) => {
        if (field === "country") {
          return country || "N/A";
        } else {
          return row[field] || "N/A";
        }
      });
    });
    const csvContent =
      "data:text/csv;charset=utf-8," + [headerRow, ...dataRows].join("\n");
    const encodedUri = encodeURI(csvContent);
    const link = document.createElement("a");
    link.setAttribute("href", encodedUri);
    link.setAttribute("download", `${tab}.csv`);
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const formateDateTime = (data) => {
    if (data === null) {
      return "N/A";
    } else {
      const utcDate = new Date(data);
      const options = {
        day: "2-digit",
        month: "long",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        timeZone: "Asia/Kolkata",
      };
      const dateString = utcDate.toLocaleDateString("en-IN", options);
      return dateString;
    }
  };

  const getLatestCheckinTime = (eventData) => {
    if (eventData) {
      const lastCheckIn = eventData.checkInData
        .filter((checkIn) => checkIn.location === "eventCheckin")
        .sort((a, b) => new Date(b.entry) - new Date(a.entry))[0];
      return lastCheckIn ? lastCheckIn.entry : null;
    }
  };
  return (
    <div className="md:ml-[0px] md:mt-[0px] md:w-[900px] pb-12 ">
      <div className="py-0">
        <div className="font-[600] w-[335px] md:w-full text-[24px] pt-5 text-black flex justify-between items-center md:sticky md:z-10 md:top-0 bg-white">
          <p className="text-[21px]">Registrations</p>

          <div className="flex">
            <div
              className="bg-primary text-white h-8 py-1 px-2 text-[14px] ml-4 my-1 font-semibold rounded-sm w-[145px] h-[35px] flex items-center text-center justify-center cursor-pointer hover:opacity-95"
              onClick={handleDownload}
            >
              Export Data
              <img
                src="/svgs/downloadIcon.svg"
                className="w-[18px] ml-2 font-[500]"
              />
            </div>
            <button
              className="bg-gray-200 text-gray-800 h-8 py-1 px-2 text-[14px] ml-4 my-1 font-semibold rounded-sm w-[130px] h-[35px] hover:opacity-95"
              // btnText="Download"
              onClick={() => {
                setIsBulkUpload(true);
              }}
            >
              Bulk Upload
            </button>
          </div>
        </div>
        <form className="flex md:hidden w-[340px] mx-auto items-center my-4">
          <label htmlFor="simple-search" className="sr-only">
            Search
          </label>
          <div className=" w-full ">
            <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              type="text"
              id="simple-search"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-primary focus:border-primary block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary dark:focus:border-primary"
              placeholder="Search"
              required={true}
              onChange={(e) => {
                if (e.target.value) {
                  setSearch(e.target.value);
                } else {
                  setRegistrations([]);
                  getAllEventAttendees(`/attendee/${eventsId.params.eventId}`);
                }
              }}
            ></input>
          </div>
        </form>

        <div className="flex flex-row items-center w-[340px] md:w-[900px] mt-0  text-sm font-medium text-center text-gray-500 border-b border-gray-200 dark:text-gray-400 dark:border-gray-700 justify-between md:sticky md:z-10 md:top-[63px] bg-white md:pt-2">
          <ul className="flex flex-wrap -mb-px">
            <li
              className="mr-2 cursor-pointer"
              onClick={() => setTab("Registered")}
            >
              <span
                className={`inline-block px-0 py-4 ${
                  tab === "Registered"
                    ? "text-primary border-primary underline underline-offset-8	 decoration-2"
                    : ""
                } rounded-t-lg border-b-2 border-transparent dark:hover:text-gray-300 text-[15px] `}
              >
                Registered&nbsp;
                {tab === "Registered" ? (
                  <>
                    (
                    {total?.registerdTotal === undefined
                      ? 0
                      : total?.registerdTotal}
                    )
                  </>
                ) : (
                  <></>
                )}
              </span>
            </li>
            <li
              className="mr-2 cursor-pointer"
              onClick={() => setTab("Attended")}
            >
              <span
                className={`inline-block px-4 py-4 ${
                  tab === "Attended"
                    ? "text-primary border-primary underline underline-offset-8	 decoration-2"
                    : ""
                } rounded-t-lg border-b-2 border-transparent dark:hover:text-gray-300 text-[15px]`}
                onClick={() => {
                  getAttendedAttendees(
                    `attendee/${eventsId.params.eventId}/attended/?hasAttended=true&location=eventCheckin`
                  );
                }}
              >
                Attended{" "}
                {tab === "Attended" ? (
                  <>
                    (
                    {total?.filteredTotal === undefined
                      ? 0
                      : total?.filteredTotal}
                    )
                  </>
                ) : (
                  <></>
                )}
              </span>
            </li>
            <li
              className="mr-2 cursor-pointer"
              onClick={() => setTab("NotAttended")}
            >
              <span
                className={`inline-block py-4 ${
                  tab === "NotAttended"
                    ? "text-primary border-primary underline underline-offset-8	 decoration-2"
                    : ""
                } rounded-t-lg border-b-2 border-transparent dark:hover:text-gray-300 text-[15px]`}
                onClick={() => {
                  getAttendedAttendees(
                    `attendee/${eventsId.params.eventId}/attended/?hasAttended=false&location=eventCheckin`
                  );
                }}
              >
                Not attended{" "}
                {tab === "NotAttended" ? (
                  <>
                    (
                    {total?.filteredTotal === undefined
                      ? 0
                      : total?.filteredTotal}
                    )
                  </>
                ) : (
                  <></>
                )}
              </span>
            </li>
          </ul>
        </div>

        <div className="w-[335px] md:w-[900px] min-h-[270px]">
          {tab === "Registered" && registrations ? (
            <RegistrationTabs
              registrations={registrations}
              getAllEventAttendees={getAllEventAttendees}
              searchValue={searchValue}
              eventsId={eventsId}
              total={total.registerdTotal}
              tab={tab}
              next={next?.registerdNext}
              previous={previous?.registerdPrevious}
              totalPages={totalPages.registerdTotalPages}
              currentPage={currentPage.registeredCurrent}
              getAttendedAttendees={getAttendedAttendees}
            />
          ) : tab === "Attended" && attendedRegistrations ? (
            <RegistrationTabs
              registrations={attendedRegistrations}
              getAllEventAttendees={getAllEventAttendees}
              searchValue={searchValue}
              eventsId={eventsId}
              total={total.filteredTotal}
              tab={tab}
              next={next?.filteredNext}
              previous={previous?.filteredPrevious}
              totalPages={totalPages.filteredTotalPages}
              currentPage={currentPage.filteredCurrent}
              getAttendedAttendees={getAttendedAttendees}
            />
          ) : tab === "NotAttended" && attendedRegistrations ? (
            <RegistrationTabs
              registrations={attendedRegistrations}
              getAllEventAttendees={getAllEventAttendees}
              searchValue={searchValue}
              eventsId={eventsId}
              total={total.filteredTotal}
              tab={tab}
              next={next?.filteredNext}
              previous={previous?.filteredPrevious}
              totalPages={totalPages.filteredTotalPages}
              currentPage={currentPage.filteredCurrent}
              getAttendedAttendees={getAttendedAttendees}
            />
          ) : (
            <></>
          )}

          <div className="">
            <Modal isOpen={isBulkUpload} setIsOpen={setIsBulkUpload}>
              <AttendeeCSVUpload
                setIsBulkUpload={setIsBulkUpload}
                getAllEventAttendees={getAllEventAttendees}
              />
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Registrations;
