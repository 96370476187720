import React from "react";
import { useNavigate } from "react-router-dom";

const ScanIcon = ({ style }) => {
  const navigate = useNavigate();
  return (
    <>
      <div
        className={`h-[45px] w-[148px] rounded-[35px] flex justify-center items-center bg-primary fixed mx-auto left-0 right-0 bottom-0 z-20 ${
          style ? style : "mb-[30px]"
        } md:hidden`}
        onClick={() => {
          navigate("/connections/scan?camera=environment");
        }}
      >
        <img
          src="/svgs/qr_code_scanner.svg"
          alt=""
          className="w-[23px] mr-1.5"
        />
        <p className="text-white font-[500] text-[12px]">Scan Badge</p>
      </div>
    </>
  );
};

export default ScanIcon;
