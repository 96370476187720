import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { UPDATE_EVENT } from "../../../redux/constants/eventConstants";
import FileInput from "../../../common/inputElements/FileInput";
import TextArea from "../../../common/inputElements/TextArea";
import throttle from "../../../utils/throttle";
import TextInput from "../../../common/inputElements/TextInput";
import { useMatch, useNavigate } from "react-router-dom";
import { showToast } from "../../../redux/actions/toastActions";
import { getSingleEvent } from "../../../redux/actions/eventActions";

export default function AddOrganzationForm() {
  const [companyLogo, setCompanyLogo] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState(false);
  const dispatch = useDispatch();
  const eventsId = useMatch("/events/:eventId/register-exhibitor-organization");
  const navigate = useNavigate();
  const [characterCount, setCharacterCount] = useState("");
  const singleEvent = useSelector((state) => state.eventData);
  let optionmymdonth = { month: "short" };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {},
    mode: "onChange",
  });

  useEffect(() => {
    dispatch(getSingleEvent({ eventId: eventsId.params.eventId }));
    return () => {};
  }, []);

  // onSubmit form create exhibitor organization
  async function onSubmit(data) {
    setIsSubmitting(true);

    if (Object.values(data).length <= 0) {
      dispatch(
        showToast({
          message: "Please fill all the details",
          category: "danger",
        })
      );
    } else {
      try {
        if (!companyLogo) {
          dispatch(
            showToast({
              message: "Please upload company logo",
              category: "danger",
            })
          );
          setIsSubmitting(false);
          return;
        }

        // adding data to the event model
        const updatedEvent = await axios.patch(
          `${process.env.REACT_APP_SERVER_URL_LEADS}/event/${eventsId.params.eventId}/addOrganization-external-form`,
          {
            data: {
              ...data,
              logo: companyLogo,
              inviteTeam:
                data.inviteTeam.length > 0 ? data.inviteTeam.split(",") : [],
            },
          }
        );
        if (updatedEvent.status === 200) {
          dispatch(
            showToast({
              message:
                "Thank you for registering, you have been added successfully",
              category: "success",
            })
          );
          setSuccess(true);
        }
        setIsSubmitting(false);
        reset();
        setCompanyLogo("");
      } catch (error) {
        console.log(error);
        dispatch(
          showToast({
            message: "Some error occured. Please try again",
            category: "danger",
          })
        );
        setIsSubmitting(false);
      }
    }
  }

  return (
    <>
      {singleEvent?.startDate === undefined ? (
        <></>
      ) : !success ? (
        <>
          <div className="grid place-items-center my-[35px] ">
            <div
              className="w-[600px] flex h-full flex-col overflow-y-scroll bg-white py-6 "
              style={{ boxShadow: "0px -4px 14px 4px rgba(0, 0, 0, 0.1)" }}
            >
              <div className="px-4 sm:px-6">
                <p className="text-[21px] pt-2 pb-[10px] font-[600] text-gray-900">
                  Register as exhibitor for{" "}
                  <span className="text-primary">{singleEvent?.title}</span> on{" "}
                  <span className="text-primary">
                    {" "}
                    {new Date(singleEvent?.startDate).getDate()}{" "}
                    {new Intl.DateTimeFormat("en-US", optionmymdonth).format(
                      new Date(singleEvent?.startDate)
                    )}
                    ,{" "}
                    {singleEvent?.startDate
                      .toString()
                      .substring(0, 4)
                      .slice(-4)}
                    .
                  </span>
                </p>
              </div>
              <div className="relative mt-6 flex-1 px-4 sm:px-6">
                <form
                  onSubmit={handleSubmit((data) =>
                    throttle(() => onSubmit(data), 5000)
                  )}
                >
                  <TextInput
                    register={register}
                    type="text"
                    id={"companyName"}
                    label="Company Name "
                    required
                    placeholder="Company Name"
                  />

                  <TextInput
                    register={register}
                    type="email"
                    id={"email"}
                    placeholder="Email Address"
                    label="Company Email (optional)"
                    errors={errors}
                    pattern={
                      /^([a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)$/i
                    }
                  />

                  <TextInput
                    register={register}
                    type="url"
                    id={"linkedinUrl"}
                    placeholder="LinkedIn URL"
                    label="Linkedin URL"
                  />

                  <FileInput
                    profilePicture={companyLogo}
                    setProfilePicture={setCompanyLogo}
                    label="Company Logo (Recommended size - 500 X 500 pixels)"
                    mb="12"
                    required
                  />

                  <TextInput
                    register={register}
                    type="url"
                    id={"website"}
                    required
                    label="website link"
                    placeholder="website link"
                  />

                  <TextArea
                    register={register}
                    type="text"
                    id={"bio"}
                    placeholder="Bio"
                    label={
                      characterCount
                        ? `Bio (${
                            characterCount.split("").length
                          }/500 characters)`
                        : "Bio"
                    }
                    maxLength={500}
                    onChange={(e) => setCharacterCount(e.target.value)}
                  />
                  <div className="mb-[42px]"></div>

                  <TextInput
                    register={register}
                    type="text"
                    id={"industry"}
                    label="Industry"
                    required
                    placeholder="Industry"
                  />

                  <TextArea
                    register={register}
                    type="text"
                    id={"inviteTeam"}
                    label="Invite team (with comma separated emails)"
                    placeholder="Event description"
                    mt="40px"
                  />
                  <div className="mb-[42px]"></div>

                  <input
                    value={isSubmitting ? "Loading..." : "Join as an exhibitor"}
                    type="submit"
                    className="primary_submit"
                  />
                </form>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="grid place-items-center my-[35px]">
          <div
            className="h-[300px] w-[600px] mt-[50px] flex flex-col items-center border justify-center"
            style={{ boxShadow: "0px -4px 14px 4px rgba(0, 0, 0, 0.1)" }}
          >
            <img
              src="/svgs/Checkcircle.svg"
              alt="success"
              className="w-[90px]"
            />
            <span className="text-[30px] mt-[10px] font-[500]">
              Thank You for Registering
            </span>
            <p className="mt-[10px] font-[500]">
              You have successfully registered as an exhibitor.
            </p>
          </div>
        </div>
      )}
    </>
  );
}
