import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import FileInput from "../../../common/inputElements/FileInput";
import TextArea from "../../../common/inputElements/TextArea";
import throttle from "../../../utils/throttle";
import TextInput from "../../../common/inputElements/TextInput";
import { useMatch, useSearchParams } from "react-router-dom";
import { getSingleEvent } from "../../../redux/actions/eventActions";
import { showToast } from "../../../redux/actions/toastActions";

export default function RegisterExhibitor() {
  const [profilePicture, setProfilePicture] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState({
    isSuccess: null,
    message: "",
  });
  const dispatch = useDispatch();
  const eventsId = useMatch("/events/:eventId/register-exhibitor");
  const [searchParams] = useSearchParams();
  const [characterCount, setCharacterCount] = useState("");
  const singleEvent = useSelector((state) => state.eventData);
  let optionmymdonth = { month: "short" };

  const {
    register,
    handleSubmit,
    formState: { errors },
    reset,
  } = useForm({
    defaultValues: {
      companyName: searchParams.get("companyName"),
      email: searchParams.get("email"),
    },
    mode: "onChange",
  });

  useEffect(() => {
    dispatch(getSingleEvent({ eventId: eventsId.params.eventId }));
    return () => {};
  }, []);

  async function onSubmit(data) {
    if (!searchParams.get("companyId")) {
      dispatch(
        showToast({
          message: "Company id can not be found",
          category: "danger",
        })
      );
    }

    setIsSubmitting(true);

    if (Object.values(data).length <= 0) {
      dispatch(
        showToast({
          message: "Please fill all the details",
          category: "danger",
        })
      );
      return;
    } else if (!profilePicture) {
      dispatch(
        showToast({
          message: "Please add profile picture",
          category: "danger",
        })
      );
      return;
    } else {
      try {
        const response = await fetch(
          `${process.env.REACT_APP_SERVER_URL_LEADS}/event/${eventsId.params.eventId}/registerExhibitorAndSponsor`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              userEmail: data.email.toLowerCase(),
              data: {
                userData: {
                  firstName: data.firstName,
                  lastName: data.lastName,
                  organization: data.companyName,
                  jobTitle: data.jobTitle,
                  email: data.email,
                  linkedinUrl: data.linkedinUrl,
                  profilePicture: profilePicture,
                },
                eventSpecificData: {
                  companyId: searchParams.get("companyId"),
                  companyName: data.companyName,
                  jobTitle: data.jobTitle,
                  eventId: eventsId.params.eventId,
                  bio: data.bio,
                  eventSpecificRole: "exhibitorAndSponsor",
                },
              },
            }),
          }
        );

        const allSponsors = await response.json();
        console.log(allSponsors, "allSponsors- 72");
        if (allSponsors.code !== 200) {
          setSuccess({
            isSuccess: false,
            message: allSponsors.message,
          });
          dispatch(
            showToast({
              message: allSponsors.message,
              category: "danger",
            })
          );
        } else {
          setSuccess({
            isSuccess: true,
            message: "You have successfully joined the team.",
          });
          dispatch(
            showToast({
              message:
                "Thank you for registering, you have been successfully added",
              category: "success",
            })
          );
        }

        setProfilePicture("");
        reset();
        setIsSubmitting(false);
      } catch (error) {
        setIsSubmitting(false);
      }
    }
  }

  return (
    <>
      {singleEvent?.startDate === undefined ? (
        <></>
      ) : success?.isSuccess === null ? (
        <>
          <div className="grid place-items-center my-[35px]">
            <div
              className="w-[600px] flex h-full flex-col overflow-y-scroll bg-white py-6"
              style={{ boxShadow: "0px -4px 14px 4px rgba(0, 0, 0, 0.1)" }}
            >
              <div className="px-4 sm:px-6">
                <p className="text-[21px] pt-2 pb-[10px] font-[600] text-gray-900">
                  Join team{" "}
                  <span className="text-primary">
                    {searchParams.get("companyName")}
                  </span>{" "}
                  for <span className="text-primary">{singleEvent?.title}</span>
                  .
                </p>
                <p className="font-[600] text-gray-900">
                  Event Date:{" "}
                  <span className="text-primary">
                    {new Date(singleEvent?.startDate).getDate()}{" "}
                    {new Intl.DateTimeFormat("en-US", optionmymdonth).format(
                      new Date(singleEvent?.startDate)
                    )}
                    ,{" "}
                    {singleEvent?.startDate
                      .toString()
                      .substring(0, 4)
                      .slice(-4)}
                  </span>
                </p>
              </div>
              <div className="relative mt-6 flex-1 px-4 sm:px-6">
                <form onSubmit={handleSubmit((data) => onSubmit(data))}>
                  <TextInput
                    register={register}
                    type="url"
                    id={"linkedinUrl"}
                    placeholder="LinkedIn URL"
                    label="Linkedin URL"
                  />

                  <TextInput
                    register={register}
                    // disabled="true"
                    type="email"
                    id={"email"}
                    placeholder="Email Address"
                    label="Email"
                    errors={errors}
                    required
                    pattern={
                      /^([a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)$/i
                    }
                  />

                  <TextInput
                    register={register}
                    type="text"
                    id={"firstName"}
                    placeholder="First Name"
                    label="First Name"
                    required
                  />

                  <TextInput
                    register={register}
                    type="text"
                    id={"lastName"}
                    placeholder="Last Name"
                    label="Last Name"
                    required
                  />

                  <TextInput
                    register={register}
                    type="text"
                    disabled="true"
                    id={"companyName"}
                    label="Company Name"
                    required
                    placeholder="Company Name"
                  />

                  <TextInput
                    register={register}
                    type="text"
                    id={"jobTitle"}
                    label="Job Title"
                    required
                    placeholder="Job Title"
                  />

                  <FileInput
                    profilePicture={profilePicture}
                    setProfilePicture={setProfilePicture}
                    label="Profile Picture (Recommended size - 500 X 500 pixels)"
                    mb="12"
                    required
                  />

                  <TextArea
                    register={register}
                    type="text"
                    id={"bio"}
                    label={
                      characterCount
                        ? `Bio (${
                            characterCount.split("").length
                          }/500 characters)`
                        : "Bio"
                    }
                    maxLength={500}
                    onChange={(e) => setCharacterCount(e.target.value)}
                    placeholder="Bio"
                  />
                  <div className="mb-[42px]"></div>

                  <input
                    value={isSubmitting ? "Loading..." : "Join the team"}
                    type="submit"
                    className="primary_submit"
                  />
                </form>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="grid place-items-center my-[35px]">
          <div
            className="h-[300px] w-[600px] mt-[50px] flex flex-col items-center border justify-center"
            style={{ boxShadow: "0px -4px 14px 4px rgba(0, 0, 0, 0.1)" }}
          >
            {success?.isSuccess ? (
              <img
                src="/svgs/Checkcircle.svg"
                alt="success"
                className="w-[90px]"
              />
            ) : (
              <img
                src="/svgs/Cancelled.svg"
                alt="failed"
                className="w-[90px]"
              />
            )}

            <span className="text-[30px] mt-[10px] font-[500]">
              {success?.isSuccess
                ? "Thank You for Registering"
                : "Failed to Register"}
            </span>
            <p className="mt-[10px] font-[500]">{success?.message}</p>
          </div>
        </div>
      )}
    </>
  );
}
