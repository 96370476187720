import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch,useSelector } from "react-redux";
import axios from "axios";
import { useMatch, useNavigate } from "react-router-dom";
import { showToast } from "../../../../../redux/actions/toastActions";
import TextInput from "../../../../../common/inputElements/TextInput";

export default function VerifyEmailAndDomain({
  open,
  setOpen,
  isEdit,
  setIsEdit,
  singleEvent,
}) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [senderOrganizerDomain, setSenderOrganizerDomain] = useState(null);
 
  const {
    register,
    handleSubmit,
    formState: { errors },
    // setValue,
    reset,
  } = useForm({
    defaultValues: {},
  });

  const dispatch = useDispatch();
  useEffect(() => {
    if (singleEvent.title) {
      reset({
        domain: singleEvent?.senderOrganizerDomain[0]?.domain,
      });
    }
    if (!open) {
      reset({
        domain: "",
      });
    }
  }, [open]);

  const navigate = useNavigate();
  const eventsId = useMatch(
    "/events/:eventId/communications/email-domain-whitelable"
  );

  const onSubmit = async (data) => {
    setIsSubmitting(true);

    const accessToken = localStorage.getItem("accessToken");
    if (!accessToken) {
      dispatch(
        showToast({
          message: "please login!! access not found",
          category: "danger",
        })
      );
      navigate("/login");
    }

    if (!singleEvent?.senderOrganizerDomain[0].id) {
      dispatch(
        showToast({
          message: "Can't find domain id",
          category: "danger",
        })
      );
      setIsSubmitting(false);
      return;
    }

    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL_LEADS}/event/${eventsId.params.eventId}/verify-custom-domain`,
        {
          domain: data.domain,
          domain_id: singleEvent?.senderOrganizerDomain[0]?.id,
        },
        {
          headers: {
            Authorization: `bearer ${accessToken}`,
          },
        }
      )
      .then(function (response) {
        if (response.data.body.valid == true) {
          dispatch(
            showToast({
              message: "Domain verified",
              category: "success",
            })
          );
        } else {
          dispatch(
            showToast({
              message: "Can't verify!! Please wait and check again",
              category: "danger",
            })
          );
        }
        // dispatch(getSingleEvent({ eventId: eventsId.params.eventId }));
        // setOpen(false);
        // reset({
        //   domain: "",
        //   email: "",
        //   name: "",
        // });
        setSenderOrganizerDomain(response.data);
        setIsSubmitting(false);
      })
      .catch(function (error) {
        if (error.response.status === 403) {
          dispatch(
            showToast({
              message: "Unauthorized!!",
              category: "danger",
            })
          );
        }
        setIsSubmitting(false);
      });
  };

  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="z-40 fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="z-40 fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 left-[45px] md:left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                        <button
                          type="button"
                          className="rounded-md outline-none hidden md:block text-gray-300"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>X
                          {/* <XMarkIcon className="h-6 w-6" aria-hidden="true" /> */}
                        </button>
                        <button
                          className="md:hidden flex items-center"
                          onClick={() => setOpen(false)}
                        >
                          <i className="fa fa-angle-left text-[24px]"></i>
                          <span className="text-[17px] pt-0.5">Back</span>
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6 fixed z-20 bg-white w-[100%] top-0">
                        <Dialog.Title className="text-[22px] pb-[25px] font-[600] text-gray-900 pt-5">
                          Verify Domain
                        </Dialog.Title>
                      </div>
                      <div className="relative mt-[55px] flex-1 px-4 sm:px-6 mb-[50px] overflow-x-hidden">
                        <form onSubmit={handleSubmit(onSubmit)}>
                          <div className="mt-[30px]"></div>
                          <TextInput
                            register={register}
                            type="text"
                            id={"domain"}
                            label="Domain Name"
                            placeholder="Domain Name"
                            mb="3"
                            required
                          />
                          <div className="mt-[40px]"></div>
                          <div className="fixed bottom-0 w-[87%] bg-white h-[80px] flex justify-center items-center ml-[-2px]">
                            <input
                              disabled={isSubmitting}
                              value={
                                isSubmitting ? "Loading..." : "Verify Domain"
                              }
                              type="submit"
                              className="primary_submit"
                            />
                          </div>
                        </form>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
