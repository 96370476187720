import React from "react";
import { useMatch, useNavigate, useSearchParams } from "react-router-dom";
import Speakers from "../../svg/Speakers";
import Schedule from "../../svg/Schedule";
import Clipboard from "../../svg/Clipboard";
import People from "../../svg/People";

function AttendeeSideMenu({ singleEvent }) {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const navbarContent = [
    {
      name: "About",
      imageUrl: (
        <Clipboard
          color={searchParams.get("tab") === "about" ? "#A55EEA" : ""}
        />
      ),
      pathName: "about",
      comingSoon: false,
    },

    {
      name: "Agenda",
      imageUrl: (
        <Schedule
          color={searchParams.get("tab") === "agenda" ? "#A55EEA" : ""}
        />
      ),
      pathName: "agenda",
      comingSoon: false,
    },
    {
      name: "Speakers",
      imageUrl: (
        <Speakers
          color={searchParams.get("tab") === "speakers" ? "#A55EEA" : ""}
        />
      ),
      pathName: "speakers",
      comingSoon: false,
    },
    {
      name: "Leads",
      imageUrl: (
        <People color={searchParams.get("tab") === "leads" ? "#A55EEA" : ""} />
      ),
      pathName: "leads",
      comingSoon: false,
    },
  ];
  return (
    <aside
      className={`hidden md:block w-[17%] fixed h-screen border-r border-[#C5C5C766] border-opacity-40 rounded bg-white aria-label=Sidebar md:h-[100%] overflow-y-scroll overflow-x-hidden scrollbar-hide md:pb-7`}
    >
      <div className="bg-white mt-[60px]">
        <div className="">
          <p className="text-[18px] font-[600] relative left-[25px] top-[25px] break-normal w-[140px] cursor-pointer relative">
            <div className="line-clamp-3">
              {singleEvent?.title
                ? singleEvent?.title.charAt(0).toUpperCase() +
                  singleEvent?.title.slice(1, singleEvent?.title.length)
                : null}
            </div>
          </p>
        </div>

        <p className="text-[10px] font-[500] relative left-[27px] top-[25px] text-gray-400">
          By{" "}
          {singleEvent?.primaryOrganizer
            ? singleEvent?.primaryOrganizer.firstName
            : null}
        </p>

        {/* <p className="">{}</p> */}
        <ul className="pt-10">
          {navbarContent &&
            navbarContent.length > 0 &&
            navbarContent.map((navbar, index) => (
              <li key={index} className="relative">
                <a
                  onClick={() => {
                    navigate(
                      `/attendee/${singleEvent?._id}?tab=${navbar.pathName}`
                    );
                  }}
                  className={`flex cursor-pointer text-[#9a9a9a] items-center pl-5 py-3.5 text-[11px] font-[500] border-b border-[#C5C5C766] border-opacity-40 ${
                    searchParams.get("tab") === navbar.pathName
                      ? "text-primary"
                      : ""
                  }`}
                >
                  <>{navbar?.imageUrl}</>
                  <span
                    className={`pl-[7px] pt-[2px] font-[600] ${
                      searchParams.get("tab") === navbar.pathName
                        ? "text-primary"
                        : ""
                    }`}
                  >
                    {navbar.name}
                  </span>
                </a>
              </li>
            ))}
        </ul>
      </div>
    </aside>
  );
}

export default AttendeeSideMenu;
