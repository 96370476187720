import React, { useEffect, useState } from "react";
import { useMatch, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import AddCustomDomain from "./AddCustomDomain";
import VerifyEmailAndDomain from "./VerifyEmailAndDomain";
import AddSenderEmail from "./AddSenderEmail";

const EmailDomainWhitelable = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [open3, setOpen3] = useState(false);
  const event = useSelector((state) => state.eventData);
  const [isEdit, setIsEdit] = useState(false);
  //   const eventsId = useMatch("/events/:eventId/*");

  return (
    <div className="w-full md:w-[845px] md:ml-[0px] md:mt-[27px] pb-[200px] ">
      <div className="font-[600] w-[275px] mx-auto md:w-[845px] text-[21px] text-[#585858] flex items-center mt-2">
        <div className="cursor-pointer mr-4" onClick={() => navigate(-1)}>
          <svg
            width="28"
            height="28"
            viewBox="0 0 24 24"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M19 12H5"
              stroke="#585858"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path
              d="M12 19L5 12L12 5"
              stroke="#585858"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
        </div>
        White Label Email Domain
      </div>

      <div
        className="border w-[350px] min-h-[300px] mt-10 rounded-lg scrollbar-hide flex justify-center items-center flex-col"
        style={{ boxShadow: "0px -4px 14px 4px rgba(0, 0,0 , 0.1)" }}
      >
        <img src="/svgs/email-icon.svg" alt="mailchimp" className="w-[80px]" />
        <div className="mt-[30px] w-[250px]">
          <h2 class="mb-2 text-[15px] font-[500] text-gray-600 dark:text-white">
            Steps to follow:
          </h2>
          <ol class="max-w-md space-y-1 text-gray-500 list-decimal list-inside dark:text-gray-400">
            <li
              className="cursor-pointer hover:text-primary flex items-center"
              onClick={() => {
                setOpen(true);
                setIsEdit(false);
              }}
            >
              <svg
                class="w-5 h-5 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="text-[15px] font-[500] text-gray-600 dark:text-white hover:text-primary underline">
                Connect Custom Domain
              </span>
            </li>
            <li
              className="cursor-pointer hover:text-primary flex items-center"
              onClick={() => {
                setOpen2(true);
                setIsEdit(false);
              }}
            >
              <svg
                class="w-5 h-5 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="text-[15px] font-[500] text-gray-600 dark:text-white hover:text-primary underline">
                Verify Custom Domain
              </span>
            </li>
            <li
              className="cursor-pointer hover:text-primary flex items-center"
              onClick={() => {
                setOpen3(true);
                setIsEdit(false);
              }}
            >
              <svg
                class="w-5 h-5 mr-1.5 text-green-500 dark:text-green-400 flex-shrink-0"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M10 18a8 8 0 100-16 8 8 0 000 16zm3.707-9.293a1 1 0 00-1.414-1.414L9 10.586 7.707 9.293a1 1 0 00-1.414 1.414l2 2a1 1 0 001.414 0l4-4z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span class="text-[15px] font-[500] text-gray-600 dark:text-white hover:text-primary underline">
                Add Sender Email
              </span>
            </li>
          </ol>
        </div>
      </div>

      <AddCustomDomain
        open={open}
        setOpen={setOpen}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        singleEvent={event}
      />

      <VerifyEmailAndDomain
        open={open2}
        setOpen={setOpen2}
        singleEvent={event}
      />

      <AddSenderEmail open={open3} setOpen={setOpen3} singleEvent={event} />
    </div>
  );
};

export default EmailDomainWhitelable;
