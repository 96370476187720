import React, { useState } from "react";
import { useSelector } from "react-redux";
import CheckinAnalytics from "./CheckinAnalytics";
import CheckinAttendees from "./checkinAttendees/CheckinAttendees";
import { useMatch, useNavigate, useSearchParams } from "react-router-dom";
import AddCheckinLocation from "./AddCheckinLocation";
import AnalyticsIcon from "../../../svg/AnalyticsIcon";
import Onsite from "../../../svg/Onsite";
import Addlocation from "../../../svg/Addlocation";
import PrintCheckin from "../../../svg/PrintCheckin";
import QrCheckinScanner from "../../../svg/QrCheckinScanner";
import QrCheckoutScanner from "../../../svg/QrCheckoutScanner";

function CheckinCheckout() {
  const [tabHover, setTabHover] = useState("");
  const [eventTitle, setEventTitle] = useState("");
  const [checkInLocations, setCheckInLocations] = useState([]);
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [triggerCheckinCheckout, setTriggerCheckinCheckout] = useState("");
  const [triggerPrintBadge, setTriggerPrintBadge] = useState("");
  const tab = searchParams.get("tab");
  const location = searchParams.get("location");
  const eventsId = useMatch("events/:eventId/*");

  return (
    <div className="pt-0 mymd:pt-0 mymd:mb-0 mymd:mx-auto grid place-items-center min-w-[312px] max-w-[422px] w-[100vw]  mymd:max-w-[1440px] mymd:w-[90%] mymd:mb-14 mymd:mt-[-30px] mt-[-40px]">
      <div className="flex flex-col mymd:mt-0 mymd:w-[85%] w-[100vw] relative mymd:items-start items-center p-3 mymd:p-0">
        <div className="mymd:flex mymd:justify-between text-center mymd:text-left px-0 mymd:p-0 mymd:w-full">
          <div className="text-[23px] font-[550] bottom-2 mymd:line-clamp-2 mymd:w-[50%]">
            {eventTitle}
          </div>
          <div className="mymd:w-[460px] flex justify-between mymd:ml-[30px] mt-[20px] mymd:mt-0 flex-wrap w-[90vw]">
            <div
              className={`flex flex-col	items-center justify-start cursor-pointer hover:text-[#A55EEA] text-${
                tab === "Analytics" ? "[#A55EEA]" : "[#C5C5C7]"
              }`}
              onMouseEnter={() => setTabHover("Analytics")}
              onMouseLeave={() => setTabHover("")}
              onClick={() => {
                navigate(
                  `/events/${eventsId.params.eventId}/registrations?location=${location}&tab=Analytics`
                );
              }}
            >
              <span className="mymd:w-[23px] w-[17px]">
                <AnalyticsIcon
                  color={tab === "Analytics" ? "#A55EEA" : "#C5C5C7"}
                  tabHover={tabHover}
                />
              </span>
              <span className={`mymd:text-[12px] text-[11px]  font-[500] mt-1`}>
                Analytics
              </span>
            </div>
            <div
              className=" flex flex-col	items-center justify-start cursor-pointer text-[#C5C5C7] hover:text-[#A55EEA]"
              onClick={() => setTriggerCheckinCheckout("checkin")}
              onMouseEnter={() => setTabHover("checkin")}
              onMouseLeave={() => setTabHover("")}
            >
              <span className="mymd:w-[23px] w-[17px]">
                <QrCheckinScanner tabHover={tabHover} />
              </span>
              <span className="mymd:text-[12px] text-[11px]  font-[500] mt-1">
                Check-in
              </span>
            </div>
            <div
              className=" flex flex-col	items-center justify-start  cursor-pointer text-[#C5C5C7] hover:text-[#A55EEA]"
              onClick={() => setTriggerCheckinCheckout("checkout")}
              onMouseEnter={() => setTabHover("checkout")}
              onMouseLeave={() => setTabHover("")}
            >
              <span className="mymd:w-[23px] w-[17px]">
                <QrCheckoutScanner tabHover={tabHover} />
              </span>
              <span className="mymd:text-[12px] text-[11px]  font-[500] mt-1">
                {" "}
                Checkout
              </span>
            </div>
            <div
              className=" flex flex-col	items-center justify-start cursor-pointer text-[#C5C5C7] hover:text-[#A55EEA]"
              onClick={() => setTriggerPrintBadge("checkin")}
              onMouseEnter={() => setTabHover("print")}
              onMouseLeave={() => setTabHover("")}
            >
              <span className="mymd:w-[23px] w-[17px]">
                <PrintCheckin tabHover={tabHover} />
              </span>
              <span className="mymd:text-[12px] text-[11px]  font-[500] mt-1">
                Scan & Print
              </span>
            </div>{" "}
            <div
              className=" flex flex-col	items-center justify-start cursor-pointer text-[#C5C5C7] hover:text-[#A55EEA]"
              onMouseEnter={() => setTabHover("addlocation")}
              onMouseLeave={() => setTabHover("")}
              onClick={() => {
                setIsEdit(false);
                setOpen(true);
              }}
            >
              <span className="mymd:w-[23px] w-[17px]">
                <Addlocation tabHover={tabHover} />
              </span>
              <span className="mymd:text-[12px] text-[11px] font-[500] mt-1">
                Location
              </span>
            </div>
            <div
              className=" flex flex-col	items-center justify-start cursor-pointer text-[#C5C5C7] hover:text-[#A55EEA]"
              onMouseEnter={() => setTabHover("onsite")}
              onMouseLeave={() => setTabHover("")}
              onClick={() => {
                window.open(
                  `${window.location.origin}/events/${eventsId.params.eventId}/registrations/onsite`,
                  "_blank"
                );
              }}
            >
              <span className="mymd:w-[23px] w-[17px]">
                <Onsite tabHover={tabHover} />
              </span>
              <span className="mymd:text-[12px] text-[11px]  font-[500] mt-1 ">
                Onsite
              </span>
            </div>
          </div>
        </div>

        <div className="flex mymd:w-full">
          <div className="text-[17px] mymd:text-[17px] font-[500] mymd:mt-2 mt-[12px] text-center">
            <div className="">
              Registrations&nbsp;
              {location !== "eventCheckin" ? (
                <>
                  and{" "}
                  <span className="text-primary font-[500]">
                    {location
                      .split("-")
                      .map(
                        (word) => word.charAt(0).toUpperCase() + word.slice(1)
                      )
                      .join(" ")}
                  </span>
                </>
              ) : (
                <>
                  and{" "}
                  <span className="text-primary font-[500]">
                    Event Check-in
                  </span>
                </>
              )}
            </div>
          </div>
          <div className="my-2.5 border-gray-200 dark:border-gray-700 mymd:w-[50%] max-w-[400px]"></div>
        </div>

        <div id="myTabContent" className="w-[98%] sm:w-[90%] mymd:w-[100%]">
          <div
            className={`${tab !== "Analytics" ? "hidden" : ""} p-4 `}
            id="notifications"
            role="tabpanel"
            aria-labelledby="notifications-tab"
          >
            <CheckinAnalytics />
          </div>
          <div
            className={`${
              tab !== "Attendees" && tab !== null ? "hidden" : ""
            } px-0 `}
            id="marketing"
            role="tabpanel"
            aria-labelledby="marketing-tab"
          >
            <CheckinAttendees
              setEventTitle={setEventTitle}
              setCheckInLocations={setCheckInLocations}
              triggerPrintBadge={triggerPrintBadge}
              setTriggerPrintBadge={setTriggerPrintBadge}
              triggerCheckinCheckout={triggerCheckinCheckout}
              setTriggerCheckinCheckout={setTriggerCheckinCheckout}
            />
          </div>
        </div>
      </div>
      <AddCheckinLocation
        open={open}
        setOpen={setOpen}
        checkInLocations={checkInLocations}
        setCheckInLocations={setCheckInLocations}
        eventId={eventsId.params.eventId}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
      />
    </div>
  );
}

export default CheckinCheckout;
