import React, { useEffect, useState } from "react";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import { useMatch, useNavigate } from "react-router-dom";
import LinkedinRegForm from "./LinkedinRegForm";

var initialThrottleCall = false;
function throttle(fn, delay) {
  try {
    if (initialThrottleCall === false) {
      fn();
      initialThrottleCall = Date.now();
    } else if (Date.now() - initialThrottleCall > delay) {
      fn();
      initialThrottleCall = Date.now();
    }
  } catch (error) {
    console.log(error);
  }
}

const LinkedinReg = ({ setIsRegistered, setRegisteredUserEmail }) => {
  // const vercelDomain = "http://localhost:3000/";
  const vercelDomain = "https://dev.warpbay.com/";
  const [isChecked, setIsChecked] = useState(true);
  const [linkData, setlinkData] = useState({});
  const navigate = useNavigate();
  const eventsId = useMatch("/event/:eventId");
  const [triggerInfo, setTriggerInfo] = useState(false);

  const { linkedInLogin } = useLinkedIn({
    clientId: "77q4vi8kkicqo6",
    redirectUri: `${vercelDomain}linkedin`,
    scope: "r_emailaddress,r_basicprofile,w_member_social",
    // scope: "r_emailaddress,w_member_social,r_compliance",
    onSuccess: (code) => {
      if (localStorage.getItem("linkedinAccessToken")) {
        throttle(
          getLinkedinAccessToken(
            code,
            localStorage.getItem("linkedinAccessToken")
          ),
          10000
        );
      } else {
        throttle(getLinkedinAccessToken(code, null), 10000);
      }
    },
    onError: (error) => {
      console.log(error);
    },
  });
  async function getLinkedinAccessToken(
    code,
    linkedinAccessTokenClient = null
  ) {
    let url = `${process.env.REACT_APP_SERVER_URL_LEADS}/user/registerwithlinkedin`;
    try {
      const response = await fetch(url, {
        method: "POST",
        headers: { "content-type": "application/json" },
        body: JSON.stringify({
          linkedinCode: code,
          linkedinAccessTokenClient: linkedinAccessTokenClient
            ? linkedinAccessTokenClient
            : null,
        }),
      });

      if (response.status !== 200) {
        alert("something went wrong!!");
      }

      let data = await response.json();

      if (data?.newUser) {
        localStorage.setItem("linkedinAccessToken", data.linkedinAccessToken);
        localStorage.setItem(
          "linkedinURNId",
          data.linkedinURNId ? data.linkedinURNId : null
        );
        const newUser = {
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          linkedinAccessToken: data.linkedinAccessToken,
          linkedinURNId: data.linkedinURNId ? data.linkedinURNId : null,
        };
        setlinkData(newUser);
      } else if (data?.oldUser) {
        localStorage.setItem("linkedinAccessToken", data.linkedinAccessToken);
        localStorage.setItem(
          "linkedinURNId",
          data.linkedinURNId ? data.linkedinURNId : null
        );
        setlinkData(data.oldUser);
      }
    } catch (error) {
      console.log(error);
      navigate(`/event/${eventsId.params.eventId}?tab=${"register"}`);
    }
  }

  useEffect(() => {
    if (isChecked) {
      linkedInLogin();
    } else {
      navigate(`/event/${eventsId.params.eventId}?tab=${"register"}`);
    }
  }, [isChecked]);

  return (
    <>
      <div className="mymd:w-[500px] flex  items-center mb-5 mt-3">
        <input
          type="checkbox"
          id="radiobtn"
          className="mr-2 border border-[#cacaca] border-2 bg-white checked:bg-primary checked:border-[#EBEEF2] cursor-pointer focus:ring-0"
          checked={isChecked}
          onChange={() => {
            setIsChecked(false);
          }}
        />
        <label htmlFor="radiobtn" className=" cursor-pointer ">
          <span className="flex text-[14px] font-[600] items-center">
            Share this on&nbsp;
            <img src="/svgs/linkedinblue.svg" alt="linkedin" /> &nbsp;Linkedin
          </span>
        </label>
        <div className=" relative">
          <img
            src="/svgs/info.svg"
            className="h-[20px] ml-1 cursor-pointer"
            onMouseEnter={() => setTriggerInfo(true)}
            onMouseLeave={() => setTriggerInfo(false)}
          />
          {triggerInfo && (
            <p className="text-[12px] absolute z-20 bg-gray-700 text-[12px] p-2 rounded-md text-white w-[200px] left-[20px]">
              Tick here to share you are attending this event on your linkedin.
              By opting in you understand that your profile details will be
              shared with us for marketing purpose.
            </p>
          )}
        </div>
      </div>
      {linkData?.firstName ? (
        <LinkedinRegForm
          linkData={linkData}
          setIsRegistered={setIsRegistered}
          setRegisteredUserEmail={setRegisteredUserEmail}
        />
      ) : (
        <>Loading....</>
      )}
    </>
  );
};

export default LinkedinReg;
