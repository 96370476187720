import axios from "axios";
import React, { useEffect } from "react";
import { useMatch, useSearchParams } from "react-router-dom";

const TrackLinkedinClicks = () => {
  const searchParams = useMatch("lnkc/:eventId/*");

  // const regex = /\/linkedin-clicks\/(.+)\/(.+)/;
  // const match = regex.exec(window.location.pathname);

  // if (match) {
  //   const eventId = match[1]; // "6432de95ab1826992ca1e521"
  //   const trackingId = match[2]; // "frrrfrfr"
  //   console.log("eventId:", eventId);
  //   console.log("trackingId:", trackingId);
  // } else {
  //   console.log("URL does not match expected format");
  // }

  async function updateLinkedinClicks(eventId) {
    const response = await axios.patch(
      `${process.env.REACT_APP_SERVER_URL_LEADS}/event/linkedin-analytics`,
      {
        eventId: eventId,
        clicks: true,
      }
    );

    if (response.status !== 200) {
      alert("something went wrong");
    }

    window.location.href = `${window.location.origin}/event/${response.data.updatedEvent._id}`;
  }

  useEffect(() => {
    if (searchParams.params.eventId) {
      updateLinkedinClicks(searchParams.params.eventId);
    } else {
      alert("cant find event-id");
    }
  }, []);

  return <div>Loading...</div>;
};

export default TrackLinkedinClicks;
