import React, { useEffect, useState } from "react";
import { FormProvider, set, useForm } from "react-hook-form";
import { useMatch } from "react-router-dom";
import PrimaryButton from "../../../../common/buttons/PrimaryButton";
import {
  getRequest,
  patchRequest,
  postRequest,
} from "../../../../utils/API/api.ts";
import AddForm from "./AddForm";
// import styles from "../../Events.module.css";
import { FormControl } from "./FormControl";
import TextInputP from "../../../../common/inputElements/TextInputP";
import { useDispatch } from "react-redux";
import { showToast } from "../../../../redux/actions/toastActions";

function RegForm() {
  const [regForm, setRegForm] = useState([]);
  const [inputType, setInputType] = useState({});
  const eventsId = useMatch("events/:eventId/*");
  const [open, setOpen] = useState(false);
  const [isEdit, setIsEdit] = useState([]);
  const [country, setCountry] = useState(false);
  const [state, setState] = useState(false);
  const [city, setCity] = useState(false);
  const [dragOverIndex, setDragOverIndex] = useState(null);
  const [draggedIndex, setDraggedIndex] = useState(null);

  const formMethods = useForm();
  const dispatch = useDispatch();
  const {
    handleSubmit,
    register,
    formState: { isSubmitting, errors },
    setValue,
  } = formMethods;
  const {
    register: register2,
    getValues,
    formState: { errors: errors2 },
    handleSubmit: handleSubmit2,
    reset,
    setValue: setValue2,
  } = useForm({
    defaultValues: {
      name: "",
      type: "text",
      options: "",
      required: false,
    },
  });

  useEffect(() => {
    if (isEdit?.id) {
      reset({
        name: isEdit?.label,
        options: isEdit?.options,
        required: isEdit?.config?.required,
        type: isEdit?.inputType,
      });
      setInputType({
        value: isEdit?.inputType,
        label: isEdit?.inputTypeLabel,
      });
    } else {
      reset({
        name: "",
        type: "text",
        options: "",
        required: false,
      });
      setInputType({
        value: "text",
        label: "Text",
      });
    }
  }, [isEdit]);

  const deleteCustomField = async (fieldId) => {
    const updatedRegForm = regForm?.filter((field) => {
      return field._id !== fieldId;
    });
    if (updatedRegForm) {
      try {
        const response = await patchRequest(
          `event/${eventsId.params.eventId}/additionalForm`,
          {
            form: updatedRegForm,
          }
        );
        if (response.status === 200) {
          setRegForm(response.data.additionalForm.form);
          dispatch(
            showToast({
              message: "Field Deleted",
              category: "success",
            })
          );
        }
      } catch (err) {
        dispatch(
          showToast({
            message: "Some Error occured",
            category: "danger",
          })
        );
      }
    }
  };

  const onSubmit = async (data, error) => {
    const duplicateExists = regForm?.some(
      (field) => field.fieldName.toLowerCase() === data.name.toLowerCase()
    );
    if (!isEdit?.id && duplicateExists) {
      dispatch(
        showToast({
          message: "Field with the same label name exists",
          category: "danger",
        })
      );
      return;
    }

    let options = [];
    if (data.type === "select" || data.type === "multi-select") {
      const optionsArray = data.options.split(",");
      options = optionsArray.map((option) => {
        return {
          label: option,
          value: option.toLowerCase(),
        };
      });
    }

    if (isEdit?.id === undefined) {
      let regFormCopy;
      if (!regForm || Object.entries(regForm).length === 0) {
        regFormCopy = [];
      } else {
        regFormCopy = regForm;
      }

      regFormCopy?.push({
        label: data.name,
        inputType: data.type,
        fieldName: data.name
          .toLowerCase()
          .replace(/[^a-zA-Z ]/g, "")
          .replace(/\s/g, "_"),
        defaultValue: "",
        options,
        config: {
          required: data.required,
        },
      });

      if (regForm) {
        try {
          const response = await patchRequest(
            `event/${eventsId.params.eventId}/additionalForm`,
            {
              form: regFormCopy,
            }
          );
          if (response.status === 200) {
            setRegForm(response.data.additionalForm.form);
            dispatch(
              showToast({
                message: "Custom Field Added",
                category: "success",
              })
            );
          }
        } catch (err) {
          dispatch(
            showToast({
              message: "Some Error occured",
              category: "danger",
            })
          );
        }
      } else {
        try {
          const response = await postRequest(
            `event/${eventsId.params.eventId}/additionalForm`,
            regFormCopy
          );
          if (response.status === 200) {
            setRegForm(response.data.additionalForm.form);
            dispatch(
              showToast({
                message: "Custom Field Added",
                category: "success",
              })
            );
          }
        } catch (err) {
          dispatch(
            showToast({
              message: "Some Error occured",
              category: "danger",
            })
          );
        }
      }
    } else {
      const editedRegform = {
        label: data.name,
        inputType: data.type,
        fieldName: data.name
          .toLowerCase()
          .replace(/[^a-zA-Z ]/g, "")
          .replace(/\s/g, "_"),
        defaultValue: "",
        options,
        config: {
          required: data.required,
        },
        _id: isEdit?.id,
      };

      const updatedRegForm = regForm?.map((field) =>
        field._id === isEdit.id ? editedRegform : field
      );
      const duplicate = updatedRegForm?.some(
        (field) =>
          field._id !== isEdit.id &&
          field.fieldName.toLowerCase() === data.name.toLowerCase()
      );
      if (duplicate) {
        dispatch(
          showToast({
            message: "Field with the same label name exists",
            category: "danger",
          })
        );
        return;
      } else {
        try {
          const response = await patchRequest(
            `event/${eventsId.params.eventId}/additionalForm`,
            {
              form: updatedRegForm,
            }
          );
          if (response.status === 200) {
            setRegForm(response.data.additionalForm.form);
            dispatch(
              showToast({
                message: "Field Edited",
                category: "success",
              })
            );
          }
        } catch (err) {
          dispatch(
            showToast({
              message: "Some Error occured",
              category: "danger",
            })
          );
        }
      }
    }
    reset();
    setInputType("text");
    setOpen(false);
    setIsEdit([]);
  };
  function onDefaultSubmit(data, error) {
    if (regForm) {
      const found = regForm.some((item) => item.fieldName === data.name);
      if (found) {
        const updatedRegForm = regForm.map((checkbox) => {
          if (checkbox.fieldName === data.name) {
            return { ...checkbox, label: data.enable };
          } else {
          }
          return checkbox;
        });
        setRegForm(updatedRegForm);
        patchRequest(`event/${eventsId.params.eventId}/additionalForm`, {
          form: updatedRegForm,
        });
      } else {
        const updatedRegForm = [
          ...regForm,
          {
            label: data.enable,
            inputType: data.type,
            fieldName: data.name
              .toLowerCase()
              .replace(/[^a-zA-Z ]/g, "")
              .replace(/\s/g, "_"),
            defaultValue: "",
            config: {
              required: data.required,
            },
          },
        ];
        setRegForm(updatedRegForm);
        patchRequest(`event/${eventsId.params.eventId}/additionalForm`, {
          form: updatedRegForm,
        });
      }
    } else {
      let regFormCopy;
      if (!regForm || Object.entries(regForm).length === 0) {
        regFormCopy = [];
      } else {
        regFormCopy = regForm;
      }
      let options = [];
      if (data.type === "select" || data.type === "multi-select") {
        const optionsArray = data.options.split(",");
        options = optionsArray.map((option) => {
          return {
            label: option,
            value: option.toLowerCase(),
          };
        });
      }
      regFormCopy?.push({
        label: data.enable,
        inputType: data.type,
        fieldName: data.name
          .toLowerCase()
          .replace(/[^a-zA-Z ]/g, "")
          .replace(/\s/g, "_"),
        defaultValue: "",
        options,
        config: {
          required: data.required,
        },
      });
      setRegForm(regFormCopy);
      postRequest(
        `event/${eventsId.params.eventId}/additionalForm`,
        regFormCopy
      );
      reset();
      setInputType("text");
      setOpen(false);
    }
  }

  useEffect(() => {
    if (regForm) {
      const updatedRegForm = regForm?.map((checkbox) => {
        if (checkbox.fieldName === "country") {
          setCountry(JSON.parse(checkbox.label));
        } else if (checkbox.fieldName === "state") {
          setState(JSON.parse(checkbox.label));
        } else if (checkbox.fieldName === "city") {
          let one = JSON.parse(checkbox.label);
          setCity(JSON.parse(checkbox.label));
        }
      });
    }
  }, [regForm]);

  function onFormSubmit(data, error) {}

  useEffect(() => {
    const getAdditionalForm = async () => {
      const response = await getRequest(
        `/event/${eventsId.params.eventId}/additionalForm`
      );
      setRegForm(response.data[0]?.form);
    };
    if (eventsId.params.eventId) {
      getAdditionalForm();
    }
  }, [eventsId]);

  const handleDragOver = (e, index) => {
    e.preventDefault();
    setDragOverIndex(index);
  };

  const handleDragStart = (e, index) => {
    setDraggedIndex(index);
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const updatedRegForm = [...regForm];
    const draggedEvent = updatedRegForm[draggedIndex];
    updatedRegForm.splice(draggedIndex, 1);
    updatedRegForm.splice(dragOverIndex, 0, draggedEvent);
    setRegForm(updatedRegForm);
    patchRequest(`event/${eventsId.params.eventId}/additionalForm`, {
      form: updatedRegForm,
    });
    setDraggedIndex(null);
    setDragOverIndex(null);
  };
  return (
    <div className="w-full md:w-[400px] h-screen ml-0 md:ml-[0px] mt-5 md:mt-[30px] ">
      <div className="flex w-[335px] md:w-[340px] flex-row place-content-around mx-auto md:mx-0">
        <AddForm
          open={open}
          setOpen={setOpen}
          inputType={inputType}
          isSubmitting={isSubmitting}
          register={register2}
          handleSubmit={handleSubmit2}
          onSubmit={onSubmit}
          setInputType={setInputType}
          getValues={getValues}
          setValue={setValue2}
          setIsEdit={setIsEdit}
          isEdit={isEdit}
        />
      </div>
      <div className="font-[600] w-[335px] mx-auto md:w-[422px] text-[20px]  text-black md:flex items-center justify-between bg-white z-10 ">
        <p className="text-[19px]"> Default Registration Form</p>
      </div>

      <div className="mt-[35px] items-center flex flex-col w-[335px] md:w-full mx-auto md:mx-0">
        <div className="mymd:w-[100%] grid grid-cols-1 sm:grid-cols-2 pt-[12px] w-[100%] gap-[15px]">
          <TextInputP
            register={register}
            type="text"
            required
            id={"firstName"}
            label="First Name"
          />
          <TextInputP
            register={register}
            type="text"
            id={"lastName"}
            required
            label="Last Name"
            minLength={0}
          />
        </div>

        <TextInputP
          register={register}
          type="email"
          required
          id={"email"}
          label="Email Address"
          errors={errors}
          pattern={
            /^([a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)$/i
          }
          width={"400px"}
        />
        <TextInputP
          register={register}
          type="tel"
          id={"mobile"}
          required
          label="Phone Number"
          width={"400px"}
        />
        <TextInputP
          register={register}
          type="text"
          id={"organization"}
          required
          label="Company Name"
          width={"400px"}
        />
        <TextInputP
          register={register}
          type="text"
          id={"jobTitle"}
          required
          label="Designation"
          width={"400px"}
        />
        <div className="font-[600] w-[335px] mx-auto md:w-[400px] text-[20px] text-black md:flex items-center justify-between bg-white z-10">
          <p className="text-[19px]">Build Registration Form</p>
          <div className="w-[335px] md:w-[150px]">
            <PrimaryButton
              btnText={"Add fields"}
              onClick={() => {
                setIsEdit([]);
                setOpen(true);
              }}
            />
          </div>
        </div>
        {/* <p className="font-[600] w-[335px] md:w-[400px] mx-auto md:mx-auto text-[19px] text-[#585858] md:ml-0">
          Build Registration Form 
        </p> */}
        <form
          onSubmit={handleSubmit(onFormSubmit)}
          className="mt-[0px] items-center flex flex-col w-[335px] md:w-[400px] mx-auto md:mx-0"
        >
          <FormProvider {...formMethods}>
            {regForm &&
              regForm.length > 0 &&
              regForm.map((el, index) => (
                <>
                  {el.inputType !== "country" &&
                  el.inputType !== "state" &&
                  el.inputType !== "city" ? (
                    <div
                      className={`w-[335px] md:w-[400px] flex flex-col mx-auto md:mx-0 rounded-[10px] pt-7 h-[78px] hover:bg-gray-200 cursor-grab ${
                        index === dragOverIndex ? "bg-gray-200" : ""
                      } `}
                      key={el._id}
                      draggable
                      onDragStart={(e) => handleDragStart(e, index)}
                      onDragOver={(e) => handleDragOver(e, index)}
                      onDrop={handleDrop}
                    >
                      {/* <label htmlFor={el.fieldName}>{el.label}</label> */}
                      <FormControl
                        {...el}
                        width={"280px"}
                        deleteCustomField={deleteCustomField}
                        setIsEdit={setIsEdit}
                        setOpen={setOpen}
                      />

                      {/* <ErrorMessage errors={errors} name={el.fieldName} /> */}
                    </div>
                  ) : (
                    <></>
                  )}
                </>
              ))}
          </FormProvider>

          {/* <button type="submit" disabled={isSubmitting}>
                    {isSubmitting ? "Submitting" : "Submit"}
                </button> */}
        </form>
        {/* 
        <div className="w-[335px] md:w-[300px] mx-auto md:mx-0 md:ml-8">
          <PrimaryButton
            btnText={"Add custom fields"}
            onClick={() => {
              setIsEdit([]);
              setOpen(true);
            }}
          />
        </div> */}

        <form
          className="my-[27px] items-center flex flex-col w-[335px] md:w-full"
          id="form_submit"
        >
          <p className="text-[13px] flex justify-between items-center font-normal	text-[#A55EEA] w-[400px] h-[40px] mt-[-18px]">
            <span className="inline-block">Country</span>
            <label
              htmlFor="country"
              className="inline-flex relative items-center cursor-pointer"
            >
              <input
                type="checkbox"
                value=""
                id={"country"}
                checked={country}
                className="sr-only peer"
                onChange={() => {
                  if (city && !state && country) {
                    dispatch(
                      showToast({
                        message: "Please toggle off city first",
                        category: "danger",
                      })
                    );
                  } else {
                    setCountry(!country);
                    onDefaultSubmit({
                      name: "country",
                      type: "country",
                      required: true,
                      enable: !country,
                    });
                  }
                }}
              />
              <span className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary"></span>
            </label>
          </p>

          <p className="text-[13px] flex justify-between items-center font-normal	text-[#A55EEA] w-[400px] h-[40px]">
            <span className="inline-block ">State</span>
            <label
              htmlFor="state"
              className="inline-flex relative items-center cursor-pointer"
            >
              <input
                type="checkbox"
                value=""
                id={"state"}
                checked={state}
                className="sr-only peer"
                onChange={() => {
                  if (city && !country && state) {
                    dispatch(
                      showToast({
                        message: "Please toggle off city first",
                        category: "danger",
                      })
                    );
                  } else {
                    setState(!state);
                    onDefaultSubmit({
                      name: "state",
                      type: "state",
                      required: true,
                      enable: !state,
                    });
                  }
                }}
              />
              <span className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary"></span>
            </label>
          </p>
          <p className="text-[13px] flex justify-between items-center font-normal	text-[#A55EEA] w-[400px] h-[40px] ">
            <span className="inline-block ">City</span>
            <label
              htmlFor="city"
              className="inline-flex relative items-center cursor-pointer"
            >
              <input
                type="checkbox"
                value=""
                checked={city}
                id={"city"}
                className="sr-only peer"
                onChange={() => {
                  if (!country && !state && !city) {
                    dispatch(
                      showToast({
                        message: "Please toggle atleast country or state",
                        category: "danger",
                      })
                    );
                  } else {
                    setCity(!city);
                    onDefaultSubmit({
                      name: "city",
                      type: "city",
                      required: true,
                      enable: !city,
                    });
                  }
                }}
              />
              <span className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary"></span>
            </label>
          </p>
        </form>
      </div>
    </div>
  );
}

export default RegForm;
