import React, { useEffect, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import ScanPopup from "./ScanPopup";
import SuccessScan from "./SuccessScan";

const ConnectionsScan = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const camera = searchParams.get("camera");
  const [isOpen, setIsOpen] = useState(false);
  const [eventId, setEventId] = useState("");
  const [scannedUser, setScannedUser] = useState({});
  const [attendeesData, setAttendeesData] = useState([]);
  const [triggerNotes, setTriggerNotes] = useState(false);
  const [singleLeadsData, setSingleLeadsData] = useState([]);
  const closeModal = () => {
    setIsOpen(false);
    // navigate(-1);
    navigate(`/attendee/${eventId}?tab=leads&view=leads`);
  };

  return (
    <div className="">
      {isOpen ? (
        <div className={``}>
          <SuccessScan
            isOpen={isOpen}
            closeModal={closeModal}
            eventId={eventId}
            scannedUser={scannedUser}
            setIsOpen={setIsOpen}
            setEventId={setEventId}
            setScannedUser={setScannedUser}
          />
        </div>
      ) : (
        <>
          <div
            className="h-full top-0 bg-[rgba(0,0,0,0.4)] z-30 fixed mymd:w-[100%] left-0 w-[100vw]"
            onClick={() => navigate(-1)}
          ></div>

          <div
            className={`w-[100vw] z-40 fixed bottom-0 top-0 left-0 right-0 bg-white mymd:w-[95%] mymd:h-[95%] mymd:mx-auto mymd:top-1/2 mymd:-translate-y-1/2 mymd:rounded-[15px] mymd:border-[3px] border-gray-300`}
          >
            <ScanPopup
              camera={camera}
              isOpen={isOpen}
              setIsOpen={setIsOpen}
              closeModal={closeModal}
              setEventId={setEventId}
              setScannedUser={setScannedUser}
            />
          </div>
        </>
      )}
      {/* <ScannedLeadsNotes
          setTriggerNotes={setTriggerNotes}
          triggerNotes={triggerNotes}
          eventsId={eventId}
          singleLeadsData={singleLeadsData}
          setAttendeesData={setAttendeesData}
          setSingleLeadsData={setSingleLeadsData}
        /> */}
    </div>
  );
};

export default ConnectionsScan;
