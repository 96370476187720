import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import useDebounce from "../../../../helper/hooks/useDebounce";
import { UPDATE_EVENT } from "../../../../redux/constants/eventConstants";
import TransactionsTable from "./TransactionsTable";
import {
  useLocation,
  useMatch,
  useNavigate,
  useSearchParams,
} from "react-router-dom";
import { getRequest } from "../../../../utils/API/api.ts";
import RegistrationScan from "../../../../pages/events/RegistrationScan";
import PrintAndCheckinBadge from "./PrintAndCheckinBadge";

function CheckinAttendees({
  setEventTitle,
  setCheckInLocations,
  triggerCheckinCheckout,
  setTriggerCheckinCheckout,
  triggerPrintBadge,
  setTriggerPrintBadge,
}) {
  const eventsId = useMatch("events/:eventId/*");
  const dispatch = useDispatch();
  const [search, setSearch] = useState("");
  const [previous, setPrevious] = useState("");
  const [showSearchResults, setShowSearchResults] = useState(false);
  const [total, setTotal] = useState(10);
  const [registrations, setRegistrations] = useState([]);
  const [filteredRegistrations, setFilteredRegistrations] = useState([]);
  const [next, setNext] = useState(`/attendee/${eventsId.params.eventId}`);
  const debouncedSearch = useDebounce(search, 700);
  const [searchParams] = useSearchParams();
  const [camera, setCamera] = useState("environment");
  const view = searchParams.get("view");
  const location = searchParams.get("location");
  const [totalPages, setTotalPages] = useState(1);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  useEffect(() => {
    async function fetchData() {
      setNext(null);
      setShowSearchResults(true);
      const data = await getRequest(
        `/attendee/${eventsId.params.eventId}/search/attendee?name=${debouncedSearch}`
      );
      setRegistrations([...data.data.registrations.attendees]);
    }
    if (eventsId.params.eventId) {
      if (debouncedSearch.length > 0) {
        fetchData();
      } else {
        getAllEventAttendees(`/attendee/${eventsId.params.eventId}`);
      }
    }
  }, [debouncedSearch]);

  useEffect(() => {
    if (eventsId.params.eventId && (view === null || view === "all")) {
      getAllEventAttendees(
        `/attendee/${eventsId.params.eventId}?page=${1}&limit=10`
      );
    }
  }, [eventsId, view]);

  useEffect(() => {
    if (
      eventsId.params.eventId &&
      (view === null || view === "all") &&
      !triggerPrintBadge
    ) {
      getAllEventAttendees(
        `/attendee/${eventsId.params.eventId}?page=${currentPage}&limit=10`
      );
    }
  }, [triggerPrintBadge]);

  useEffect(() => {
    const tabs = ["Attended", "NotAttended", "CheckedIn", "CheckedOut"];
    let query = "";
    if (tabs.includes(view)) {
      switch (view) {
        case "Attended":
          query = "hasAttended=true";
          break;
        case "NotAttended":
          query = "hasAttended=false";
          break;
        case "CheckedIn":
          query = "hasAttended=true&isCheckedIn=true";
          break;
        case "CheckedOut":
          query = "hasAttended=true&isCheckedIn=false";
          break;
        default:
          query = "";
          break;
      }
      getFilteredAttendees(query);
    }
  }, [view]);

  //Get all attendees that are registered for the event
  const getAllEventAttendees = async (route) => {
    setShowSearchResults(false);
    const response = await getRequest(route);

    if (
      registrations?.length == 0 ||
      ((search === "" || search === " ") && response.data.attendees.length > 0)
    ) {
      setRegistrations(response.data.attendees);
    } else {
      setRegistrations([...registrations, ...response.data.attendees]);
    }
    setNext(response?.data?.next);
    setPrevious(response?.data?.previous);
    setTotalPages(response.data?.totalPages);
    setCurrentPage(response.data?.currentPage);
    setEventTitle(response?.data?.eventTitle);
    setCheckInLocations(response?.data?.venueCheckinLocations);
    setTotal(response?.data?.totalAttendees[0]?.total);
    dispatch({
      type: UPDATE_EVENT,
      payload: {
        attendees: [...registrations, response.data.attendees],
      },
    });
  };

  //Get Filtered Attendees based on query provided
  const getFilteredAttendees = async (query, route) => {
    setShowSearchResults(false);
    if (route === undefined) {
      const response = await getRequest(
        `attendee/${eventsId.params.eventId}/attended/?${query}&location=${location}`
      );
      setNext(response?.data?.next);
      setTotal(response?.data?.totalAttendees);
      setFilteredRegistrations(response?.data?.registrations);
      setPrevious(response?.data?.previous);
      setTotalPages(response.data?.totalPages);
      setCurrentPage(response.data?.currentPage);
    } else {
      const response = await getRequest(route);
      if (response.status === 200) {
        setNext(response?.data?.next);
        setTotal(response?.data?.totalAttendees);
        setFilteredRegistrations(response?.data?.registrations);
        setPrevious(response?.data?.previous);
        setTotalPages(response.data?.totalPages);
        setCurrentPage(response.data?.currentPage);
      }
    }
  };

  useEffect(() => {
    if (triggerCheckinCheckout) {
      function preventBackgroundScroll(event) {
        event.preventDefault();
        event.stopPropagation();
      }

      document.body.style.overflow = "hidden";
      document.addEventListener("scroll", preventBackgroundScroll, {
        passive: false,
      });

      return () => {
        document.body.style.overflow = "visible";
        document.removeEventListener("scroll", preventBackgroundScroll);
      };
    }
  }, [triggerCheckinCheckout]);

  return (
    <div className="">
      {triggerCheckinCheckout || triggerPrintBadge ? (
        <div className="">
          <div
            className="h-full top-0 bg-[rgba(0,0,0,0.4)] z-30 fixed mymd:w-[100%] left-0 w-[100vw]"
            onClick={() => {
              setTriggerCheckinCheckout(false);
              setTriggerPrintBadge(false);
            }}
          ></div>
          <div
            className={`mymd:w-[55%] mymd:h-[90%] w-full z-40 fixed bottom-0 bg-white rounded-lg p-5 left-0 right-0 mx-auto md:top-1/2 md:-translate-y-1/2 h-[90%] overflow-scroll`}
          >
            {triggerCheckinCheckout && (
              <RegistrationScan
                registrations={
                  view === "all" || view === null
                    ? registrations
                    : filteredRegistrations
                }
                setCamera={setCamera}
                camera={camera}
                setRegistrations={setRegistrations}
                triggerScan={triggerCheckinCheckout}
                setTriggerScan={setTriggerCheckinCheckout}
              />
            )}

            {triggerPrintBadge && (
              <PrintAndCheckinBadge
                registrations={
                  view === "all" || view === null
                    ? registrations
                    : filteredRegistrations
                }
                setCamera={setCamera}
                camera={camera}
                setRegistrations={setRegistrations}
                triggerScan={triggerPrintBadge}
                setTriggerScan={setTriggerPrintBadge}
              />
            )}
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className="flex items-center my-4 ">
        <form
          className="flex items-center  w-[100%]"
          onSubmit={(event) => event.preventDefault()}
        >
          <label htmlFor="simple-search" className="sr-only">
            Search
          </label>
          <div className="relative w-full">
            <div className="flex absolute inset-y-0 left-0 items-center pl-3 pointer-events-none">
              <svg
                aria-hidden="true"
                className="w-5 h-5 text-gray-500 dark:text-gray-400"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fillRule="evenodd"
                  d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z"
                  clipRule="evenodd"
                ></path>
              </svg>
            </div>
            <input
              type="text"
              id="simple-search"
              className="bg-gray-50 border border-gray-300 text-gray-900 text-[13px] rounded-lg focus:ring-primary focus:border-primary block w-full pl-10 p-2.5  dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-primary dark:focus:border-primary"
              placeholder="Search"
              required={true}
              onChange={(e) => {
                setSearch(e.target.value);
              }}
            ></input>
          </div>
        </form>
      </div>

      {/* Filters */}
      <div className="mb-0 ">
        <ul className="flex mymd:flex-wrap -m-1 overflow-scroll mymd:w-full">
          <li className="m-1">
            <button
              onClick={() => {
                navigate(
                  `/events/${eventsId.params.eventId}/registrations?location=${location}&tab=Attendees&view=all`
                );
              }}
              // onClick={() => setTab("all")}
              className={`inline-flex items-center justify-center mymd:text-[13px] font-medium leading-5 rounded-full px-3 py-1 border  w-[90px] h-[40px] text-[14px] ${
                view === "all" || view === null
                  ? "border-transparent  bg-primary text-white "
                  : "border-slate-200 hover:border-slate-300 bg-white text-slate-500"
              } duration-150 ease-in-out`}
            >
              View All
            </button>
          </li>
          <li className="m-1">
            <button
              // onClick={() => setTab("Attended")}
              onClick={() => {
                navigate(
                  `/events/${eventsId.params.eventId}/registrations?location=${location}&tab=Attendees&view=Attended`
                );
              }}
              className={`inline-flex items-center justify-center mymd:text-[13px] font-medium leading-5 rounded-full px-3 py-1 border  w-[90px] h-[40px]  text-[14px] ${
                view === "Attended"
                  ? "border-transparent  bg-primary text-white "
                  : "border-slate-200 hover:border-slate-300 bg-white text-slate-500"
              } duration-150 ease-in-out`}
            >
              Attended
            </button>
          </li>
          <li className="m-1">
            <button
              onClick={() => {
                navigate(
                  `/events/${eventsId.params.eventId}/registrations?location=${location}&tab=Attendees&view=NotAttended`
                );
              }}
              // onClick={() => setTab("NotAttended")}
              className={`inline-flex items-center justify-center mymd:text-[13px] font-medium leading-5 rounded-full px-3 py-1 border  w-[120px] h-[40px] text-[14px] ${
                view === "NotAttended"
                  ? "border-transparent  bg-primary text-white "
                  : "border-slate-200 hover:border-slate-300 bg-white text-slate-500"
              } duration-150 ease-in-out`}
            >
              Not Attended
            </button>
          </li>
          <li className="m-1">
            <button
              onClick={() => {
                navigate(
                  `/events/${eventsId.params.eventId}/registrations?location=${location}&tab=Attendees&view=CheckedIn`
                );
              }}
              // onClick={() => setTab("CheckedIn")}
              className={`inline-flex items-center justify-center mymd:text-[13px] font-medium leading-5 rounded-full px-3 py-1 border w-[90px] h-[40px] text-[14px] ${
                view === "CheckedIn"
                  ? "border-transparent  bg-primary text-white "
                  : "border-slate-200 hover:border-slate-300 bg-white text-slate-500"
              } duration-150 ease-in-out`}
            >
              Onsite
            </button>
          </li>
          <li className="m-1">
            <button
              onClick={() => {
                navigate(
                  `/events/${eventsId.params.eventId}/registrations?location=${location}&tab=Attendees&view=CheckedOut`
                );
              }}
              // onClick={() => setTab("CheckedOut")}
              className={`inline-flex items-center justify-center mymd:text-[13px] font-medium leading-5 rounded-full px-3 py-1 border w-[120px] h-[40px] text-[14px] ${
                view === "CheckedOut"
                  ? "border-transparent  bg-primary text-white "
                  : "border-slate-200 hover:border-slate-300 bg-white text-slate-500"
              } duration-150 ease-in-out`}
            >
              Checked Out
            </button>
          </li>
        </ul>
      </div>

      {/* Attendee Table */}
      <TransactionsTable
        registrations={
          view === "all" || view === null
            ? registrations
            : filteredRegistrations
        }
        setRegistrations={setRegistrations}
        showSearchResults={showSearchResults}
      />

      {/* Pagination */}
      <div className="flex justify-start items-center mymd:border-x mymd:border-b rounded-b-sm pl-[18px]">
        <div className="text-[13px]">
          <span className="font-semibold text-[14px]">
            {view === "all" || view === null
              ? registrations?.length
              : filteredRegistrations?.length}
          </span>{" "}
          of <span className="font-semibold text-[14px]">{total}</span> results
        </div>

        {view === "all" || view === null ? (
          <div className="flex items-center ml-3 p-2 rounded-lg">
            <button
              className={`text-center cursor-pointer text-${
                previous === null ? "gray-300" : "primary"
              } text-[23px]`}
              disabled={previous === null ? true : false}
              onClick={async () => {
                await getAllEventAttendees(previous);
              }}
            >
              {"<"}
            </button>

            <div className="mx-3 cursor-pointer">
              <select
                id="title"
                name="title"
                required
                value={currentPage}
                onChange={(e) => {
                  getAllEventAttendees(
                    `/attendee/${eventsId.params.eventId}/?page=${e.target.value}&limit=10`
                  );
                }}
                className={`
              w-[75px]
              peer bg-white border-2 border-primary rounded-[5px] text-gray-900 text-[12px] placeholder-transparent focus:ring-transparent py-0 bg-[right] 1.5rem cursor-pointer
              bg-center `}
              >
                {Array.from({ length: totalPages }).map((_, index) => (
                  <option
                    key={index + 1}
                    value={index + 1}
                    className="hover:bg-gray-300 border-primary rounded-[5px] overflow-scroll max-h-[50px]"
                  >
                    {index + 1}
                  </option>
                ))}
              </select>
            </div>

            <button
              disabled={next === null ? true : false}
              className={`text-center cursor-pointer text-${
                next === null ? "gray-300" : "primary"
              } text-[23px]`}
              onClick={async () => {
                await getAllEventAttendees(next);
              }}
            >
              {">"}
            </button>
          </div>
        ) : (
          <></>
        )}

        {/* filteredData */}
        {view !== "all" && view !== null ? (
          <div className="flex items-center border ml-3 p-2 rounded-lg">
            <button
              className={`text-center cursor-pointer text-${
                previous === null ? "gray-300" : "primary"
              } text-[23px]`}
              disabled={previous === null ? true : false}
              onClick={async () => {
                await getFilteredAttendees("query", previous);
              }}
            >
              {"<"}
            </button>

            <div className="mx-3 cursor-pointer">
              <select
                id="title"
                name="title"
                required
                value={currentPage}
                onChange={(e) => {
                  const url = next || previous;
                  const regex = /\?(.*?)&location/;
                  const match = url.match(regex);
                  const queryString = match ? match[1] : "";

                  getFilteredAttendees(
                    "query",
                    `/attendee/${eventsId.params.eventId}/attended/?${queryString}&location=${location}&page=${e.target.value}&limit=10`
                  );
                }}
                className={`
               w-[60px]
               peer bg-white border border-primary rounded-[5px] text-gray-900 text-[12px] placeholder-transparent focus:ring-transparent py-0 bg-[right] 1.5rem cursor-pointer
               bg-center`}
              >
                {Array.from({ length: totalPages }).map((_, index) => (
                  <option
                    key={index + 1}
                    value={index + 1}
                    className="hover:bg-gray-300 border-primary rounded-[5px] overflow-scroll max-h-[50px]"
                  >
                    {index + 1}
                  </option>
                ))}
              </select>
            </div>

            <div
              disabled={next === null ? true : false}
              className={`text-center cursor-pointer text-${
                next === null ? "gray-300" : "primary"
              } text-[23px]`}
              onClick={async () => {
                await getFilteredAttendees("query", next);
              }}
            >
              {">"}
            </div>
          </div>
        ) : (
          <></>
        )}
      </div>

      <br />
      <br />
      <br />
    </div>
  );
}

export default CheckinAttendees;
