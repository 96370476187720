//@ts-nocheck
import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment, useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import TextInput from "../../../common/inputElements/TextInput";
import Select from "../../../common/inputElements/Select";
import TextArea from "../../../common/inputElements/TextArea";
import {
  patchAuthenticatedRequest,
  patchRequest,
} from "../../../utils/API/api.ts";
import moment from "moment";
import throttle from "../../../utils/throttle";
import DatePicker from "react-date-picker/dist/entry.nostyle";
// import DatePicker from "react-date-picker";
import TimeInput from "../../../common/timeInput/TimeInput";
import { UPDATE_EVENT } from "../../../redux/constants/eventConstants";
import { useDispatch } from "react-redux";
import { showToast } from "../../../redux/actions/toastActions";

export default function AddSession({
  open,
  setOpen,
  setSchedule,
  schedule,
  event,
  singleSchedule,
  setSingleSchedule,
  isEdit,
  setIsEdit,
}) {
  const [value1, setValue1] = useState([]);
  const [options, setOptions] = useState([]);
  const [value2, setValue2] = useState(options[0]);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [dateValue, setDateValue] = useState("");
  const [scheduleTime, setscheduleTime] = useState("");
  const [characterCount, setCharacterCount] = useState("");

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
    watch,
  } = useForm({ mode: "onChange" });

  const dispatch = useDispatch();
  useEffect(() => {
    if (event?.startDate) {
      setDateValue(new Date(event.startDate));
    }
  }, [event?.startDate]);

  useEffect(() => {
    if (isEdit) {
      const timeStr = new Date(singleSchedule?.startTime)
        .toISOString()
        .slice(11, 19);
      reset({
        sessionName: singleSchedule.sessionName,
        sessionDescription: singleSchedule.sessionDescription,
        venueName: singleSchedule.venueName,
        sessionTime: timeStr,
        onlineSessionUrl: singleSchedule?.onlineSessionUrl,
      });
      let filteredArrayA;
      let speakerValue;
      if (event?.speakers && event?.speakers.length > 0) {
        filteredArrayA = event?.speakers.filter((itemA) =>
          singleSchedule.speakers.some((itemB) => itemB === itemA._id)
        );
        speakerValue = filteredArrayA.map((speaker) => {
          return {
            label: `${speaker.firstName} ${speaker.lastName}`,
            value: speaker._id,
          };
        });
        setValue1(speakerValue);
        setValue("speakers", speakerValue);
        if (event?.speakers && event?.speakers.length > 0) {
          const speakerOptions = event?.speakers.map((speaker) => {
            return {
              label: `${speaker.firstName} ${speaker.lastName}`,
              value: speaker._id,
            };
          });
          setOptions(speakerOptions);
        }
        const formattedTime = moment
          .utc(singleSchedule?.startTime)
          .format("hh : mm A");
        setscheduleTime(formattedTime);
        setCharacterCount(singleSchedule.sessionDescription.slice(0, 600));
        if (singleSchedule.onlineSessionUrl) {
          setValue("onlineSession", true);
        }
      }
    } else {
      const currentTime = moment();
      const roundedMinutes =
        (Math.ceil((currentTime.minute() + 1) / 15) * 15) % 60;
      const roundedTime = moment({
        hour: currentTime.hour() + (roundedMinutes === 0),
        minute: roundedMinutes,
      });
      const formattedVal = moment(
        roundedTime.format("hh : mm A"),
        "hh : mm A"
      ).format("HH:mm:ss");
      reset({
        sessionName: "",
        sessionDescription: "",
        speakers: "",
        sessionTags: "",
        venueName: "",
        sessionTime: formattedVal,
        selectSpeaker: [],
      });
      if (event?.speakers && event?.speakers.length > 0) {
        const speakerOptions = event?.speakers.map((speaker) => {
          return {
            label: `${speaker.firstName} ${speaker.lastName}`,
            value: speaker._id,
          };
        });
        setOptions(speakerOptions);
      }

      setValue1([]);
      setValue();
      setscheduleTime(roundedTime.format("hh : mm A"));
      setCharacterCount("");
    }
  }, [event?.speakers, isEdit, open]); //Not good can trigger infinite loop

  const onSubmit = async (data) => {
    setIsSubmitting(true);
    if (!data || !data.sessionTime) {
      dispatch(
        showToast({
          message: "Please select time for the session",
          category: "danger",
        })
      );
      setIsSubmitting(false);
      return;
    }

    if (
      new Date(
        `${moment(dateValue).format("ll")}, ${data.sessionTime}`
      ).toISOString() < event.startDate
    ) {
      dispatch(
        showToast({
          message: "Please select valid time!",
          category: "danger",
        })
      );
      setIsSubmitting(false);
      return;
    }

    let scheduleCopy = event.schedule || [];
    if (scheduleCopy && scheduleCopy.length > 0) {
      if (singleSchedule.sessionName !== "" && isEdit) {
        let newData;
        if (value1.length !== 0) {
          // removing speakers array if its not present
          newData = {
            ...data,
            _id: singleSchedule._id,
            speakers: data.speakers.map((speaker) => speaker.value),
            startTime: new Date(
              `${moment(dateValue).format("ll")}, ${data.sessionTime}`
            ).toISOString(),
          };
        } else {
          newData = {
            ...data,
            _id: singleSchedule._id,
            startTime: new Date(
              `${moment(dateValue).format("ll")}, ${data.sessionTime}`
            ).toISOString(),
          };
        }
        let newscheduleCopy = scheduleCopy[
          scheduleCopy.length - 1
        ].sessions.filter((item) => {
          return newData._id !== item._id;
        });
        let updatedscheduleCopy = [...newscheduleCopy, newData];
        scheduleCopy = {
          ...scheduleCopy[0],
          sessions: updatedscheduleCopy,
        };
      } else {
        scheduleCopy[scheduleCopy.length - 1].sessions.push({
          sessionName: data.sessionName,
          sessionDescription: data.sessionDescription,
          speakers:
            data.speakers.length > 0
              ? data.speakers.map((speaker) => speaker.value)
              : [],
          sessionTags: data.sessionTags.split(","),
          onlineSessionUrl: data.onlineSessionUrl,
          venueName: data.venueName,
          startTime: new Date(
            `${moment(dateValue).format("ll") || moment().format("LL")}, ${
              data.sessionTime
            }`
          ).toISOString(),
        });
      }
    } else {
      scheduleCopy[0] = {
        day: "1",
        sessions: [
          {
            sessionName: data.sessionName,
            sessionDescription: data.sessionDescription,
            speakers:
              data.speakers.length > 0
                ? data.speakers.map((speaker) => speaker.value)
                : [],
            sessionTags: data.sessionTags.split(","),
            venueName: data.venueName,
            startTime: new Date(
              `${moment(dateValue).format("ll")}, ${data.sessionTime}`
            ).toISOString(),
          },
        ],
      };
    }

    try {
      const updatedEvent = await patchAuthenticatedRequest(
        `/event/${event._id}`,
        {
          schedule: scheduleCopy,
        }
      );

      const updatedSchedule = updatedEvent.data.savedEventConfig.schedule;
      let allSessions = [];
      updatedSchedule.forEach((day) => {
        allSessions = [...allSessions, ...day.sessions];
      });
      setSchedule(allSessions);

      dispatch({
        type: UPDATE_EVENT,
        payload: {
          schedule: updatedEvent.data.savedEventConfig.schedule,
        },
      });

      if (isEdit) {
        dispatch(
          showToast({
            message: "Changes Saved Successfully",
            category: "success",
          })
        );
      } else if (!isEdit) {
        dispatch(
          showToast({
            message: "Session Added Successfully",
            category: "success",
          })
        );
      }

      setOpen(false);
      setIsEdit(false);
      setSingleSchedule({
        sessionName: "",
        sessionDescription: "",
        speakers: "",
        sessionTags: "",
        venueName: "",
        startTime: "",
        selectSpeaker: [],
      });
      reset();
      setValue1([]);
      setIsSubmitting(false);
    } catch (err) {
      console.log(err, "error from schedule");
      setOpen(false);
      setIsSubmitting(false);
    }
  };
  return (
    <>
      <Transition.Root show={open} as={Fragment}>
        <Dialog as="div" className="relative z-40" onClose={setOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="z-40 fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="z-40 fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10">
                <Transition.Child
                  as={Fragment}
                  enter="transform transition ease-in-out duration-500 sm:duration-700"
                  enterFrom="translate-x-full"
                  enterTo="translate-x-0"
                  leave="transform transition ease-in-out duration-500 sm:duration-700"
                  leaveFrom="translate-x-0"
                  leaveTo="translate-x-full"
                >
                  <Dialog.Panel className="pointer-events-auto relative w-screen max-w-md">
                    <Transition.Child
                      as={Fragment}
                      enter="ease-in-out duration-500"
                      enterFrom="opacity-0"
                      enterTo="opacity-100"
                      leave="ease-in-out duration-500"
                      leaveFrom="opacity-100"
                      leaveTo="opacity-0"
                    >
                      <div className="absolute top-0 left-[45px] md:left-0 -ml-8 flex pt-4 pr-2 sm:-ml-10 sm:pr-4">
                        <button
                          type="button"
                          className="rounded-md outline-none hidden md:block text-gray-300"
                          onClick={() => setOpen(false)}
                        >
                          <span className="sr-only">Close panel</span>X
                          {/* <XMarkIcon className="h-6 w-6" aria-hidden="true" /> */}
                        </button>
                        <button
                          className="md:hidden flex items-center"
                          onClick={() => setOpen(false)}
                        >
                          <i className="fa fa-angle-left text-[24px]"></i>
                          <span className="text-[17px] pt-0.5">Back</span>
                        </button>
                      </div>
                    </Transition.Child>
                    <div className="flex h-full flex-col overflow-y-scroll bg-white py-6 shadow-xl">
                      <div className="px-4 sm:px-6 fixed z-20 bg-white w-[100%] top-0">
                        <Dialog.Title className="text-[22px] pt-5 pb-4 font-[600] text-gray-900">
                          {!isEdit ? "Add a new session" : "Edit Session"}
                        </Dialog.Title>
                      </div>
                      <div className="relative mt-[75px] flex-1 px-4 sm:px-6 mb-[50px]">
                        <form
                          onSubmit={handleSubmit((data) =>
                            throttle(() => onSubmit(data), 5000)
                          )}
                        >
                          <TextInput
                            register={register}
                            type="text"
                            id={"sessionName"}
                            required
                            label="Session name"
                            placeholder="Session name"
                          />

                          <TextArea
                            register={register}
                            type="text"
                            id={"sessionDescription"}
                            required
                            label={
                              characterCount
                                ? `Session description (${
                                    characterCount.split("").length
                                  }/600 characters)`
                                : "Session description"
                            }
                            placeholder="Session description"
                            maxLength={600}
                            onChange={(e) => setCharacterCount(e.target.value)}
                            mt={"35px"}
                          />

                          <p className="text-[14px] font-[500] text-[#A55EEA] mb-[35px] mt-[-7px] pl-[7px]">
                            <span className="inline-block w-[183px]">
                              Is this session online ?
                            </span>
                            <label
                              htmlFor="default-toggle"
                              className="inline-flex top-[8px] left-[7px] relative items-center cursor-pointer"
                            >
                              <input
                                type="checkbox"
                                value=""
                                id="default-toggle"
                                className="sr-only peer"
                                {...register("onlineSession")}
                              />
                              <span className="w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-primary"></span>
                            </label>
                          </p>

                          {watch().onlineSession ? (
                            <TextInput
                              register={register}
                              type="text"
                              id={"onlineSessionUrl"}
                              label="Online Session URL"
                              placeholder="Online Session URL"
                            />
                          ) : (
                            <TextInput
                              register={register}
                              type="text"
                              id={"venueName"}
                              label="Venue Name"
                              placeholder="Venue Name"
                            />
                          )}

                          <TextInput
                            register={register}
                            type="text"
                            id={"sessionTags"}
                            label="Session tags"
                            placeholder="Session tags"
                          />

                          <div className="grid grid-cols-2 gap-[50px] mt-[40px] mb-[30px]">
                            <div className="relative">
                              <label
                                htmlFor="startDate"
                                className="text-[12px] text-[#9c9c9c] absolute -top-[18px] left-[5px]"
                              >
                                Session Date&nbsp;
                                <span className="text-red-600">*</span>
                              </label>
                              <DatePicker
                                onChange={(value) => setDateValue(value)}
                                value={dateValue}
                                minDate={new Date(event.startDate)}
                                // minDate={new Date(
                                //   event.startDate
                                // ).toLocaleDateString("en-IN", {
                                //   timeZone: "Asia/Kolkata",
                                // })}
                                maxDate={new Date(event.endDate)}
                                // maxDate={new Date(
                                //   event.endDate
                                // ).toLocaleDateString("en-IN", {
                                //   timeZone: "Asia/Kolkata",
                                // })}
                                format="dMMMy"
                              />
                            </div>

                            <TimeInput
                              register={register}
                              required={true}
                              value={scheduleTime}
                              setValue={setValue}
                              id={"sessionTime"}
                              label={"Session Time"}
                              isHalfWidth={false}
                            />
                          </div>
                          <div className="my-[35px] relative">
                            <label
                              htmlFor="startDate"
                              className="text-[12px] text-[#9c9c9c] absolute -top-[18px] left-[5px]"
                            >
                              Speaker
                            </label>
                            <Select
                              multiple
                              register={register}
                              id={"selectSpeaker"}
                              options={options}
                              value={value1}
                              onChange={(event) => {
                                setValue("speakers", event);
                                setValue1(event);
                              }}
                            />
                          </div>
                          <div className="fixed bottom-0 w-[87%] bg-white  h-[80px] flex justify-center items-center">
                            {isEdit ? (
                              <input
                                disabled={isSubmitting}
                                value={
                                  isSubmitting ? "Loading..." : "Save Changes"
                                }
                                type="submit"
                                className="primary_submit"
                              />
                            ) : (
                              <input
                                disabled={isSubmitting}
                                value={
                                  isSubmitting ? "Loading..." : "Add Session"
                                }
                                type="submit"
                                className="primary_submit"
                              />
                            )}
                          </div>
                        </form>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </>
  );
}
