import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useMatch } from "react-router-dom";
import { getSingleEvent } from "../../../../redux/actions/eventActions";
import { showToast } from "../../../../redux/actions/toastActions";
import { patchRequest } from "../../../../utils/API/api.ts";

const BadgeTemplate = () => {
  const dispatch = useDispatch();
  const eventsId = useMatch("/events/:eventId/*");
  const event = useSelector((state) => state.eventData);
  const [eventDetails, setEventDetails] = useState([]);

  useEffect(() => {
    dispatch(getSingleEvent({ eventId: eventsId.params.eventId }));
    return () => {};
  }, []);

  useEffect(() => {
    if (event) {
      setEventDetails(event);
    }
  }, [event]);

  const onSubmit = async (data) => {
    try {
      const updatedEvent = await patchRequest(
        `/event/${eventsId.params.eventId}`,
        {
          isCustomBadge: !data,
        }
      );
      if (updatedEvent.status === 200) {
        setEventDetails(updatedEvent.data.savedEventConfig);
        dispatch(
          showToast({
            message: "Settings Updated",
            category: "success",
          })
        );
      }
    } catch (err) {
      dispatch(
        showToast({
          message: "Some Error occured",
          category: "danger",
        })
      );
    }
  };
  return (
    <div className="w-full md:w-[845px] md:ml-[0px] md:mt-[27px] pb-[200px] ">
      <div className="font-[600] w-[375px] mx-auto md:w-[845px] text-[24px] text-[#585858] flex justify-between items-center mt-2">
        <span className="">
          Custom Badge
          {eventDetails?.isCustomBadge ? (
            <span className="text-[15px] text-[#2ECC71]">(Active)</span>
          ) : (
            <span className="text-[15px] text-[#E74C3C] ">(Inactive)</span>
          )}
        </span>
        <p className="text-[13px] font-normal	text-[#A55EEA] flex justify-center items-center">
          <label
            htmlFor="badge"
            className="inline-flex relative items-center cursor-pointer"
          >
            <input
              type="checkbox"
              value=""
              id={"badge"}
              onChange={() => onSubmit(eventDetails?.isCustomBadge)}
              checked={eventDetails?.isCustomBadge}
              className="sr-only peer"
            />
            <span className="w-[57px] h-[30px] bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 dark:peer-focus:ring-blue-800 rounded-full peer dark:bg-gray-700 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[4px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-[25px] after:w-[25px] after:transition-all dark:border-gray-600 peer-checked:bg-primary"></span>
          </label>
        </p>
      </div>
    </div>
  );
};

export default BadgeTemplate;
