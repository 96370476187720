import React, { useRef } from "react";
import DefaultProfilePicture from "../../../common/defaultProfilePicture/DefaultProfilePicture";

const RegistrationTabs = ({
  registrations,
  getAllEventAttendees,
  searchValue,
  total,
  eventsId,
  tab,
  next,
  getAttendedAttendees,
  previous,
  totalPages,
  currentPage,
}) => {
  const targetRef = useRef([]);

  const showBadge = (index) => {
    targetRef.current[index].style.display = "block";
  };

  const hideBadge = (index) => {
    targetRef.current[index].style.display = "none";
  };

  const getLatestCheckinTime = (eventData) => {
    if (eventData) {
      const lastCheckIn = eventData.checkInData
        .filter((checkIn) => checkIn.location === "eventCheckin")
        .sort((a, b) => new Date(b.entry) - new Date(a.entry))[0];
      return lastCheckIn ? lastCheckIn : null;
    }
  };

  function formatCheckInTime(checkInData) {
    if (checkInData) {
      const utcDate = new Date(checkInData.entry);
      const options = {
        day: "numeric",
        month: "short",
        year: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
        timeZone: "Asia/Kolkata",
      };

      const formatter = new Intl.DateTimeFormat("en-IN", options);
      const dateString = formatter.format(utcDate);
      return dateString;
    }
    return null;
  }

  return (
    <div className="w-[900px] overflow-x-auto">
      {registrations?.length > 0 ? (
        <>
          <table className="table-auto md:w-[1020px]">
            <thead className="">
              <tr className="">
                <th className="text-[12px] font-[600] text-left leading-[45px] w-[200px]">
                  User
                </th>
                <th className="text-[12px] font-[600] text-left leading-[45px] w-[230px]">
                  Contact Info
                </th>
                <th className="text-[12px] font-[600] text-left leading-[45px] w-[170px]">
                  {tab === "Attended"
                    ? "Last Check-in time"
                    : "Registration date"}
                </th>
                <th className="text-[12px] font-[600] text-center leading-[45px] w-[100px] -left-[10px] relative">
                  Role
                </th>
                <th className="text-[12px] font-[600] text-center relative -left-[10px] leading-[45px] w-[100px]">
                  Label
                </th>
                <th className="text-[12px] font-[600] leading-[45px] text-center w-[90px]">
                  Badge
                </th>
              </tr>
            </thead>

            <tbody className="md:w-[1020px]">
              {registrations.map((attendee, index) => {
                return (
                  <>
                    <tr className="h-[55px] border-b ">
                      <td className="text-[12px] font-[500]]">
                        <div className="grid grid-cols-[45px_1fr] gap-[1px]">
                          {attendee.profilePicture ? (
                            <img
                              src={attendee.profilePicture}
                              className="w-[32px] h-[32px] rounded-[50%] object-cover"
                            />
                          ) : (
                            <div className=" ">
                              <DefaultProfilePicture
                                firstName={attendee.firstName}
                                lastName={attendee.lastName}
                                style={{
                                  width: "32px",
                                  height: "32px",
                                  borderRadius: "300px",
                                  fontSize: "13px",
                                }}
                              />
                            </div>
                          )}

                          <p className="text-[12px] font-[400] grid grid-rows-2">
                            <p>
                              {attendee.firstName.charAt(0).toUpperCase() +
                                attendee.firstName.slice(
                                  1,
                                  attendee.firstName.length
                                )}{" "}
                              {attendee.lastName}
                            </p>
                            <p className="text-[12px] text-slate-500 line-clamp-1 w-[150px] font-[450]">
                              {attendee.jobTitle && attendee.jobTitle},{" "}
                              {attendee.organization && attendee.organization}
                            </p>
                          </p>
                        </div>
                      </td>
                      <td className="text-[12px] font-[500]">
                        <p className="text-[12px] font-[400] grid grid-rows-2">
                          <p>{attendee.email && attendee.email}</p>
                          <span className="flex">
                            <p className="text-[12px] text-slate-500 font-[450]">
                              {attendee.countryCode && attendee.countryCode}
                              &nbsp;
                            </p>
                            <p className="text-[12px] text-slate-500 font-[450]">
                              {attendee.mobile && attendee.mobile}
                            </p>
                          </span>
                        </p>
                      </td>
                      {attendee.attendee[0] === undefined
                        ? attendee.attendee?.eventSpecificData.map(
                            (ele, index) => {
                              if (ele.eventId === eventsId.params.eventId) {
                                const utcDate = new Date(ele.timeStamp);
                                const options = {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: true,
                                  timeZone: "Asia/Kolkata",
                                };
                                const formatter = new Intl.DateTimeFormat(
                                  "en-IN",
                                  options
                                );
                                const dateString = formatter.format(utcDate);
                                return tab !== "Attended" ? (
                                  <td className="text-[12px] font-[400]">
                                    {ele?.timeStamp ? dateString : "N/A"}
                                  </td>
                                ) : (
                                  <td className="text-[12px] font-[400]">
                                    {formatCheckInTime(
                                      getLatestCheckinTime(ele)
                                    ) || "N/A"}
                                  </td>
                                );
                              }
                            }
                          )
                        : attendee.attendee[0]?.eventSpecificData.map(
                            (ele, index) => {
                              if (ele.eventId === eventsId.params.eventId) {
                                const utcDate = new Date(ele.timeStamp);

                                const options = {
                                  day: "numeric",
                                  month: "short",
                                  year: "numeric",
                                  hour: "2-digit",
                                  minute: "2-digit",
                                  hour12: true,
                                  timeZone: "Asia/Kolkata",
                                };
                                const formatter = new Intl.DateTimeFormat(
                                  "en-IN",
                                  options
                                );
                                const dateString = formatter.format(utcDate);
                                return tab !== "Attended" ? (
                                  <td className="text-[12px] font-[400]">
                                    {ele?.timeStamp ? dateString : "N/A"}
                                  </td>
                                ) : (
                                  <td className="text-[12px] font-[400]">
                                    {formatCheckInTime(
                                      getLatestCheckinTime(ele)
                                    ) || "N/A"}
                                  </td>
                                );
                              }
                            }
                          )}

                      {attendee.attendee[0] === undefined
                        ? attendee.attendee?.eventSpecificData.map(
                            (ele, ind) => {
                              if (ele.eventId === eventsId.params.eventId) {
                                return (
                                  <td
                                    className="text-[12px] font-[400] relative left-[0px]"
                                    id={ind}
                                  >
                                    <p
                                      className={`${
                                        ele.highestRole === "attendee"
                                          ? "bg-[#2D9CDB]"
                                          : ele.highestRole === "speaker"
                                          ? "bg-[#27AE60]"
                                          : ele.highestRole === "organiser"
                                          ? "bg-[#F2994A]"
                                          : "bg-primary"
                                      } rounded-[12px] p-[4px] text-white grid place-items-center w-[85%]`}
                                    >
                                      {ele.highestRole === "exhibitorAndSponsor"
                                        ? "Exhibitor"
                                        : ele.highestRole
                                            .charAt(0)
                                            .toUpperCase() +
                                          ele.highestRole.slice(
                                            1,
                                            ele.highestRole.length
                                          )}
                                    </p>
                                  </td>
                                );
                              }
                            }
                          )
                        : attendee.attendee[0]?.eventSpecificData.map(
                            (ele, ind) => {
                              if (ele.eventId === eventsId.params.eventId) {
                                return (
                                  <td
                                    className="text-[12px] font-[400] relative left-[0px]"
                                    id={ind}
                                  >
                                    <p
                                      className={`${
                                        ele.highestRole === "attendee"
                                          ? "bg-[#2D9CDB]"
                                          : ele.highestRole === "speaker"
                                          ? "bg-[#27AE60]"
                                          : ele.highestRole === "organiser"
                                          ? "bg-[#F2994A]"
                                          : "bg-primary"
                                      } rounded-[12px] p-[4px] text-white grid place-items-center w-[85%]`}
                                    >
                                      {ele.highestRole === "exhibitorAndSponsor"
                                        ? "Exhibitor"
                                        : ele.highestRole
                                            .charAt(0)
                                            .toUpperCase() +
                                          ele.highestRole.slice(
                                            1,
                                            ele.highestRole.length
                                          )}
                                    </p>
                                  </td>
                                );
                              }
                            }
                          )}

                      {attendee.attendee[0] === undefined
                        ? attendee.attendee?.eventSpecificData.map(
                            (ele, ind) => {
                              if (ele.eventId === eventsId.params.eventId) {
                                return (
                                  <td
                                    className="text-[12px] font-[400] relative left-[0px]"
                                    id={ind}
                                  >
                                    <p className="p-[4px] font-[500] text-black grid place-items-center w-[85%] bg-gray-200 rounded-[12px]">
                                      {ele?.label
                                        ? ele.label.charAt(0).toUpperCase() +
                                          ele.label.slice(1, ele.label.length)
                                        : "N/A"}
                                    </p>
                                  </td>
                                );
                              }
                            }
                          )
                        : attendee.attendee[0]?.eventSpecificData.map(
                            (ele, ind) => {
                              if (ele.eventId === eventsId.params.eventId) {
                                return (
                                  <td
                                    className="text-[12px] font-[400] relative left-[0px]"
                                    id={ind}
                                  >
                                    <p className="p-[4px] font-[500] text-black grid place-items-center w-[85%] bg-gray-200 rounded-[12px]">
                                      {ele?.label
                                        ? ele.label.charAt(0).toUpperCase() +
                                          ele.label.slice(1, ele.label.length)
                                        : "N/A"}
                                    </p>
                                  </td>
                                );
                              }
                            }
                          )}

                      <td className="text-center relative">
                        <div
                          ref={(element) => {
                            targetRef.current[index] = element;
                          }}
                          className="absolute z-10 inline-block px-3 py-1 ml-10 mt-0 bg-transparent hidden w-[200px]"
                          onMouseEnter={() => showBadge(index)}
                          onMouseLeave={() => hideBadge(index)}
                        >
                          {attendee.attendee[0] === undefined
                            ? attendee.attendee?.eventSpecificData.map(
                                (items) => {
                                  if (
                                    items.eventId === eventsId.params.eventId
                                  ) {
                                    return (
                                      <img
                                        className="border rounded-t-xl register_img"
                                        src={items.badgeUrl}
                                      />
                                    );
                                  }
                                }
                              )
                            : attendee.attendee[0]?.eventSpecificData.map(
                                (items) => {
                                  if (
                                    items.eventId === eventsId.params.eventId
                                  ) {
                                    return (
                                      <img
                                        className="border rounded-t-xl register_img"
                                        src={items.badgeUrl}
                                      />
                                    );
                                  }
                                }
                              )}

                          <div className=" flex justify-evenly text-white">
                            <button
                              onClick={() => {
                                var win = window.open("");
                                attendee.attendee[0] === undefined
                                  ? attendee.attendee?.eventSpecificData.map(
                                      (items) => {
                                        if (
                                          items.eventId ===
                                          eventsId.params.eventId
                                        ) {
                                          items.badgeUrl === undefined ? (
                                            <></>
                                          ) : (
                                            win.document.write(
                                              '<html><head><style>img { display: block; margin: 0 auto; }</style></head><body><img src="' +
                                                items.badgeUrl +
                                                '" onload="window.print();window.close()" /></body></html>'
                                            )
                                          );
                                        }
                                      }
                                    )
                                  : attendee.attendee[0]?.eventSpecificData.map(
                                      (items) => {
                                        if (
                                          items.eventId ===
                                          eventsId.params.eventId
                                        ) {
                                          items.badgeUrl === undefined ? (
                                            <></>
                                          ) : (
                                            win.document.write(
                                              '<html><head><style>img { display: block; margin: 0 auto; }</style></head><body><img src="' +
                                                items.badgeUrl +
                                                '" onload="window.print();window.close()" /></body></html>'
                                            )
                                          );
                                        }
                                      }
                                    );

                                win.focus();
                              }}
                              className="w-[100%] bg-primary h-[30px] mr-2 rounded-bl-lg"
                            >
                              Print
                            </button>
                            <button
                              className="w-[100%] bg-primary h-[30px] rounded-br-lg"
                              onClick={() => {
                                attendee.attendee[0] === undefined
                                  ? attendee.attendee?.eventSpecificData.map(
                                      (items) => {
                                        if (
                                          items.eventId ===
                                          eventsId.params.eventId
                                        ) {
                                          items.badgeUrl === undefined ? (
                                            <></>
                                          ) : (
                                            window.open(
                                              `${items.badgeUrl} `,
                                              "_blank"
                                            )
                                          );
                                        }
                                      }
                                    )
                                  : attendee.attendee[0]?.eventSpecificData.map(
                                      (items) => {
                                        if (
                                          items.eventId ===
                                          eventsId.params.eventId
                                        ) {
                                          items.badgeUrl === undefined ? (
                                            <></>
                                          ) : (
                                            window.open(
                                              `${items.badgeUrl} `,
                                              "_blank"
                                            )
                                          );
                                        }
                                      }
                                    );
                              }}
                            >
                              Preview
                            </button>
                          </div>
                        </div>
                        <img
                          src="/svgs/IDCard.svg"
                          alt="IDCard"
                          className="ml-[35px] cursor-pointer"
                          onMouseEnter={() => showBadge(index)}
                          onMouseLeave={() => hideBadge(index)}
                        />
                      </td>
                    </tr>
                  </>
                );
              })}
            </tbody>
          </table>
          {/* {tab === "Registered" ? ( */}

          <div className="flex items-center ml-0  rounded-lg w-full mx-auto mt-[10px]">
            <div className="text-[13px] mr-2">
              <span className="font-semibold text-[14px]">
                {registrations?.length}
              </span>{" "}
              of <span className="font-semibold text-[14px]">{total}</span>{" "}
              results
            </div>

            <button
              className={`text-center cursor-pointer text-${
                previous === null ? "gray-300" : "primary"
              } text-[23px]`}
              disabled={previous === null ? true : false}
              onClick={async () => {
                // await getAllEventAttendees(previous);
                if (tab === "Registered") {
                  await getAllEventAttendees(previous);
                } else {
                  await getAttendedAttendees(previous);
                }
              }}
            >
              {"<"}
            </button>

            <div className="mx-3 cursor-pointer">
              <select
                id="title"
                name="title"
                // {...register("gender")}
                required
                value={currentPage}
                onChange={(e) => {
                  if (tab === "Registered") {
                    getAllEventAttendees(
                      `/attendee/${eventsId.params.eventId}/?page=${e.target.value}&limit=10`
                    );
                  } else {
                    const url = next || previous;
                    const regex = /\?(.*?)&location/;
                    const match = url.match(regex);
                    const queryString = match ? match[1] : "";

                    getAttendedAttendees(
                      `/attendee/${eventsId.params.eventId}/attended/?${queryString}&location=eventCheckin&page=${e.target.value}&limit=10`
                    );
                  }
                }}
                className={`
              w-[75px]
              peer bg-white border-2 border-primary rounded-[5px] text-gray-900 text-[12px] placeholder-transparent focus:ring-transparent py-0 bg-[right] 1.5rem cursor-pointer
              bg-center `}
              >
                {Array.from({ length: totalPages }).map((_, index) => (
                  <option
                    key={index + 1}
                    value={index + 1}
                    className="hover:bg-gray-300 border-primary rounded-[5px] overflow-scroll max-h-[50px]"
                  >
                    {index + 1}
                  </option>
                ))}
              </select>
            </div>

            <button
              disabled={next === null ? true : false}
              className={`text-center cursor-pointer text-${
                next === null ? "gray-300" : "primary"
              } text-[23px]`}
              onClick={async () => {
                if (tab === "Registered") {
                  await getAllEventAttendees(next);
                } else {
                  await getAttendedAttendees(next);
                }
                // await getAllEventAttendees(next);
              }}
            >
              {">"}
            </button>
          </div>
          {/* ) : (
            <></>
          )} */}
        </>
      ) : (
        <div className="grid w-full place-items-center h-[350px] mt-[40px]">
          <div>
            <img
              src="/svgs/nullState.svg"
              alt=""
              className="w-[500px] h-[300px]"
            />
            <p className="text-[15px] font-[500] text-[#717171] text-center mt-[15px]">
              Nothing here...
            </p>
          </div>
        </div>
      )}
    </div>
  );
};

export default RegistrationTabs;
