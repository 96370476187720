import React, { useEffect, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { getRequest } from "../../../src/utils/API/api.ts";
import styles from "./Landing.module.css";
import axios from "axios";
import TextInputP from "../../common/inputElements/TextInputP";
import { useMatch, useNavigate } from "react-router-dom";
import { FormControl } from "../eventLayout/settings2/regForm/FormControl";
import CountryCityRegForm from "./CountryCityRegForm.jsx";
import countryCodes from "../../utils/CountryCodes.json";
import { useDispatch } from "react-redux";
import { showToast } from "../../redux/actions/toastActions.js";

const LandingRegForm = ({ setIsRegistered, setRegisteredUserEmail }) => {
  const [regForm, setRegForm] = useState([]);
  const dispatch = useDispatch();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [windowWidth, setWindowWidth] = useState(0);
  const eventsId = useMatch("/event/:eventId");
  const [triggerInfo, setTriggerInfo] = useState(false);
  const navigate = useNavigate();
  const formMethods = useForm();
  const [countryResult, setCountryResult] = useState([]);
  const [cityResult, setCityResult] = useState([]);
  const [stateResult, setStateResult] = useState([]);
  const [triggerCode, setTriggerCode] = useState(false);
  const [countryCode, setCountryCode] = useState("+91");
  const [allCountryCode, setAllCountryCode] = useState(countryCodes);
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset,
  } = formMethods;
  useEffect(() => {
    const getAdditionalForm = async () => {
      const response = await getRequest(
        `/event/${eventsId.params.eventId}/additionalForm`
      );
      setRegForm(response.data[0]?.form);
      // console.log(response.data[0]?.form, "new form");
    };
    if (eventsId.params.eventId) {
      getAdditionalForm();
    }
  }, [eventsId]);

  function onFormSubmit(data, error) {
    const matchingCountry = countryCodes.find(
      (country) => country.dial_code === countryCode
    );
    const regex = /^[\d]{10}$/;
    if (!matchingCountry) {
      dispatch(
        showToast({
          message: "Invalid Country Code.",
          category: "danger",
        })
      );
      setIsSubmitting(false);
      return;
    }

    if (!regex.test(data.mobile)) {
      dispatch(
        showToast({
          message: "Invalid Mobile Number",
          category: "danger",
        })
      );
      setIsSubmitting(false);
      return;
    }
    let additionalData = {};
    if (Object.keys(data).length > 6) {
      for (const prop in data) {
        if (
          prop !== "firstName" &&
          prop !== "lastName" &&
          prop !== "mobile" &&
          prop !== "email" &&
          prop !== "organization" &&
          prop !== "jobTitle" &&
          prop !== "country" &&
          prop !== "state" &&
          prop !== "city" &&
          prop !== "gender"
        ) {
          additionalData[prop] = data[prop];
        }
      }
    }
    setIsSubmitting(true);
    axios
      .post(`${process.env.REACT_APP_SERVER_URL_LEADS}/attendee/eventregister`, {
        firstName: data.firstName,
        lastName: data.lastName,
        mobile: data.mobile,
        countryCode: countryCode,
        email: data.email,
        eventId: eventsId.params.eventId,
        jobTitle: data.jobTitle,
        organization: data.organization,
        country: data.country,
        state: data.state,
        city: data.city,
        gender: data.gender,
        additionalData: additionalData,
      })
      .then(function (response) {
        setRegisteredUserEmail(data.email);
        reset();
        setIsSubmitting(false);
        setIsRegistered(true);
      })
      .catch(function (error) {
        reset();
        setIsSubmitting(false);
        if (
          error.response.data.message ===
          "You have already registered for this event"
        ) {
          dispatch(
            showToast({
              message: "You have already registered for this event",
              category: "danger",
            })
          );
        }
        setIsSubmitting(false);
      });
  }

  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  useEffect(() => {
    if (countryCode) {
      const matchingCountries = countryCodes.filter(
        (country) =>
          country.dial_code
            .toLowerCase()
            .startsWith(countryCode.toLowerCase()) ||
          country.name.toLowerCase().startsWith(countryCode.toLowerCase()) ||
          country.code.toLowerCase().startsWith(countryCode.toLowerCase())
      );

      const nonMatchingCountries = countryCodes.filter(
        (country) =>
          !(
            country.dial_code
              .toLowerCase()
              .startsWith(countryCode.toLowerCase()) ||
            country.name.toLowerCase().startsWith(countryCode.toLowerCase()) ||
            country.code.toLowerCase().startsWith(countryCode.toLowerCase())
          )
      );

      setAllCountryCode([...matchingCountries, ...nonMatchingCountries]);
    }
  }, [countryCode]);

  return (
    <div className="w-[100%] mymd:w-full">
      <form
        onSubmit={handleSubmit(onFormSubmit)}
        className={styles.reg_form_box}
      >
        <div className="mymd:w-[500px] flex items-center mb-5 mt-3">
          <input
            type="checkbox"
            id="radiobtn"
            className="mr-2 border border-[#cacaca] border-2 bg-white checked:bg-primary checked:border-[#EBEEF2] cursor-pointer focus:ring-0"
            checked={false}
            onChange={() =>
              navigate(
                `/event/${eventsId.params.eventId}?tab=${"registerlinkedin"}`
              )
            }
          />
          <label htmlFor="radiobtn" className=" cursor-pointer ">
            <span className="flex text-[14px] font-[600] items-center">
              Share this on&nbsp;
              <img src="/svgs/linkedinblue.svg" alt="linkedin" /> &nbsp;Linkedin
            </span>
          </label>
          <div className=" relative">
            <img
              src="/svgs/info.svg"
              className="h-[20px] ml-1 cursor-pointer"
              onMouseEnter={() => setTriggerInfo(true)}
              onMouseLeave={() => setTriggerInfo(false)}
            />
            {triggerInfo && (
              <p className="text-[12px] absolute z-20 bg-gray-700 text-[12px] p-2 rounded-md text-white w-[200px] left-[20px]">
                Tick here to share you are attending this event on your
                linkedin. By opting in you understand that your profile details
                will be shared with us for marketing purpose.
              </p>
            )}
          </div>
        </div>
        {/* <TextInputP
            register={register}
            type="text"
            id={"firstName"}
            label="First Name"
            required
            width={windowWidth > 900 ? "200px" : ""} */}
        <div className="mymd:w-full mymd:flex pt-[15px] w-[100%] justify-between">
          <div
            className="relative mb-[28px]  mymd:w-[250px] w-[100%] 
             peer bg-white border border-gray-300 text-gray-900 text-[12px] rounded-lg h-[35px] placeholder-transparent focus:ring-transparent focus:border-primary focus:border-[1.2px] block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white font-medium dark:focus:ring-blue-500 dark:focus:border-blue-500 flex py-0 items-center "
          >
            <div>
              <select
                id="title"
                name="title"
                {...register("gender")}
                required
                className={`
              w-[80px]
              peer bg-white border-0 text-gray-900 text-[12px]  placeholder-transparent focus:ring-transparent py-0 bg-[right] 1.5rem
              bg-center`}
              >
                <option value="Male">Mr.</option>
                <option value="Female">Ms.</option>
                <option value="Female">Mrs.</option>
                <option value="Dr.">Dr.</option>
                <option value="Prof.">Prof.</option>
              </select>
            </div>
            <div>
              <input
                type="text"
                id="firstName"
                autoComplete="off"
                className={`w-[100%]
                 bg-white border-0 text-gray-900 text-[12px] h-[33px] placeholder-transparent focus:ring-transparent p-0 appearance-none bg-transparent focus:outline-none focus:ring-0 focus:border-blue-500
           `}
                // placeholder={label}
                {...register("firstName")}
                style={{ backgroundColor: "transparent" }}
              />
              <label
                htmlFor="firstName"
                className="absolute left-2 -top-5 mb-1 text-sm peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-900 peer-placeholder-shown:text-opacity-40 peer-placeholder-shown:top-[10px] peer-placeholder-shown:left-[13px] transition-all peer-focus:-top-5 peer-focus:text-gray-900 peer-focus:text-opacity-40 peer-focus:text-[12.5px] font-medium text-gray-900 text-opacity-40 dark:text-gray-300"
              >
                <>
                  First Name&nbsp;<span className="text-red-500">*</span>
                </>
              </label>
            </div>
          </div>

          <TextInputP
            register={register}
            type="text"
            id={"lastName"}
            label="Last Name"
            required
            // minLength={0}
            width={windowWidth > 900 ? "250px" : ""}
          />
        </div>

        <TextInputP
          register={register}
          type="email"
          id={"email"}
          label="Email Address"
          required
          errors={errors}
          pattern={
            /^([a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)$/i
          }
          width={"100%"}
        />

        <div
          className="relative mb-[28px] w-[100%] 
             peer bg-white border border-gray-300 text-gray-900 text-[12px] rounded-lg h-[35px] placeholder-transparent focus:ring-transparent focus:border-primary focus:border-[1.2px] block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white font-medium dark:focus:ring-blue-500 dark:focus:border-blue-500 flex py-0 items-center "
        >
          <div className="relative">
            <div className="flex items-center">
              <input
                type="text"
                id="code"
                required
                value={countryCode}
                onFocus={() => setTriggerCode(true)}
                onBlur={() => setTriggerCode(false)}
                onChange={(event) => setCountryCode(event.target.value)}
                // {...register("code")}
                className={`w-[52px]
                    peer bg-white border-0 border-gray-300 text-gray-900 text-[12px] rounded-lg h-[30px] placeholder-transparent focus:ring-transparent block pl-1.5 `}
                autoComplete="off"
              />
              <img
                src="/svgs/arrow.svg"
                alt=""
                onClick={() => setTriggerCode(!triggerCode)}
                className="transform rotate-180 h-[16px] cursor-pointer mr-2"
              />
            </div>

            {triggerCode && (
              <div className="border max-h-[110px] absolute w-[83px] z-20 bg-white overflow-y-scroll rounded-lg border-primary">
                {allCountryCode?.map((codes) => (
                  <span
                    // key={result}
                    className="w-[100%] flex h-[40px] items-center pl-2 text-[12px] font-[500] cursor-pointer hover:bg-gray-200 "
                    onMouseDown={(event) => {
                      setCountryCode(codes.dial_code);
                      event.stopPropagation();
                    }}
                  >
                    {codes.code} {codes.dial_code}
                  </span>
                ))}
              </div>
            )}
          </div>
          <div className="w-[100%]" onClick={() => setTriggerCode(false)}>
            <input
              type="tel"
              id="mobile"
              maxLength={10}
              required
              autoComplete="off"
              className={`w-[100%]
                 bg-white border-0 text-gray-900 text-[12px] h-[33px] placeholder-transparent focus:ring-transparent p-0 appearance-none bg-transparent focus:outline-none focus:ring-0 focus:border-blue-500 pl-2
           `}
              {...register("mobile")}
              style={{ backgroundColor: "transparent" }}
            />
            <label
              htmlFor="mobile"
              className="absolute left-2 -top-5 mb-1 text-sm peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-900 peer-placeholder-shown:text-opacity-40 peer-placeholder-shown:top-[10px] peer-placeholder-shown:left-[13px] transition-all peer-focus:-top-5 peer-focus:text-gray-900 peer-focus:text-opacity-40 peer-focus:text-[12.5px] font-medium text-gray-900 text-opacity-40 dark:text-gray-300"
            >
              <>
                Phone Number&nbsp;<span className="text-red-500">*</span>
              </>
            </label>
          </div>
        </div>
        <TextInputP
          register={register}
          type="text"
          id={"organization"}
          required
          label="Company Name"
          width={"100%"}
        />
        <TextInputP
          register={register}
          type="text"
          id={"jobTitle"}
          required
          label="Designation"
          width={"100%"}
        />

        <FormProvider {...formMethods}>
          {regForm &&
            regForm.length > 0 &&
            regForm.map((form, key) => (
              <div key={key}>
                <CountryCityRegForm
                  form={form}
                  setCountryResult={setCountryResult}
                  countryResult={countryResult}
                  cityResult={cityResult}
                  setCityResult={setCityResult}
                  setStateResult={setStateResult}
                  stateResult={stateResult}
                />
              </div>
            ))}
        </FormProvider>

        <FormProvider {...formMethods}>
          {regForm &&
            regForm.length > 0 &&
            regForm.map((ele, key) => (
              <div key={key}>
                <FormControl {...ele} showIcons={false} width={"100%"} />
              </div>
            ))}
        </FormProvider>

        <div className="mymd:w-[100%] mymd:flex flex-col">
          <button
            type="submit"
            disabled={isSubmitting}
            className={styles.submit_btn}
            // onClick={() => setSubmitDisable(true)}
          >
            {isSubmitting ? "Loading..." : "Register"}
          </button>
          <div
            className="text-center text-[14px] mt-[15px] cursor-pointer"
            onClick={() =>
              window.open(`${window.location.origin}/login`, "_blank")
            }
          >
            <span>
              Already Registered?{" "}
              <span className="text-primary font-[500] ">Login</span>
            </span>
          </div>
        </div>
      </form>
    </div>
  );
};

export default LandingRegForm;
