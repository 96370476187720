import { Dialog, Transition } from "@headlessui/react";
import React, { Fragment } from "react";
import { useNavigate } from "react-router-dom";

const SuccessScan = ({
  isOpen,
  closeModal,
  eventId,
  setIsOpen,
  setScannedUser,
  scannedUser,
}) => {
  const navigate = useNavigate();

  return (
    <div className="">
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-70" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto w-full h-full">
            <div className="flex h-full p-0 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel
                  className=" transform overflow-hidden  bg-white  align-middle shadow-xl transition-all  w-full h-full flex justify-center items-center border flex-col"
                  // style={{ ...style }}
                >
                  <div>
                    {/* <div className="absolute top-0 left-0 z-50 p-4 text-[20px] font-medium">
                      Title
                    </div> */}
                    <div
                      className="modal-close absolute top-0 right-0 cursor-pointer z-50 p-4"
                      onClick={() => {
                        closeModal();
                      }}
                    >
                      <svg
                        className="fill-current text-black"
                        xmlns="http://www.w3.org/2000/svg"
                        width="24"
                        height="24"
                        viewBox="0 0 18 18"
                      >
                        <path d="M0 0h18v18H0z" fill="none" />
                        <path
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M10.293 9l4.646-4.646a1 1 0 10-1.414-1.414L9 7.586 4.354 2.94a1 1 0 10-1.414 1.414L7.586 9l-4.646 4.646a1 1 0 001.414 1.414L9 10.414l4.646 4.646a1 1 0 001.414-1.414L10.414 9z"
                          fill="#374151"
                        />
                      </svg>
                    </div>
                  </div>

                  <div className="w-[190px] relative flex justify-center items-center mt-0">
                    {/* <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 200 200"
                    >
                      <circle
                        fill="none"
                        stroke-opacity="1"
                        stroke="#2ECC71"
                        stroke-width=".5"
                        cx="100"
                        cy="100"
                        r="0"
                      >
                        <animate
                          attributeName="r"
                          calcMode="spline"
                          dur="2"
                          values="1;80"
                          keyTimes="0;1"
                          keySplines="0 .2 .5 1"
                          repeatCount="indefinite"
                        ></animate>
                        <animate
                          attributeName="stroke-width"
                          calcMode="spline"
                          dur="2"
                          values="0;25"
                          keyTimes="0;1"
                          keySplines="0 .2 .5 1"
                          repeatCount="indefinite"
                        ></animate>
                        <animate
                          attributeName="stroke-opacity"
                          calcMode="spline"
                          dur="2"
                          values="1;0"
                          keyTimes="0;1"
                          keySplines="0 .2 .5 1"
                          repeatCount="indefinite"
                        ></animate>
                      </circle>
                    </svg> */}
                    <img src="/svgs/Animate.svg" alt="check" className=" " />
                    <img
                      src="/svgs/Checkcircle.svg"
                      alt="check"
                      className=" rounded-full w-[80px] absolute z-30 bg-white"
                    />
                  </div>
                  <p className="leading-3 text-[25px] font-medium text-[#2ECC71]">
                    Lead Generated!!
                  </p>
                  <div className="border rounded-lg max-w-[80%] flex justify-center items-center flex-col shadow-[0_0px_4px_1px_rgba(0,0,0,0.1)] p-3  shadow-[#2ECC71] text-[20px] mt-8">
                    <p>
                      <span className="font-medium whitespace-normal break-all">
                        {scannedUser?.firstName} {scannedUser?.lastName}
                      </span>{" "}
                      added to leads.
                    </p>

                    <div className="mt-3 w-[100%] gap-3">
                      <button
                        className="h-[33px] rounded-[4px] text-[#1C1C1E] font-[500] text-[16px] px-2 w-full border bg-gray-100"
                        onClick={() => {
                          navigate(`/attendee/${eventId}?tab=leads&view=leads`);
                        }}
                      >
                        Check all leads
                      </button>

                      <button
                        className="bg-primary border text-white font-[500]  rounded-[4px] h-[33px] text-[16px] px-2 w-full mt-2"
                        onClick={() => {
                          setIsOpen(false);
                          setScannedUser({});
                        }}
                      >
                        Scan another badge
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </div>
  );
};

export default SuccessScan;
