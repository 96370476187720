import React, { useEffect, useState } from "react";

import AddMailchimp from "./AddMailchimp";
import { useMatch, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getSingleEvent } from "../../../../redux/actions/eventActions";
import { showToast } from "../../../../redux/actions/toastActions";
import { patchRequest } from "../../../../utils/API/api.ts";

const Mailchimp = () => {
  const [open, setOpen] = useState(false);
  const event = useSelector((state) => state.eventData);
  const [isEdit, setIsEdit] = useState(false);
  const [eventDetails, setEventDetails] = useState([]);
  const eventsId = useMatch("/events/:eventId/*");
  const [popup, setPopup] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getSingleEvent({ eventId: eventsId.params.eventId }));
    return () => {};
  }, [eventsId.params.eventId]);

  useEffect(() => {
    if (event?._id) {
      setEventDetails(event);
    }
  }, [event?._id]);

  const deleteApiKey = async () => {
    const obj = {
      apiKey: "none",
      listId: "none",
      server: "none",
    };
    try {
      const updatedEvent = await patchRequest(
        `/event/${eventsId.params.eventId}`,

        {
          mailchimpData: obj,
        }
      );
      if (updatedEvent.status === 200) {
        setEventDetails(updatedEvent.data.savedEventConfig);
        dispatch(
          showToast({
            message: "Api Key Removed",
            category: "success",
          })
        );
      }
      setPopup(false);
    } catch (err) {
      dispatch(
        showToast({
          message: "Some Error occured",
          category: "danger",
        })
      );
      setPopup(false);
    }
  };

  return (
    <>
      {popup && (
        <div className="">
          <div
            className="h-full top-0 bg-[rgba(0,0,0,0.4)] z-40 fixed w-[100%] md:left-0"
            onClick={() => setPopup(false)}
          ></div>
          <div
            className={`mymd:w-[400px] h-[210px] w-full z-50 fixed bottom-0 bg-white rounded-lg p-5 md:left-0 md:right-0 mx-auto md:top-1/2 md:-translate-y-1/2 `}
          >
            <div className="h-[35px] font-semibold text-[20px] border-b-2 outline-offset-4 relative">
              Delete
              <span
                className="absolute right-0 rounded-full bg-gray-200 cursor-pointer w-[28px] h-[28px]"
                onClick={() => setPopup(false)}
              >
                <div className="flex justify-center items-center text-gray-800 w-[28px] h-[28px] text-[17px]">
                  x
                </div>
              </span>
              <div className="h-[85px] flex justify-evenly items-center mt-2 font-[500]">
                Are you sure you want to remove Mailchimp api key?
              </div>
              <div className="flex justify-between items-center h-[40px] w-[360px] mt-2">
                <button
                  className="border rounded-lg bg-white text-black text-[14px] w-[46%] h-[40px]"
                  onClick={() => setPopup(false)}
                >
                  Cancel
                </button>
                <button
                  className="rounded-lg bg-danger text-white text-[14px] w-[46%] h-[40px]"
                  onClick={deleteApiKey}
                >
                  Delete
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {!eventDetails?.mailchimpData?.server ? (
        <></>
      ) : (
        <div className="w-full md:w-[845px] md:ml-[0px] md:mt-[27px] pb-[200px] ">
          <div className="font-[600] w-[375px] mx-auto md:w-[845px] text-[24px] text-[#585858] flex items-center mt-2">
            <div className="cursor-pointer mr-4" onClick={() => navigate(-1)}>
              <svg
                width="28"
                height="28"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M19 12H5"
                  stroke="#585858"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M12 19L5 12L12 5"
                  stroke="#585858"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </div>
            Mailchimp
          </div>
          {eventDetails?.mailchimpData?.server !== "none" ? (
            <table className="table-auto md:w-[820px] mt-[30px]">
              <thead className="">
                <tr className="bg-gray-200">
                  <th className="text-[14px] font-[500] text-left leading-[45px] w-[180px] bg-gray-200 pl-[20px]">
                    Api Key
                  </th>
                  <th className="text-[14px] font-[500] text-left leading-[45px] w-[125px]">
                    ListId / AudienceId
                  </th>
                  <th className="text-[14px] font-[500] text-left leading-[45px] w-[70px]">
                    Server
                  </th>
                  <th className="text-[12px] font-[500] text-left leading-[45px] w-[70px]"></th>
                  <th className="text-[12px] font-[500] leading-[45px] text-center w-[70px]"></th>
                </tr>
              </thead>

              <tbody className="">
                <tr className="h-[55px] border-b">
                  <td className="text-[13px] font-[500]] pl-[20px]">
                    {eventDetails?.mailchimpData?.apiKey.substring(
                      0,
                      eventDetails?.mailchimpData?.apiKey.length - 7
                    ) + "*".repeat(7)}
                  </td>
                  <td className="text-[13px] font-[500]]">
                    {eventDetails?.mailchimpData?.listId}
                  </td>
                  <td className="text-[13px] font-[500]]">
                    {eventDetails?.mailchimpData?.server}
                  </td>
                  <td
                    className="text-[12px] font-[500]] text-danger cursor-pointer hover:underline"
                    onClick={() => setPopup(true)}
                  >
                    Remove
                  </td>
                  <td
                    className="text-[12px] font-[500]] text-primary cursor-pointer hover:underline"
                    onClick={() => {
                      setIsEdit(true);
                      setOpen(true);
                    }}
                  >
                    Edit
                  </td>
                </tr>
              </tbody>
            </table>
          ) : (
            <>
              <div className="mt-5 text-[20px] text-[#585858] font-[600]">
                Connect Mailchimp
              </div>
              <div
                className="border w-[400px] min-h-[300px] mt-5 rounded-lg scrollbar-hide flex justify-center items-center flex-col"
                style={{ boxShadow: "0px -4px 14px 4px rgba(0, 0, 0, 0.1)" }}
              >
                <img
                  src="/svgs/mailchimp.svg"
                  alt="mailchimp"
                  className="w-[100px]"
                />
                <div className="mt-[30px] w-[200px]">
                  <input
                    type="submit"
                    className="primary_submit"
                    value={"Connect Mailchimp"}
                    onClick={() => {
                      setOpen(true);
                      setIsEdit(false);
                    }}
                  />
                </div>
              </div>
            </>
          )}

          <AddMailchimp
            open={open}
            setOpen={setOpen}
            isEdit={isEdit}
            setIsEdit={setIsEdit}
            setEventDetails={setEventDetails}
            eventDetails={eventDetails}
          />
        </div>
      )}
    </>
  );
};

export default Mailchimp;
