import React, { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import throttle from "../../../utils/throttle";
import countryCodes from "../../../utils/CountryCodes.json";
import { useMatch, useNavigate } from "react-router-dom";
import { showToast } from "../../../redux/actions/toastActions";
import { getSingleEvent } from "../../../redux/actions/eventActions";
import TextInputP from "../../../common/inputElements/TextInputP";
import Select from "../../../common/inputElements/Select";
import useDebounce from "../../../helper/hooks/useDebounce";
import axios from "axios";
import { useReactToPrint } from "react-to-print";

const OnsiteRegistrationForm = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [success, setSuccess] = useState({
    isSuccess: null,
    message: "",
  });
  const dispatch = useDispatch();
  const eventsId = useMatch("/events/:eventId/*");
  const singleEvent = useSelector((state) => state.eventData);
  const [triggerCode, setTriggerCode] = useState(false);
  const [countryCode, setCountryCode] = useState("+91");
  const [allCountryCode, setAllCountryCode] = useState(countryCodes);
  const [value1, setValue1] = useState([]);
  const [options, setOptions] = useState([]);
  const [countryResult, setCountryResult] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const debouncedSearchValue = useDebounce(searchTerm, 700);
  const [showResults, setShowResults] = useState(false);
  const [badgeUrl, setBadgeUrl] = useState("");
  const [windowWidth, setWindowWidth] = useState(0);

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    defaultValues: {},
    mode: "onChange",
  });

  useEffect(() => {
    dispatch(getSingleEvent({ eventId: eventsId.params.eventId }));
    return () => {};
  }, []);

  useEffect(() => {
    const speakerOptions = [
      {
        label: "Sponsor",
        value: "1",
      },
      {
        label: "Speaker",
        value: "2",
      },
      {
        label: "Government",
        value: "3",
      },
      {
        label: "Delegate",
        value: "4",
      },
      {
        label: "Media",
        value: "5",
      },
      {
        label: "Organizer",
        value: "6",
      },
      {
        label: "Volunteer",
        value: "7",
      },
    ];
    setOptions(speakerOptions);
    setValue1([]);
    setValue();
  }, []);

  function onSubmit(data, error) {
    const matchingCountry = countryCodes.find(
      (country) => country.dial_code === countryCode
    );
    const regex = /^[\d]{10}$/;
    if (!matchingCountry) {
      dispatch(
        showToast({
          message: "Invalid Country Code.",
          category: "danger",
        })
      );
      setIsSubmitting(false);
      return;
    }

    if (!regex.test(data.mobile)) {
      dispatch(
        showToast({
          message: "Invalid Mobile Number",
          category: "danger",
        })
      );
      setIsSubmitting(false);
      return;
    }

    if (!data.label) {
      dispatch(
        showToast({
          message: "Please add the label",
          category: "danger",
        })
      );
      setIsSubmitting(false);
      return;
    }

    let additionalData = {};
    if (Object.keys(data).length > 6) {
      for (const prop in data) {
        if (
          prop !== "firstName" &&
          prop !== "lastName" &&
          prop !== "mobile" &&
          prop !== "email" &&
          prop !== "organization" &&
          prop !== "jobTitle" &&
          prop !== "country" &&
          prop !== "state" &&
          prop !== "city" &&
          prop !== "gender" &&
          prop !== "label"
        ) {
          additionalData[prop] = data[prop];
        }
      }
    }
    setIsSubmitting(true);
    axios
      .post(
        `${process.env.REACT_APP_SERVER_URL_LEADS}/attendee/eventregister/registerOnsiteAttendee`,
        {
          firstName: data.firstName,
          lastName: data.lastName,
          mobile: data.mobile,
          countryCode: countryCode,
          email: data.email,
          eventId: eventsId.params.eventId,
          jobTitle: data.jobTitle,
          organization: data.organization,
          country: countryResult,
          gender: data.gender,
          label: data.label.label,
          additionalData: additionalData,
        }
      )
      .then(function (response) {
        setBadgeUrl(response.data.badgeUrl);
        reset();
        setValue1([]);
        setValue();
        setCountryResult([]);
        setIsSubmitting(false);
        dispatch(
          showToast({
            message: "You have successfully registered",
            category: "success",
          })
        );
        setSuccess({
          isSuccess: true,
          message: "You have successfully registered",
        });
      })
      .catch(function (error) {
        reset();
        setIsSubmitting(false);
        if (
          error.response.data.message ===
          "You have already registered for this event"
        ) {
          dispatch(
            showToast({
              message: "You have already registered for this event",
              category: "danger",
            })
          );
        }
        setSuccess({
          isSuccess: false,
          message: "You have already registered for this event",
        });
        setIsSubmitting(false);
      });
  }

  useEffect(() => {
    if (countryCode) {
      const matchingCountries = countryCodes.filter(
        (country) =>
          country.dial_code
            .toLowerCase()
            .startsWith(countryCode.toLowerCase()) ||
          country.name.toLowerCase().startsWith(countryCode.toLowerCase()) ||
          country.code.toLowerCase().startsWith(countryCode.toLowerCase())
      );

      const nonMatchingCountries = countryCodes.filter(
        (country) =>
          !(
            country.dial_code
              .toLowerCase()
              .startsWith(countryCode.toLowerCase()) ||
            country.name.toLowerCase().startsWith(countryCode.toLowerCase()) ||
            country.code.toLowerCase().startsWith(countryCode.toLowerCase())
          )
      );

      setAllCountryCode([...matchingCountries, ...nonMatchingCountries]);
    }
  }, [countryCode]);

  let resizeWindow = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    resizeWindow();
    window.addEventListener("resize", resizeWindow);
    return () => window.removeEventListener("resize", resizeWindow);
  }, []);

  useEffect(() => {
    var headers = new Headers();
    headers.append(
      "X-CSCAPI-KEY",
      " bXJra0hXRXk2UTdZWmdYTzZDQU53TEVyT1JIWUJ3YmxRZnA3dEpqUA=="
    );
    var requestOptions = {
      method: "GET",
      headers: headers,
      redirect: "follow",
    };

    fetch(`${"https://api.countrystatecity.in/v1/countries"}`, requestOptions)
      .then((response) => response.json())
      .then((result) => {
        if (debouncedSearchValue === "") {
          const firstFourCountries = result.slice(0, 100);
          setSearchResults(firstFourCountries);
        } else {
          const filteredCountries = result.filter((country) =>
            country.name.toLowerCase().startsWith(searchTerm.toLowerCase())
          );

          setSearchResults(filteredCountries);
        }
      })
      .catch((error) => console.log("error", error));
  }, [debouncedSearchValue]);

  useEffect(() => {
    if (badgeUrl) {
      PrintImage();
    }
  }, [badgeUrl]);

  const PrintImage = useReactToPrint({
    content: () => {
      const imgElement = document.createElement("img");
      imgElement.src = badgeUrl;
      imgElement.style.width = "97%";
      imgElement.style.height = "97%";
      imgElement.style.display = "block";
      const containerElement = document.createElement("div");
      containerElement.style.display = "flex";
      containerElement.style.justifyContent = "center";
      containerElement.style.alignItems = "center";
      containerElement.style.width = "100%";
      containerElement.style.height = "100%";
      containerElement.appendChild(imgElement);
      return containerElement;
    },
    onAfterPrint: () => {
      setBadgeUrl("");
    },
  });

  return (
    <>
      {singleEvent?.startDate === undefined ? (
        <></>
      ) : !isSubmitting ? (
        <>
          <div className="grid place-items-center my-[35px] ">
            <div
              className="w-[600px] flex h-full flex-col overflow-y-scroll bg-white py-6 "
              style={{ boxShadow: "0px -4px 14px 4px rgba(0, 0, 0, 0.1)" }}
            >
              <div className="px-4 sm:px-6">
                <p className="text-[21px] pt-2 pb-[10px] font-[600] text-gray-900">
                  Onsite Registration form for{" "}
                  <span className="text-primary">{singleEvent?.title}</span>
                </p>
              </div>
              <div className="relative mt-6 flex-1 px-4 sm:px-6">
                <form
                  onSubmit={handleSubmit((data) =>
                    throttle(() => onSubmit(data), 5000)
                  )}
                >
                  <div className="mymd:w-full mymd:flex pt-[15px] w-[100%] justify-between ">
                    <div
                      className="relative mb-[28px]  mymd:w-[50%] w-[100%] 
             peer bg-white border border-gray-300 text-gray-900 text-[12px] rounded-lg h-[35px] placeholder-transparent focus:ring-transparent focus:border-primary focus:border-[1.2px] block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white font-medium dark:focus:ring-blue-500 dark:focus:border-blue-500 flex py-0 items-center "
                    >
                      <div>
                        <select
                          id="title"
                          name="title"
                          {...register("gender")}
                          required
                          className={`
              w-[80px]
              peer bg-white border-0 text-gray-900 text-[12px]  placeholder-transparent focus:ring-transparent py-0 bg-[right] 1.5rem
              bg-center`}
                        >
                          <option value="Male">Mr.</option>
                          <option value="Female">Ms.</option>
                          <option value="Female">Mrs.</option>
                          <option value="Dr.">Dr.</option>
                          <option value="Prof.">Prof.</option>
                        </select>
                      </div>
                      <div>
                        <input
                          type="text"
                          id="firstName"
                          autoComplete="off"
                          className={`w-[100%]
                 bg-white border-0 text-gray-900 text-[12px] h-[33px] placeholder-transparent focus:ring-transparent p-0 appearance-none bg-transparent focus:outline-none focus:ring-0 focus:border-blue-500
           `}
                          // placeholder={label}
                          {...register("firstName")}
                          style={{ backgroundColor: "transparent" }}
                        />
                        <label
                          htmlFor="firstName"
                          className="absolute left-2 -top-5 mb-1 text-sm peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-900 peer-placeholder-shown:text-opacity-40 peer-placeholder-shown:top-[10px] peer-placeholder-shown:left-[13px] transition-all peer-focus:-top-5 peer-focus:text-gray-900 peer-focus:text-opacity-40 peer-focus:text-[12.5px] font-medium text-gray-900 text-opacity-40 dark:text-gray-300"
                        >
                          <>
                            First Name&nbsp;
                            <span className="text-red-500">*</span>
                          </>
                        </label>
                      </div>
                    </div>

                    <TextInputP
                      register={register}
                      type="text"
                      id={"lastName"}
                      label="Last Name"
                      required
                      // minLength={0}
                      width={windowWidth > 900 ? "250px" : ""}
                    />
                  </div>

                  <TextInputP
                    register={register}
                    type="email"
                    id={"email"}
                    label="Email Address"
                    required
                    errors={errors}
                    pattern={
                      /^([a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?)$/i
                    }
                    width={"100%"}
                  />

                  <div
                    className="relative mb-[28px] w-[100%] 
             peer bg-white border border-gray-300 text-gray-900 text-[12px] rounded-lg h-[35px] placeholder-transparent focus:ring-transparent focus:border-primary focus:border-[1.2px] block dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white font-medium dark:focus:ring-blue-500 dark:focus:border-blue-500 flex py-0 items-center "
                  >
                    <div className="relative">
                      <div className="flex items-center">
                        <input
                          type="text"
                          id="code"
                          required
                          value={countryCode}
                          onFocus={() => setTriggerCode(true)}
                          onBlur={() => setTriggerCode(false)}
                          onChange={(event) =>
                            setCountryCode(event.target.value)
                          }
                          // {...register("code")}
                          className={`w-[52px]
                    peer bg-white border-0 border-gray-300 text-gray-900 text-[12px] rounded-lg h-[30px] placeholder-transparent focus:ring-transparent block pl-1.5 `}
                          autoComplete="off"
                        />
                        <img
                          src="/svgs/arrow.svg"
                          alt=""
                          onClick={() => setTriggerCode(!triggerCode)}
                          className="transform rotate-180 h-[16px] cursor-pointer mr-2"
                        />
                      </div>

                      {triggerCode && (
                        <div className="border max-h-[110px] absolute w-[83px] z-20 bg-white overflow-y-scroll rounded-lg border-primary">
                          {allCountryCode?.map((codes) => (
                            <span
                              // key={result}
                              className="w-[100%] flex h-[40px] items-center pl-2 text-[12px] font-[500] cursor-pointer hover:bg-gray-200 "
                              onMouseDown={(event) => {
                                setCountryCode(codes.dial_code);
                                event.stopPropagation();
                              }}
                            >
                              {codes.code} {codes.dial_code}
                            </span>
                          ))}
                        </div>
                      )}
                    </div>
                    <div
                      className="w-[100%]"
                      onClick={() => setTriggerCode(false)}
                    >
                      <input
                        type="tel"
                        id="mobile"
                        maxLength={10}
                        required
                        autoComplete="off"
                        className={`w-[100%]
                 bg-white border-0 text-gray-900 text-[12px] h-[33px] placeholder-transparent focus:ring-transparent p-0 appearance-none bg-transparent focus:outline-none focus:ring-0 focus:border-blue-500 pl-2
           `}
                        {...register("mobile")}
                        style={{ backgroundColor: "transparent" }}
                      />
                      <label
                        htmlFor="mobile"
                        className="absolute left-2 -top-5 mb-1 text-sm peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-900 peer-placeholder-shown:text-opacity-40 peer-placeholder-shown:top-[10px] peer-placeholder-shown:left-[13px] transition-all peer-focus:-top-5 peer-focus:text-gray-900 peer-focus:text-opacity-40 peer-focus:text-[12.5px] font-medium text-gray-900 text-opacity-40 dark:text-gray-300"
                      >
                        <>
                          Phone Number&nbsp;
                          <span className="text-red-500">*</span>
                        </>
                      </label>
                    </div>
                  </div>
                  <TextInputP
                    register={register}
                    type="text"
                    id={"organization"}
                    required
                    label="Company Name"
                    width={"100%"}
                  />
                  <TextInputP
                    register={register}
                    type="text"
                    id={"jobTitle"}
                    required
                    label="Designation"
                    width={"100%"}
                  />

                  <div className={`relative mb-[28px] mt-[30px]`}>
                    <input
                      type="text"
                      id="country"
                      required
                      value={searchTerm}
                      onFocus={() => setShowResults(true)}
                      onBlur={() => setShowResults(false)}
                      onChange={(event) => setSearchTerm(event.target.value)}
                      className={`w-full
                    peer bg-white border border-gray-300 text-gray-900 text-[12px] rounded-lg h-[35px] placeholder-transparent focus:ring-transparent focus:border-primary focus:border-[1.2px] block p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white font-medium dark:focus:ring-blue-500 dark:focus:border-blue-500`}
                      autoComplete="off"
                    />

                    <label
                      htmlFor="country"
                      className="absolute left-2 -top-5 mb-1 text-sm peer-placeholder-shown:text-sm peer-placeholder-shown:text-gray-900 peer-placeholder-shown:text-opacity-40 peer-placeholder-shown:top-[10px] peer-placeholder-shown:left-[13px] transition-all peer-focus:-top-5 peer-focus:text-gray-900 peer-focus:text-opacity-40 peer-focus:text-[12.5px] font-medium text-gray-900 text-opacity-40 dark:text-gray-300"
                    >
                      <>
                        Country&nbsp;<span className="text-red-500">*</span>
                      </>
                    </label>
                    {showResults && (
                      <div className="border max-h-[110px] absolute w-[100%] z-20 bg-white overflow-y-scroll rounded-lg border-primary">
                        {searchResults.map((result) => (
                          <span
                            onMouseDown={(event) => {
                              setSearchTerm(result.name);
                              setCountryResult(result);
                              var headers = new Headers();
                              headers.append(
                                "X-CSCAPI-KEY",
                                " bXJra0hXRXk2UTdZWmdYTzZDQU53TEVyT1JIWUJ3YmxRZnA3dEpqUA=="
                              );

                              var requestOptions = {
                                method: "GET",
                                headers: headers,
                                redirect: "follow",
                              };

                              let countryCode = result.iso2;

                              fetch(
                                `https://api.countrystatecity.in/v1/countries/${countryCode}`,
                                requestOptions
                              )
                                .then((response) => response.json())
                                .then((result) => {
                                  setValue("country", {
                                    id: result.id,
                                    iso2: result.iso2,
                                    iso3: result.iso3,
                                    latitude: result.latitude,
                                    longitude: result.longitude,
                                    name: result.name,
                                  });
                                })
                                .catch((error) => console.log("error", error));

                              event.stopPropagation();
                            }}
                            key={result.id}
                            className="w-[100%] flex h-[40px] items-center pl-2 text-[16px] font-[500] cursor-pointer hover:bg-gray-200"
                          >
                            {result.name}
                          </span>
                        ))}
                      </div>
                    )}
                  </div>

                  <div className="relative mb-[25px]">
                    <label
                      htmlFor="startDate"
                      className="text-[12px] text-[#9c9c9c] absolute -top-[18px] left-[12px]"
                    >
                      Label <span className="text-red-600">*</span>
                    </label>
                    <Select
                      register={register}
                      id={"label"}
                      options={options}
                      value={value1}
                      onChange={(event) => {
                        setValue("label", event);
                        setValue1(event);
                      }}
                    />
                  </div>

                  <div className="mb-[42px]"></div>

                  <input
                    value={
                      isSubmitting ? "Loading..." : "Register & Print Badge"
                    }
                    type="submit"
                    className="primary_submit"
                  />
                </form>
              </div>
            </div>
          </div>
        </>
      ) : (
        <div className="grid place-items-center mt-[250px]">
          <div className=" w-full h-full flex justify-center items-center flex-col">
            <svg
              className="inline mr-2 w-11 h-11 text-gray-200 animate-spin dark:text-gray-600 fill-primary"
              viewBox="0 0 100 101"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                fill="currentColor"
              />
              <path
                d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                fill="currentFill"
              />
            </svg>
            <p className="mt-[13px]">Registering and printing your badge...</p>
          </div>
        </div>
      )}
    </>
  );
};

export default OnsiteRegistrationForm;
