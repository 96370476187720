import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import AddEmailSchedule from "./AddEmailSchedule";
import AddEmailAndDomain from "./AddEmailAndDomain";
import {
  getRequest,
  patchAuthenticatedRequest,
} from "../../../../utils/API/api.ts";
import EditDefaultEmail from "./EditDefaultEmail";
import { getSingleEvent } from "../../../../redux/actions/eventActions";
import { useMatch } from "react-router-dom";
import ScheduledEmailStats from "./ScheduledEmailStats";
const EmailMarketing = () => {
  const dispatch = useDispatch();
  const [emailDetails, setEmailDetails] = useState([]);
  const [open, setOpen] = useState(false);
  const [openDefaultEmail, setOpenDefaultEmail] = useState(false);
  const [open2, setOpen2] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [deletEmail, setDeleteEmail] = useState("");
  const [singleEmail, setsingleEmail] = useState([]);
  const [defaultEmail, setDefaultEmail] = useState("");
  const [testEmail, setTestEmail] = useState(false);
  const event = useSelector((state) => state.eventData);
  const [open3, setOpen3] = useState(false);
  const [emailBody, setEmailBody] = useState("");
  const eventsId = useMatch("events/:eventId/*");
  const targetRef = useRef([]);
  const now = new Date().getTime();

  useEffect(() => {
    let eventDetails = event;
    setEmailDetails(eventDetails);
  }, [event]);

  useEffect(() => {
    dispatch(getSingleEvent({ eventId: eventsId.params.eventId }));
    return () => {};
  }, []);

  const handleDelete = async (email) => {
    const filteredEmail = emailDetails.scheduledEmails.filter(
      (scheduledEmails) => {
        return scheduledEmails._id !== email._id;
      }
    );
    const updatedEmails = { ...emailDetails, scheduledEmails: filteredEmail };
    setEmailDetails(updatedEmails);

    const updatedEvent = await patchAuthenticatedRequest(
      `/event/${event._id}`,
      {
        scheduledEmails: filteredEmail,
      }
    );
    setDeleteEmail("");
  };

  const showInfo = (index) => {
    targetRef.current[index].style.display = "block";
  };

  const hideInfo = (index) => {
    targetRef.current[index].style.display = "none";
  };

  return (
    <div className="w-full md:w-[375px] md:ml-[0px] md:mt-[27px] pb-[200px]">
      <div className="font-[600] w-[375px] mx-auto md:w-[800px] text-[24px] text-[#585858] flex justify-between items-center mt-2">
        Scheduled Emails
        <div className="w-[200px] grid grid-cols-[1fr] gap-2">
          <div
            className="primary_submit"
            onClick={() => {
              setIsEdit(false);
              setTestEmail(false);
              setOpen(true);
            }}
          >
            Schedule Email
          </div>
        </div>
      </div>
      <div className="border w-[800px] min-h-[300px] mt-5 rounded-lg">
        <div className="flex border-b h-[70px] items-center px-4">
          <div>
            <img src="/svgs/clock.svg" />
          </div>
          <div className=" w-full ml-4">
            <div className="flex items-center mr-5">
              <img src="/svgs/calender.svg" className="mr-2 " />
              <span className="text-[15px] line-clamp-1">
                {emailDetails?.defaultEmails?.resgistrationConfirmationEmail
                  .emailBody !== "none"
                  ? emailDetails?.defaultEmails?.resgistrationConfirmationEmail
                      .emailSubject
                  : `Registration confirmed for ${event?.title}`}
              </span>
            </div>
            <p className="text-[13px] mt-1.5 text-gray-500">
              <div className="flex">
                To:
                <span className="text-primary font-[500]">
                  &nbsp;Registered -&nbsp;
                </span>
                <div className="text-green-600 font-medium	">
                  Sent:&nbsp; As soon as registers
                </div>
              </div>
            </p>
          </div>
          <img
            src="/svgs/Edit.svg"
            alt="edit"
            className="w-6 h-6 cursor-pointer"
            onClick={() => {
              setEmailBody("");
              setOpenDefaultEmail(true);
              setDefaultEmail("registration");
              setTestEmail(false);
            }}
          />
        </div>
        {emailDetails?.scheduledEmails
          ?.sort((a, b) => {
            const timeA = new Date(
              new Date(a.campaignTime).toLocaleString("en-US", {
                timeZone: "Asia/Kolkata",
              })
            ).getTime();
            const timeB = new Date(
              new Date(b.campaignTime).toLocaleString("en-US", {
                timeZone: "Asia/Kolkata",
              })
            ).getTime();
            return timeB - timeA;
          })
          .map((email, index) => {
            return (
              <div className="px-4 border-b">
                <div className="flex  h-[70px] items-center " key={email._id}>
                  <div>
                    <img src="/svgs/clock.svg" />
                  </div>
                  <div className=" w-full ml-4">
                    <div className="flex items-center mr-7">
                      <img src="/svgs/calender.svg" className="mr-2" />
                      <span className="text-[15px] line-clamp-1">
                        {email.emailSubject}
                      </span>
                    </div>
                    <p className="text-[13px] mt-1.5 text-gray-500">
                      <div className="flex">
                        To:
                        <span className="text-primary font-[500]">
                          &nbsp;
                          {email.emailTo[0]?.label === "Send to all"
                            ? "All"
                            : email.emailTo[0]?.label}{" "}
                          -&nbsp;
                        </span>
                        <span className="text-primary font-[500]">
                          {email.filters.length > 0 &&
                          email.emailTo[0]?.label !== "Send to all" ? (
                            <>{email.filters[0]?.label}&nbsp;-&nbsp;</>
                          ) : (
                            <></>
                          )}
                        </span>
                        {new Date(
                          new Date(email.campaignTime).toLocaleString("en-US", {
                            timeZone: "Asia/Kolkata",
                          })
                        ).getTime() <= now ? (
                          <div className="text-green-600 font-medium flex">
                            Sent:&nbsp;
                            {new Date(email.campaignTime).toLocaleString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                              }
                            )}
                            ,{" "}
                            {new Date(email.campaignTime).toLocaleString(
                              "en-US",
                              {
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              }
                            )}
                          </div>
                        ) : (
                          <div className="text-orange-500 font-medium	">
                            Scheduled:&nbsp;
                            {new Date(email.campaignTime).toLocaleString(
                              "en-US",
                              {
                                year: "numeric",
                                month: "2-digit",
                                day: "2-digit",
                              }
                            )}
                            ,{" "}
                            {new Date(email.campaignTime).toLocaleString(
                              "en-US",
                              {
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                              }
                            )}
                          </div>
                        )}
                      </div>
                    </p>
                  </div>
                  {new Date(
                    new Date(email.campaignTime).toLocaleString("en-US", {
                      timeZone: "Asia/Kolkata",
                    })
                  ).getTime() -
                    30 * 60 * 1000 <=
                  now ? (
                    <>
                      {new Date(
                        new Date(email.campaignTime).toLocaleString("en-US", {
                          timeZone: "Asia/Kolkata",
                        })
                      ).getTime() <= now ? (
                        <div className="flex w-[80px] justify-end">
                          {deletEmail === email._id ? (
                            <div className="flex w-[70px] justify-between">
                              <img
                                src="/svgs/done.svg"
                                alt="done"
                                className="w-6 h-6 cursor-pointer"
                                onClick={() => handleDelete(email)}
                              />
                              <img
                                src="/svgs/Cross.svg"
                                alt="delete"
                                className="w-6 h-6 cursor-pointer"
                                onClick={() => setDeleteEmail("")}
                              />
                            </div>
                          ) : (
                            <div className="flex w-[70px] justify-between">
                              <img
                                src="/svgs/Delete.svg"
                                alt="delete"
                                title="Delete"
                                className="w-6 h-6 cursor-pointer"
                                onClick={() => setDeleteEmail(email._id)}
                              />
                              <img
                                src="/svgs/stats.svg"
                                alt="stats"
                                title="Stats"
                                className="w-6 h-6 cursor-pointer"
                                onClick={() => {
                                  setOpen3(true);
                                  setsingleEmail(email);
                                }}
                              />
                            </div>
                          )}
                        </div>
                      ) : (
                        <div
                          className="cursor-pointer relative "
                          onMouseEnter={() => showInfo(index)}
                          onMouseLeave={() => hideInfo(index)}
                        >
                          <img src="/svgs/info.svg" />

                          <div
                            className="absolute z-20 bg-gray-700 h-[90px] text-[12px] w-[150px] p-2 ml-5 rounded-md	text-white text-center hidden"
                            ref={(element) => {
                              targetRef.current[index] = element;
                            }}
                          >
                            Emails cannot be deleted or edited 30 minutes before
                            the scheduled time.
                          </div>
                        </div>
                      )}
                    </>
                  ) : (
                    <div className="flex w-[80px] justify-between">
                      {deletEmail === email._id ? (
                        <>
                          <img
                            src="/svgs/done.svg"
                            alt="done"
                            className="w-6 h-6 cursor-pointer"
                            onClick={() => handleDelete(email)}
                          />
                          <img
                            src="/svgs/Cross.svg"
                            alt="delete"
                            className="w-6 h-6 cursor-pointer"
                            onClick={() => setDeleteEmail("")}
                          />
                        </>
                      ) : (
                        <>
                          <img
                            src="/svgs/Edit.svg"
                            alt="edit"
                            title="Edit"
                            className="w-6 h-6 cursor-pointer"
                            onClick={() => {
                              setOpen(true);
                              setIsEdit(true);
                              setTestEmail(false);
                              setsingleEmail(email);
                            }}
                          />
                          <img
                            src="/svgs/Delete.svg"
                            alt="delete"
                            className="w-6 h-6 cursor-pointer"
                            onClick={() => setDeleteEmail(email._id)}
                          />
                        </>
                      )}
                    </div>
                  )}
                </div>
              </div>
            );
          })}
      </div>
      <div className="mt-5 text-[20px] text-[#585858] font-[600]">
        Speaker Invite
      </div>
      <div className="border w-[800px] min-h-[130px] mt-5 rounded-lg scrollbar-hide">
        <div className="flex border-b h-[70px] items-center px-4">
          <div>
            <img src="/svgs/clock.svg" />
          </div>
          <div className=" w-full ml-4">
            <div className="flex items-center mr-5">
              <img src="/svgs/calender.svg" className="mr-2" />
              <span className="text-[15px] line-clamp-1">
                {emailDetails?.defaultEmails?.speakerConfirmationEmail
                  .emailBody !== "none"
                  ? emailDetails?.defaultEmails?.speakerConfirmationEmail
                      .emailSubject
                  : `You are invited as a speaker at ${event?.title}`}
              </span>
            </div>
            <p className="text-[13px] mt-1.5 text-gray-500">
              <div className="flex">
                To:
                <span className="text-primary font-[500]">
                  &nbsp;Registered -&nbsp;
                </span>
                <div className="text-green-600 font-medium	">
                  Sent:&nbsp; As soon as added
                </div>
              </div>
            </p>
          </div>
          <img
            src="/svgs/Edit.svg"
            alt="edit"
            className="w-6 h-6 cursor-pointer"
            onClick={() => {
              setEmailBody("");
              setDefaultEmail("speaker");
              setOpenDefaultEmail(true);
              // setIsEdit(true);
              // setTestEmail(false);
              // setsingleEmail(email);
            }}
          />
        </div>
      </div>
      <AddEmailSchedule
        open={open}
        setOpen={setOpen}
        event={event}
        setEmailDetails={setEmailDetails}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        testEmail={testEmail}
        setTestEmail={setTestEmail}
        emailDetails={emailDetails}
        singleEmail={singleEmail}
      />

      <EditDefaultEmail
        open={openDefaultEmail}
        setOpen={setOpenDefaultEmail}
        event={emailDetails}
        emailDetails={emailDetails}
        defaultEmail={defaultEmail}
        setEmailDetails={setEmailDetails}
        testEmail={testEmail}
        setTestEmail={setTestEmail}
        emailBody={emailBody}
        setEmailBody={setEmailBody}
      />

      <AddEmailAndDomain
        open={open2}
        setOpen={setOpen2}
        event={event}
        isEdit={isEdit}
        setIsEdit={setIsEdit}
        singleEvent={event}
      />

      <ScheduledEmailStats
        open={open3}
        setOpen={setOpen3}
        singleEmail={singleEmail}
      />
    </div>
  );
};

export default EmailMarketing;
