import React from "react";

const LinkedinLinkClicks = ({ singleEvent }) => {
  return (
    <div className="h-[110px] flex flex-col col-span-full sm:col-span-6 xl:col-span-3 bg-[#f9fbff] shadow-lg rounded-sm">
      <div className="px-5 pt-5">
        <h3 className="text-[15px] font-[600] mb-1">
          <span className="text-[#000]"> Linkedin Link Visits</span>
        </h3>
      </div>

      <p className="text-[22px] font-[600] text-[#000] mt-2 text-center">
        {singleEvent?.linkedinAnalytics?.linkedinAutoPostLinkClicks}
      </p>
    </div>
  );
};

export default LinkedinLinkClicks;
